import Command from "./Command.js";
import runes from "runes";

class Insert extends Command {
  constructor(data, attributes, cursor, transformations = []) {
    super("insert", data, attributes, cursor);
    this.transformations = transformations;
  }
  exec(string) {
    this.startCommand();
    this.input_state = string.hashify();

    const distinct_chars = runes(this.data);
    let cursor = this.cursor;
    for (let i = 0; i < distinct_chars.length; i++) {
      let distinct_char = distinct_chars[i];

      if (i > 0) cursor = this.manager.getCurrentSelection();
      let attributes = this.attributes;

      let transformations = this.transformations;
      if (transformations !== null) transformations = this.transformations[i];
      if (Array.isArray(this.attributes)) attributes = this.attributes[i];

      let insertSingle = this.manager.commandFactory.make("insert single", [
        distinct_char,
        attributes,
        cursor,
        transformations,
      ]);
      insertSingle.exec(string);
      this.subCommands.push(insertSingle);
    }

    this.output_state = string.hashify();
  }
}

export default Insert;
