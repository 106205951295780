import Font from "../Font.js";

const Freaky_Friday = new Font(238,"Freaky Friday");

Freaky_Friday.initCharacterSet("normal",239);
Freaky_Friday.character_sets["normal"].setCharacter(null,"A","A҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"B","B҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"C","C҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"D","D҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"E","E҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"F","F҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"G","G҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"H","H҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"I","I҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"J","J҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"K","K҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"L","L҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"M","M҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"N","N҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"O","O҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"P","P҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"Q","Q҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"R","R҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"S","S҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"T","T҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"U","U҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"V","V҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"W","W҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"X","X҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"Y","Y҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"Z","Z҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"a","a҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"b","b҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"c","c҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"d","d҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"e","e҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"f","f҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"g","g҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"h","h҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"i","i҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"j","j҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"k","k҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"l","l҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"m","m҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"n","n҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"o","o҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"p","p҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"q","q҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"r","r҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"s","s҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"t","t҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"u","u҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"v","v҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"w","w҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"x","x҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"y","y҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"z","z҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"0","0҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"1","1҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"2","2҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"3","3҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"4","4҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"5","5҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"6","6҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"7","7҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"8","8҉");
Freaky_Friday.character_sets["normal"].setCharacter(null,"9","9҉");

export default Freaky_Friday;