import Command from "./Command.js";
import Selection from "../../../Selection.js";
class DeleteAll extends Command {
  constructor() {
    super("delete all", null, null, null);
  }
  exec(string) {
    this.input_state = string.hashify();

    const chLen = string.makeString().length;
    let removeSelection = this.manager.commandFactory.make("remove selection", [
      new Selection(0, chLen),
    ]);
    removeSelection.exec(string);
    this.subCommands.push(removeSelection);
    this.manager.setSelection(new Selection(0, 0));

    this.output_state = string.hashify();
  }
}

export default DeleteAll;
