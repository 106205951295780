import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

class SnippetModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.manager.getPanel("snippets"),
    };
    this.app_manager_id = null;
  }
  /***************************************************************************/
  componentDidMount() {
    this.app_manager_id = this.props.manager.registerListener((changed) => {
      let changedSet = new Set(changed);
      if (changedSet.has("panelsOpen")) {
        this.setState((state, props) => {
          return {
            open: this.props.manager.getPanel("snippets"),
          };
        });
      }
    });
  }
  /***************************************************************************/
  componentWillUnmount() {
    this.props.manager.unregisterListener(this.app_manager_id);
  }
  /***************************************************************************/
  render() {
    return (
      <Dialog
        open={this.state.open}
        aria-labelledby="max-width-dialog-title"
        onClose={() => {
          this.props.manager.togglePanel("snippets");
        }}
      >
        <DialogTitle id="max-width-dialog-title">Snippets</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    );
  }
}

export default SnippetModal;
