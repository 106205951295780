import Command from "./Command.js";
import Selection from "../../../Selection.js";

class InsertSingle extends Command {
  constructor(data, attributes, cursor, transformations = []) {
    super("insert single", data, attributes, cursor);
    this.transformations = transformations;
  }
  exec(string) {
    this.startCommand();
    this.input_state = string.hashify();

    const returnedCursor = string.insertSymbol(
      this.data,
      this.attributes.font,
      this.attributes.style.determineStyle(),
      this.cursor.start,
      this.transformations
    );
    this.manager.setSelection(new Selection(returnedCursor, returnedCursor));
    let outputState = this.manager.stateManager.calcNextState(
      "insert single",
      this.data,
      this.cursor
    );

    this.output_state = string.hashify();
  }
}

export default InsertSingle;
