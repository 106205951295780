import Font from "../Font.js";

const Monospace = new Font(18, "Monospace");

Monospace.initCharacterSet("normal",19);
Monospace.character_sets["normal"].setCharacter("U+1D670","A","𝙰");
Monospace.character_sets["normal"].setCharacter("U+1D671","B","𝙱");
Monospace.character_sets["normal"].setCharacter("U+1D672","C","𝙲");
Monospace.character_sets["normal"].setCharacter("U+1D673","D","𝙳");
Monospace.character_sets["normal"].setCharacter("U+1D674","E","𝙴");
Monospace.character_sets["normal"].setCharacter("U+1D675","F","𝙵");
Monospace.character_sets["normal"].setCharacter("U+1D676","G","𝙶");
Monospace.character_sets["normal"].setCharacter("U+1D677","H","𝙷");
Monospace.character_sets["normal"].setCharacter("U+1D678","I","𝙸");
Monospace.character_sets["normal"].setCharacter("U+1D679","J","𝙹");
Monospace.character_sets["normal"].setCharacter("U+1D67A","K","𝙺");
Monospace.character_sets["normal"].setCharacter("U+1D67B","L","𝙻");
Monospace.character_sets["normal"].setCharacter("U+1D67C","M","𝙼");
Monospace.character_sets["normal"].setCharacter("U+1D67D","N","𝙽");
Monospace.character_sets["normal"].setCharacter("U+1D67E","O","𝙾");
Monospace.character_sets["normal"].setCharacter("U+1D67F","P","𝙿");
Monospace.character_sets["normal"].setCharacter("U+1D680","Q","𝚀");
Monospace.character_sets["normal"].setCharacter("U+1D681","R","𝚁");
Monospace.character_sets["normal"].setCharacter("U+1D682","S","𝚂");
Monospace.character_sets["normal"].setCharacter("U+1D683","T","𝚃");
Monospace.character_sets["normal"].setCharacter("U+1D684","U","𝚄");
Monospace.character_sets["normal"].setCharacter("U+1D685","V","𝚅");
Monospace.character_sets["normal"].setCharacter("U+1D686","W","𝚆");
Monospace.character_sets["normal"].setCharacter("U+1D687","X","𝚇");
Monospace.character_sets["normal"].setCharacter("U+1D688","Y","𝚈");
Monospace.character_sets["normal"].setCharacter("U+1D689","Z","𝚉");
Monospace.character_sets["normal"].setCharacter("U+1D68A","a","𝚊");
Monospace.character_sets["normal"].setCharacter("U+1D68B","b","𝚋");
Monospace.character_sets["normal"].setCharacter("U+1D68C","c","𝚌");
Monospace.character_sets["normal"].setCharacter("U+1D68D","d","𝚍");
Monospace.character_sets["normal"].setCharacter("U+1D68E","e","𝚎");
Monospace.character_sets["normal"].setCharacter("U+1D68F","f","𝚏");
Monospace.character_sets["normal"].setCharacter("U+1D690","g","𝚐");
Monospace.character_sets["normal"].setCharacter("U+1D691","h","𝚑");
Monospace.character_sets["normal"].setCharacter("U+1D692","i","𝚒");
Monospace.character_sets["normal"].setCharacter("U+1D693","j","𝚓");
Monospace.character_sets["normal"].setCharacter("U+1D694","k","𝚔");
Monospace.character_sets["normal"].setCharacter("U+1D695","l","𝚕");
Monospace.character_sets["normal"].setCharacter("U+1D696","m","𝚖");
Monospace.character_sets["normal"].setCharacter("U+1D697","n","𝚗");
Monospace.character_sets["normal"].setCharacter("U+1D698","o","𝚘");
Monospace.character_sets["normal"].setCharacter("U+1D699","p","𝚙");
Monospace.character_sets["normal"].setCharacter("U+1D69A","q","𝚚");
Monospace.character_sets["normal"].setCharacter("U+1D69B","r","𝚛");
Monospace.character_sets["normal"].setCharacter("U+1D69C","s","𝚜");
Monospace.character_sets["normal"].setCharacter("U+1D69D","t","𝚝");
Monospace.character_sets["normal"].setCharacter("U+1D69E","u","𝚞");
Monospace.character_sets["normal"].setCharacter("U+1D69F","v","𝚟");
Monospace.character_sets["normal"].setCharacter("U+1D6A0","w","𝚠");
Monospace.character_sets["normal"].setCharacter("U+1D6A1","x","𝚡");
Monospace.character_sets["normal"].setCharacter("U+1D6A2","y","𝚢");
Monospace.character_sets["normal"].setCharacter("U+1D6A3","z","𝚣");
Monospace.character_sets["normal"].setCharacter("U+1D7F6","0","𝟶");
Monospace.character_sets["normal"].setCharacter("U+1D7F7","1","𝟷");
Monospace.character_sets["normal"].setCharacter("U+1D7F8","2","𝟸");
Monospace.character_sets["normal"].setCharacter("U+1D7F9","3","𝟹");
Monospace.character_sets["normal"].setCharacter("U+1D7FA","4","𝟺");
Monospace.character_sets["normal"].setCharacter("U+1D7FB","5","𝟻");
Monospace.character_sets["normal"].setCharacter("U+1D7FC","6","𝟼");
Monospace.character_sets["normal"].setCharacter("U+1D7FD","7","𝟽");
Monospace.character_sets["normal"].setCharacter("U+1D7FE","8","𝟾");
Monospace.character_sets["normal"].setCharacter("U+1D7FF","9","𝟿");

export default Monospace;