import CharacterStyle from "./CharacterStyle.js";
class Attributes {
  constructor(bold, italic, font) {
    this.font = font;
    this.style = new CharacterStyle(bold, italic);
  }

  equals(attributes) {
    return this.font === attributes.font && this.style === attributes.style;
  }

  clone() {
    return new Attributes(this.style.bold, this.style.italic, this.font);
  }
  hashify() {
    return { font: this.font, style: this.style.hashify() };
  }
}

export default Attributes;
