import Font from "../Font.js";

const Notes = new Font(268,"Notes");

Notes.initCharacterSet("normal",269);
Notes.character_sets["normal"].setCharacter(null,"A","A");
Notes.character_sets["normal"].setCharacter(null,"B","B");
Notes.character_sets["normal"].setCharacter(null,"C","C");
Notes.character_sets["normal"].setCharacter(null,"D","D");
Notes.character_sets["normal"].setCharacter(null,"E","E");
Notes.character_sets["normal"].setCharacter(null,"F","F");
Notes.character_sets["normal"].setCharacter(null,"G","G");
Notes.character_sets["normal"].setCharacter(null,"H","H");
Notes.character_sets["normal"].setCharacter(null,"I","I");
Notes.character_sets["normal"].setCharacter(null,"J","J");
Notes.character_sets["normal"].setCharacter(null,"K","K");
Notes.character_sets["normal"].setCharacter(null,"L","L");
Notes.character_sets["normal"].setCharacter(null,"M","M");
Notes.character_sets["normal"].setCharacter(null,"N","N");
Notes.character_sets["normal"].setCharacter(null,"O","O");
Notes.character_sets["normal"].setCharacter(null,"P","P");
Notes.character_sets["normal"].setCharacter(null,"Q","Q");
Notes.character_sets["normal"].setCharacter(null,"R","R");
Notes.character_sets["normal"].setCharacter(null,"S","S");
Notes.character_sets["normal"].setCharacter(null,"T","T");
Notes.character_sets["normal"].setCharacter(null,"U","U");
Notes.character_sets["normal"].setCharacter(null,"V","V");
Notes.character_sets["normal"].setCharacter(null,"W","W");
Notes.character_sets["normal"].setCharacter(null,"X","X");
Notes.character_sets["normal"].setCharacter(null,"Y","Y");
Notes.character_sets["normal"].setCharacter(null,"Z","Z");
Notes.character_sets["normal"].setCharacter(null,"a","ᾰ");
Notes.character_sets["normal"].setCharacter(null,"b","♭");
Notes.character_sets["normal"].setCharacter(null,"c","ḉ");
Notes.character_sets["normal"].setCharacter(null,"d","ᖱ");
Notes.character_sets["normal"].setCharacter(null,"e","ḙ");
Notes.character_sets["normal"].setCharacter(null,"f","ḟ");
Notes.character_sets["normal"].setCharacter(null,"g","❡");
Notes.character_sets["normal"].setCharacter(null,"h","ℏ");
Notes.character_sets["normal"].setCharacter(null,"i","!");
Notes.character_sets["normal"].setCharacter(null,"j","♩");
Notes.character_sets["normal"].setCharacter(null,"k","к");
Notes.character_sets["normal"].setCharacter(null,"l","ℓ");
Notes.character_sets["normal"].setCharacter(null,"m","Պ");
Notes.character_sets["normal"].setCharacter(null,"n","ℵ");
Notes.character_sets["normal"].setCharacter(null,"o","✺");
Notes.character_sets["normal"].setCharacter(null,"p","℘");
Notes.character_sets["normal"].setCharacter(null,"q","ǭ");
Notes.character_sets["normal"].setCharacter(null,"r","Ի");
Notes.character_sets["normal"].setCharacter(null,"s","ṧ");
Notes.character_sets["normal"].setCharacter(null,"t","т");
Notes.character_sets["normal"].setCharacter(null,"u","ṳ");
Notes.character_sets["normal"].setCharacter(null,"v","ṽ");
Notes.character_sets["normal"].setCharacter(null,"w","ω");
Notes.character_sets["normal"].setCharacter(null,"x","✘");
Notes.character_sets["normal"].setCharacter(null,"y","⑂");
Notes.character_sets["normal"].setCharacter(null,"z","ℨ");
Notes.character_sets["normal"].setCharacter(null,"0","0");
Notes.character_sets["normal"].setCharacter(null,"1","1");
Notes.character_sets["normal"].setCharacter(null,"2","2");
Notes.character_sets["normal"].setCharacter(null,"3","3");
Notes.character_sets["normal"].setCharacter(null,"4","4");
Notes.character_sets["normal"].setCharacter(null,"5","5");
Notes.character_sets["normal"].setCharacter(null,"6","6");
Notes.character_sets["normal"].setCharacter(null,"7","7");
Notes.character_sets["normal"].setCharacter(null,"8","8");
Notes.character_sets["normal"].setCharacter(null,"9","9");

export default Notes;