import complex_unicode_string_manager from "../models/UnicodeString/ComplexUnicodeString/ComplexUnicodeStringManager.js";
class AppManagerForComplexString {
  constructor() {
    this.stringManager = complex_unicode_string_manager;
  }
  init() {
    this.stringManager.init(this);
    this.listeners = [];
    this.saveId = null;
    this.constants = {
      HORIZONTAL_RULE:
        "\n·········································································\n",
      BULLET: "• ",
      THREE_DOTS: ".\n.\n.\n",
    };

    this.settings = {
      HORIZONTAL_RULE: this.constants.HORIZONTAL_RULE,
      BULLET: this.constants.BULLET,
      THREE_DOTS: this.constants.THREE_DOTS,
    };

    this.panelsOpen = {
      emojis: false,
      glyphs: false,
      settings: false,
      "font selector": false,
      snippets: false,
      share: false,
      help: false,
      about: false,
      charsLeft: false,
    };

    this.settingsEnabled = {
      list: false,
      horizontal_rule: false,
    };

    this.mode = { normal: true, list: false, selection: false };
    this.changeText = true;
  }

  setSaveId(id) {
    this.saveId = id;
    this.notifyListeners(["saveId"]);
  }

  getSaveId() {
    return this.saveId;
  }

  getPanel(p) {
    return this.panelsOpen[p];
  }

  enableSettings(n) {
    this.settingsEnabled[n] = true;
    this.notifyListeners(["settingsEnabled"]);
  }

  getSettingsEnabled() {
    return this.settingsEnabled;
  }

  settingsAreEnabled() {
    return this.settingsEnabled.list || this.settingsEnabled.horizontal_rule;
  }

  disableSettings(n) {
    this.settingsEnabled[n] = false;
    this.notifyListeners(["settingsEnabled"]);
  }

  setChangeText() {
    this.changeText = !this.changeText;
    this.notifyListeners(["changeText"]);
  }

  reinstateFromHash(hash) {
    this.stringManager.reinstateFromHash(hash);
  }

  manageSettings() {
    if (this.mode.list) this.enableSettings("list");
    else if (!this.mode.list) this.disableSettings("list");

    this.disableSettings("horizontal_rule");
  }

  toggleMode(m) {
    if (this.mode.normal && m === "list") {
      this.mode.normal = false;
      this.mode.list = true;
    } else if (this.mode.list && m === "list") {
      this.mode.normal = true;
      this.mode.list = false;
    }


    this.manageSettings();

    this.notifyListeners(["mode", "settingsEnabled"]);
  }

  togglePanel(panel) {
    this.panelsOpen[panel] = !this.panelsOpen[panel];
    if (panel === "font selector" && this.panelsOpen["font selector"])
      this.panelsOpen["input box"] = false;
    else if (panel === "input box" && this.panelsOpen["input box"])
      this.panelsOpen["font selector"] = false;

    this.manageSettings();

    this.notifyListeners(["panelsOpen", "settingsEnabled"]);
  }
  registerListener(listener) {
    this.listeners.push(listener);
    return this.listeners.length - 1;
  }

  unregisterListener(id) {
    this.listeners[id] = null;
  }

  notifyListeners(propsChanged) {
    for (let i = 0; i < this.listeners.length; i++) {
      const listener = this.listeners[i];
      if (listener !== undefined && listener !== null) {
        listener(propsChanged);
      }
    }
  }
}

const app_manager = new AppManagerForComplexString();

export default app_manager;
