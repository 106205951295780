import Command from "./Command.js";

class InitFromHash extends Command {
  constructor(hash) {
    super("init from hash", null, null, null);
    this.hash = hash;
  }
  exec(string) {
    string.reinstateFromHash(this.hash);
  }
}

export default InitFromHash;
