import "./App.css";
import { Link, Route, Routes, Router, useParams, useLocation } from "react-router-dom";
import DualInputOutputFormatter from "./components/DualInputOutputbox/DualInputOutputFormatter.js";
import DualInputOutputbox from "./components/DualInputOutputbox/DualInputOutputbox";
import app_manager from "./AppManager/AppManagerForComplexString.js";


// If the path is "/", then we are starting from scratch/
// If the path is "/caption/id/:id/version/:version", then we are starting
// from a saved version

function App() {
  app_manager.init();

  return (
      <div className="App">
        <DualInputOutputFormatter manager={app_manager} />
      </div>
  );
}

function Child() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { id, version } = useParams();

  return (
    <DualInputOutputFormatter
      captionId={id}
      version={version}
      manager={app_manager}
    />
  );
}
export default App;