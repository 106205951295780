import Font from "../Font.js";

const Gawd_Mode = new Font(244,"Gawd Mode");

Gawd_Mode.initCharacterSet("normal",245);
Gawd_Mode.character_sets["normal"].setCharacter(null,"A","ƛ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"B","Ɓ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"C","Ƈ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"D","Ɗ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"E","Є");
Gawd_Mode.character_sets["normal"].setCharacter(null,"F","Ƒ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"G","Ɠ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"H","Ӈ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"I","Ɩ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"J","ʆ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"K","Ƙ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"L","Լ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"M","M");
Gawd_Mode.character_sets["normal"].setCharacter(null,"N","Ɲ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"O","Ơ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"P","Ƥ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"Q","Ƣ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"R","Ʀ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"S","Ƨ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"T","Ƭ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"U","Ʋ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"V","Ɣ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"W","Ɯ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"X","Ҳ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"Y","Ƴ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"Z","Ȥ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"a","ƛ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"b","Ɓ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"c","Ƈ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"d","Ɗ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"e","Є");
Gawd_Mode.character_sets["normal"].setCharacter(null,"f","Ƒ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"g","Ɠ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"h","Ӈ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"i","Ɩ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"j","ʆ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"k","Ƙ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"l","Լ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"m","M");
Gawd_Mode.character_sets["normal"].setCharacter(null,"n","Ɲ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"o","Ơ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"p","Ƥ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"q","Ƣ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"r","Ʀ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"s","Ƨ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"t","Ƭ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"u","Ʋ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"v","Ɣ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"w","Ɯ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"x","Ҳ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"y","Ƴ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"z","Ȥ");
Gawd_Mode.character_sets["normal"].setCharacter(null,"0","0");
Gawd_Mode.character_sets["normal"].setCharacter(null,"1","1");
Gawd_Mode.character_sets["normal"].setCharacter(null,"2","2");
Gawd_Mode.character_sets["normal"].setCharacter(null,"3","3");
Gawd_Mode.character_sets["normal"].setCharacter(null,"4","4");
Gawd_Mode.character_sets["normal"].setCharacter(null,"5","5");
Gawd_Mode.character_sets["normal"].setCharacter(null,"6","6");
Gawd_Mode.character_sets["normal"].setCharacter(null,"7","7");
Gawd_Mode.character_sets["normal"].setCharacter(null,"8","8");
Gawd_Mode.character_sets["normal"].setCharacter(null,"9","9");

export default Gawd_Mode;