import Font from "../Font.js";

const Notebook = new Font(224,"Notebook");

Notebook.initCharacterSet("normal",225);
Notebook.character_sets["normal"].setCharacter(null,"A","ᗩ");
Notebook.character_sets["normal"].setCharacter(null,"B","ᗷ");
Notebook.character_sets["normal"].setCharacter(null,"C","ᑕ");
Notebook.character_sets["normal"].setCharacter(null,"D","ᗪ");
Notebook.character_sets["normal"].setCharacter(null,"E","E");
Notebook.character_sets["normal"].setCharacter(null,"F","ᖴ");
Notebook.character_sets["normal"].setCharacter(null,"G","G");
Notebook.character_sets["normal"].setCharacter(null,"H","ᕼ");
Notebook.character_sets["normal"].setCharacter(null,"I","I");
Notebook.character_sets["normal"].setCharacter(null,"J","ᒍ");
Notebook.character_sets["normal"].setCharacter(null,"K","K");
Notebook.character_sets["normal"].setCharacter(null,"L","ᒪ");
Notebook.character_sets["normal"].setCharacter(null,"M","ᗰ");
Notebook.character_sets["normal"].setCharacter(null,"N","ᑎ");
Notebook.character_sets["normal"].setCharacter(null,"O","O");
Notebook.character_sets["normal"].setCharacter(null,"P","ᑭ");
Notebook.character_sets["normal"].setCharacter(null,"Q","ᑫ");
Notebook.character_sets["normal"].setCharacter(null,"R","ᖇ");
Notebook.character_sets["normal"].setCharacter(null,"S","ᔕ");
Notebook.character_sets["normal"].setCharacter(null,"T","T");
Notebook.character_sets["normal"].setCharacter(null,"U","ᑌ");
Notebook.character_sets["normal"].setCharacter(null,"V","ᐯ");
Notebook.character_sets["normal"].setCharacter(null,"W","ᗯ");
Notebook.character_sets["normal"].setCharacter(null,"X","᙭");
Notebook.character_sets["normal"].setCharacter(null,"Y","Y");
Notebook.character_sets["normal"].setCharacter(null,"Z","ᘔ");
Notebook.character_sets["normal"].setCharacter(null,"a","ᗩ");
Notebook.character_sets["normal"].setCharacter(null,"b","ᗷ");
Notebook.character_sets["normal"].setCharacter(null,"c","ᑕ");
Notebook.character_sets["normal"].setCharacter(null,"d","ᗪ");
Notebook.character_sets["normal"].setCharacter(null,"e","E");
Notebook.character_sets["normal"].setCharacter(null,"f","ᖴ");
Notebook.character_sets["normal"].setCharacter(null,"g","G");
Notebook.character_sets["normal"].setCharacter(null,"h","ᕼ");
Notebook.character_sets["normal"].setCharacter(null,"i","I");
Notebook.character_sets["normal"].setCharacter(null,"j","ᒍ");
Notebook.character_sets["normal"].setCharacter(null,"k","K");
Notebook.character_sets["normal"].setCharacter(null,"l","ᒪ");
Notebook.character_sets["normal"].setCharacter(null,"m","ᗰ");
Notebook.character_sets["normal"].setCharacter(null,"n","ᑎ");
Notebook.character_sets["normal"].setCharacter(null,"o","O");
Notebook.character_sets["normal"].setCharacter(null,"p","ᑭ");
Notebook.character_sets["normal"].setCharacter(null,"q","ᑫ");
Notebook.character_sets["normal"].setCharacter(null,"r","ᖇ");
Notebook.character_sets["normal"].setCharacter(null,"s","ᔕ");
Notebook.character_sets["normal"].setCharacter(null,"t","T");
Notebook.character_sets["normal"].setCharacter(null,"u","ᑌ");
Notebook.character_sets["normal"].setCharacter(null,"v","ᐯ");
Notebook.character_sets["normal"].setCharacter(null,"w","ᗯ");
Notebook.character_sets["normal"].setCharacter(null,"x","᙭");
Notebook.character_sets["normal"].setCharacter(null,"y","Y");
Notebook.character_sets["normal"].setCharacter(null,"z","ᘔ");
Notebook.character_sets["normal"].setCharacter(null,"0","0");
Notebook.character_sets["normal"].setCharacter(null,"1","1");
Notebook.character_sets["normal"].setCharacter(null,"2","2");
Notebook.character_sets["normal"].setCharacter(null,"3","3");
Notebook.character_sets["normal"].setCharacter(null,"4","4");
Notebook.character_sets["normal"].setCharacter(null,"5","5");
Notebook.character_sets["normal"].setCharacter(null,"6","6");
Notebook.character_sets["normal"].setCharacter(null,"7","7");
Notebook.character_sets["normal"].setCharacter(null,"8","8");
Notebook.character_sets["normal"].setCharacter(null,"9","9");

export default Notebook;