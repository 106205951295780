import Command from "./Command.js";
import Selection from "../../../Selection.js";

class RemoveSingle extends Command {
  constructor(cursor) {
    super("remove single", null, null, cursor);
  }
  exec(string) {
    this.startCommand();
    this.input_state = string.hashify();

    const pos = this.manager.model.cursorManager.getPrevSymbolPosition(
      this.cursor.end
    );
    const ch = this.manager.model.symbols[pos];
    const chLen = ch.length();
    string.removeSymbol(pos, chLen);
    this.manager.setSelection(
      new Selection(this.cursor.end - chLen, this.cursor.end - chLen)
    );

    this.manager.stateManager.calcNextState(
      "remove single",
      ch.parent.char,
      pos
    );
    this.output_state = string.hashify();
  }
}

export default RemoveSingle;
