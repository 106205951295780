import Command from "./Command.js";
import Selection from "../../../Selection.js";

class Reverse extends Command {
  constructor(selection, attributes) {
    super("reverse", null, attributes, selection);
  }
  exec(string) {
    this.startCommand();
    this.input_state = string.hashify();
    const substring = string.getSubstring(this.cursor);

    const reversed = substring.substring.split("").reverse().join("");

    // create the subcommands and save them
    let removeSelection = this.manager.commandFactory.make("remove selection", [
      this.cursor,
    ]);
    let insert = this.manager.commandFactory.make("insert", [
      reversed,
      substring.attributes.reverse(),
      this.cursor,
    ]);
    this.subCommands.push(removeSelection);
    this.subCommands.push(insert);
    this.execSubCommands(string);

    const newCursor = this.manager.getCurrentSelection();
    this.manager.setSelection(new Selection(this.cursor.start, newCursor.end));

    this.output_state = string.hashify();
    return this;
  }
  execSubCommands(string) {
    for (let i = 0; i < this.subCommands.length; i++)
      this.subCommands[i].exec(string);
  }
}

export default Reverse;
