import Font from "../Font.js";

const Dot_Matrix = new Font(262,"Dot Matrix");

Dot_Matrix.initCharacterSet("normal",263);
Dot_Matrix.character_sets["normal"].setCharacter(null,"A","Ä̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"B","B̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"C","C̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"D","D̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"E","Ë̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"F","F̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"G","G̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"H","Ḧ̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"I","Ï̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"J","J̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"K","K̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"L","L̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"M","M̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"N","N̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"O","Ö̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"P","P̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"Q","Q̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"R","R̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"S","S̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"T","T̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"U","Ṳ̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"V","V̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"W","Ẅ̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"X","Ẍ̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"Y","Ÿ̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"Z","Z̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"a","ä̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"b","b̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"c","c̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"d","d̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"e","ë̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"f","f̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"g","g̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"h","ḧ̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"i","ï̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"j","j̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"k","k̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"l","l̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"m","m̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"n","n̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"o","ö̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"p","p̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"q","q̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"r","r̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"s","s̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"t","ẗ̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"u","ṳ̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"v","v̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"w","ẅ̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"x","ẍ̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"y","ÿ̤̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"z","z̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"0","0̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"1","1̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"2","2̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"3","3̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"4","4̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"5","5̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"6","6̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"7","7̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"8","8̤̈̈");
Dot_Matrix.character_sets["normal"].setCharacter(null,"9","9̤̤̈");

export default Dot_Matrix;