import Font from "../Font.js";

const Wonky_Doodle = new Font(250,"Wonky Doodle");

Wonky_Doodle.initCharacterSet("normal",251);
Wonky_Doodle.character_sets["normal"].setCharacter(null,"A","ค");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"B","๒");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"C","ς");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"D","๔");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"E","є");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"F","Ŧ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"G","ɠ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"H","ђ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"I","เ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"J","ן");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"K","к");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"L","l");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"M","๓");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"N","ภ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"O","๏");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"P","թ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"Q","ợ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"R","г");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"S","ร");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"T","t");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"U","ย");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"V","v");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"W","ฬ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"X","x");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"Y","ץ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"Z","z");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"a","ค");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"b","๒");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"c","ς");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"d","๔");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"e","є");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"f","Ŧ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"g","ɠ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"h","ђ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"i","เ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"j","ן");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"k","к");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"l","l");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"m","๓");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"n","ภ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"o","๏");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"p","թ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"q","ợ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"r","г");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"s","ร");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"t","t");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"u","ย");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"v","v");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"w","ฬ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"x","x");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"y","ץ");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"z","z");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"0","0");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"1","1");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"2","2");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"3","3");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"4","4");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"5","5");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"6","6");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"7","7");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"8","8");
Wonky_Doodle.character_sets["normal"].setCharacter(null,"9","9");

export default Wonky_Doodle;