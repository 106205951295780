import Font from "../Font.js";

const Script = new Font(6, "Script");

Script.initCharacterSet("bold",7);
Script.character_sets["bold"].setCharacter("U+1D4D0","A","𝓐");
Script.character_sets["bold"].setCharacter("U+1D4D1","B","𝓑");
Script.character_sets["bold"].setCharacter("U+1D4D2","C","𝓒");
Script.character_sets["bold"].setCharacter("U+1D4D3","D","𝓓");
Script.character_sets["bold"].setCharacter("U+1D4D4","E","𝓔");
Script.character_sets["bold"].setCharacter("U+1D4D5","F","𝓕");
Script.character_sets["bold"].setCharacter("U+1D4D6","G","𝓖");
Script.character_sets["bold"].setCharacter("U+1D4D7","H","𝓗");
Script.character_sets["bold"].setCharacter("U+1D4D8","I","𝓘");
Script.character_sets["bold"].setCharacter("U+1D4D9","J","𝓙");
Script.character_sets["bold"].setCharacter("U+1D4DA","K","𝓚");
Script.character_sets["bold"].setCharacter("U+1D4DB","L","𝓛");
Script.character_sets["bold"].setCharacter("U+1D4DC","M","𝓜");
Script.character_sets["bold"].setCharacter("U+1D4DD","N","𝓝");
Script.character_sets["bold"].setCharacter("U+1D4DE","O","𝓞");
Script.character_sets["bold"].setCharacter("U+1D4DF","P","𝓟");
Script.character_sets["bold"].setCharacter("U+1D4E0","Q","𝓠");
Script.character_sets["bold"].setCharacter("U+1D4E1","R","𝓡");
Script.character_sets["bold"].setCharacter("U+1D4E2","S","𝓢");
Script.character_sets["bold"].setCharacter("U+1D4E3","T","𝓣");
Script.character_sets["bold"].setCharacter("U+1D4E4","U","𝓤");
Script.character_sets["bold"].setCharacter("U+1D4E5","V","𝓥");
Script.character_sets["bold"].setCharacter("U+1D4E6","W","𝓦");
Script.character_sets["bold"].setCharacter("U+1D4E7","X","𝓧");
Script.character_sets["bold"].setCharacter("U+1D4E8","Y","𝓨");
Script.character_sets["bold"].setCharacter("U+1D4E9","Z","𝓩");
Script.character_sets["bold"].setCharacter("U+1D4EA","a","𝓪");
Script.character_sets["bold"].setCharacter("U+1D4EB","b","𝓫");
Script.character_sets["bold"].setCharacter("U+1D4EC","c","𝓬");
Script.character_sets["bold"].setCharacter("U+1D4ED","d","𝓭");
Script.character_sets["bold"].setCharacter("U+1D4EE","e","𝓮");
Script.character_sets["bold"].setCharacter("U+1D4EF","f","𝓯");
Script.character_sets["bold"].setCharacter("U+1D4F0","g","𝓰");
Script.character_sets["bold"].setCharacter("U+1D4F1","h","𝓱");
Script.character_sets["bold"].setCharacter("U+1D4F2","i","𝓲");
Script.character_sets["bold"].setCharacter("U+1D4F3","j","𝓳");
Script.character_sets["bold"].setCharacter("U+1D4F4","k","𝓴");
Script.character_sets["bold"].setCharacter("U+1D4F5","l","𝓵");
Script.character_sets["bold"].setCharacter("U+1D4F6","m","𝓶");
Script.character_sets["bold"].setCharacter("U+1D4F7","n","𝓷");
Script.character_sets["bold"].setCharacter("U+1D4F8","o","𝓸");
Script.character_sets["bold"].setCharacter("U+1D4F9","p","𝓹");
Script.character_sets["bold"].setCharacter("U+1D4FA","q","𝓺");
Script.character_sets["bold"].setCharacter("U+1D4FB","r","𝓻");
Script.character_sets["bold"].setCharacter("U+1D4FC","s","𝓼");
Script.character_sets["bold"].setCharacter("U+1D4FD","t","𝓽");
Script.character_sets["bold"].setCharacter("U+1D4FE","u","𝓾");
Script.character_sets["bold"].setCharacter("U+1D4FF","v","𝓿");
Script.character_sets["bold"].setCharacter("U+1D500","w","𝔀");
Script.character_sets["bold"].setCharacter("U+1D501","x","𝔁");
Script.character_sets["bold"].setCharacter("U+1D502","y","𝔂");
Script.character_sets["bold"].setCharacter("U+1D503","z","𝔃");

Script.initCharacterSet("normal",8);
Script.character_sets["normal"].setCharacter("U+1D49C","A","𝒜");
Script.character_sets["normal"].setCharacter("U+1D49E","C","𝒞");
Script.character_sets["normal"].setCharacter("U+1D49F","D","𝒟");
Script.character_sets["normal"].setCharacter("U+1D4A2","G","𝒢");
Script.character_sets["normal"].setCharacter("U+1D4A5","J","𝒥");
Script.character_sets["normal"].setCharacter("U+1D4A6","K","𝒦");
Script.character_sets["normal"].setCharacter("U+1D4A9","N","𝒩");
Script.character_sets["normal"].setCharacter("U+1D4AA","O","𝒪");
Script.character_sets["normal"].setCharacter("U+1D4AB","P","𝒫");
Script.character_sets["normal"].setCharacter("U+1D4AC","Q","𝒬");
Script.character_sets["normal"].setCharacter("U+1D4AE","S","𝒮");
Script.character_sets["normal"].setCharacter("U+1D4AF","T","𝒯");
Script.character_sets["normal"].setCharacter("U+1D4B0","U","𝒰");
Script.character_sets["normal"].setCharacter("U+1D4B1","V","𝒱");
Script.character_sets["normal"].setCharacter("U+1D4B2","W","𝒲");
Script.character_sets["normal"].setCharacter("U+1D4B3","X","𝒳");
Script.character_sets["normal"].setCharacter("U+1D4B4","Y","𝒴");
Script.character_sets["normal"].setCharacter("U+1D4B5","Z","𝒵");
Script.character_sets["normal"].setCharacter("U+1D4B6","a","𝒶");
Script.character_sets["normal"].setCharacter("U+1D4B7","b","𝒷");
Script.character_sets["normal"].setCharacter("U+1D4B8","c","𝒸");
Script.character_sets["normal"].setCharacter("U+1D4B9","d","𝒹");
Script.character_sets["normal"].setCharacter("U+1D4BB","f","𝒻");
Script.character_sets["normal"].setCharacter("U+1D4BD","h","𝒽");
Script.character_sets["normal"].setCharacter("U+1D4BE","i","𝒾");
Script.character_sets["normal"].setCharacter("U+1D4BF","j","𝒿");
Script.character_sets["normal"].setCharacter("U+1D4C0","k","𝓀");
Script.character_sets["normal"].setCharacter("U+1D4C1","l","𝓁");
Script.character_sets["normal"].setCharacter("U+1D4C2","m","𝓂");
Script.character_sets["normal"].setCharacter("U+1D4C3","n","𝓃");
Script.character_sets["normal"].setCharacter("U+1D4C5","p","𝓅");
Script.character_sets["normal"].setCharacter("U+1D4C6","q","𝓆");
Script.character_sets["normal"].setCharacter("U+1D4C7","r","𝓇");
Script.character_sets["normal"].setCharacter("U+1D4C8","s","𝓈");
Script.character_sets["normal"].setCharacter("U+1D4C9","t","𝓉");
Script.character_sets["normal"].setCharacter("U+1D4CA","u","𝓊");
Script.character_sets["normal"].setCharacter("U+1D4CB","v","𝓋");
Script.character_sets["normal"].setCharacter("U+1D4CC","w","𝓌");
Script.character_sets["normal"].setCharacter("U+1D4CD","x","𝓍");
Script.character_sets["normal"].setCharacter("U+1D4CE","y","𝓎");
Script.character_sets["normal"].setCharacter("U+1D4CF","z","𝓏");
Script.character_sets["normal"].setCharacter("U+212C","B","ℬ");
Script.character_sets["normal"].setCharacter("U+2130","E","ℰ");
Script.character_sets["normal"].setCharacter("U+2131","F","ℱ");
Script.character_sets["normal"].setCharacter("U+210B","H","ℋ");
Script.character_sets["normal"].setCharacter("U+2110","I","ℐ");
Script.character_sets["normal"].setCharacter("U+2112","L","ℒ");
Script.character_sets["normal"].setCharacter("U+2133","M","ℳ");
Script.character_sets["normal"].setCharacter("U+211B","R","ℛ");
Script.character_sets["normal"].setCharacter("U+212F","e","ℯ");
Script.character_sets["normal"].setCharacter("U+210A","g","ℊ");
Script.character_sets["normal"].setCharacter("U+2134","o","ℴ");
Script.character_sets["normal"].setCharacter("U+212C","B","ℬ");
Script.character_sets["normal"].setCharacter("U+2130","E","ℰ");
Script.character_sets["normal"].setCharacter("U+2131","F","ℱ");
Script.character_sets["normal"].setCharacter("U+210B","H","ℋ");
Script.character_sets["normal"].setCharacter("U+2110","I","ℐ");
Script.character_sets["normal"].setCharacter("U+2112","L","ℒ");
Script.character_sets["normal"].setCharacter("U+2133","M","ℳ");
Script.character_sets["normal"].setCharacter("U+211B","R","ℛ");
Script.character_sets["normal"].setCharacter("U+212F","e","ℯ");
Script.character_sets["normal"].setCharacter("U+210A","g","ℊ");
Script.character_sets["normal"].setCharacter("U+2134","o","ℴ");
Script.character_sets["normal"].setCharacter("U+2118","P","℘");
Script.character_sets["normal"].setCharacter("U+212F","e","ℯ");
Script.character_sets["normal"].setCharacter("U+2113","l","ℓ");

export default Script;