import React from "react";
import IconButton from "@mui/material/IconButton";
import { FormatListBulleted, EmojiEmotions, Remove } from "@mui/icons-material";
import "./SpecialInputTypeButtons.scss";

class SpecialInputTypeButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: {
        normal: this.props.manager.mode.normal,
        list: this.props.manager.mode.list,
      },
      snackbarOpen: false,
    };
    this.toggleBulletListMode = this.toggleBulletListMode.bind(this);
    this.handleInsertHorizontalRule =
      this.handleInsertHorizontalRule.bind(this);
    this.toggleEmojiPicker = this.toggleEmojiPicker.bind(this);

    this.app_manager_id = null;
  }
  /***************************************************************************/
  componentDidMount() {
    this.app_manager_id = this.props.manager.registerListener((changed) => {
      let changedSet = new Set(changed);
      if (changedSet.has("mode") || changedSet.has("settingsEnabled")) {
        this.setState((state, props) => {
          return {
            mode: {
              normal: this.props.manager.mode.normal,
              list: this.props.manager.mode.list,
            },
          };
        });
      }
    });
  }
  /***************************************************************************/
  componentWillUnmount() {
    this.props.manager.stringManager.unregisterListener(
      this.complex_unicode_string_manager_id
    );

    this.props.manager.unregisterListener(this.app_manager_id);
  }
  /***************************************************************************/
  toggleBulletListMode(e) {
    this.props.manager.toggleMode("list");
  }
  /***************************************************************************/
  handleInsertHorizontalRule(e) {
    this.props.manager.stringManager.insertSpecialInput(
      this.props.manager.constants["HORIZONTAL_RULE"]
    );
    this.props.manager.enableSettings("horizontal_rule");
  }
  /***************************************************************************/
  toggleEmojiPicker() {
    this.props.manager.togglePanel("emojis");
  }

  /***************************************************************************/

  render() {
    return (
      <React.Fragment>
        <IconButton
          size="small"
          className={this.state.mode.list ? "active-button" : ""}
          onClick={this.toggleBulletListMode}
        >
          <FormatListBulleted />
        </IconButton>
        <IconButton size="small" onClick={this.handleInsertHorizontalRule}>
          <Remove />
        </IconButton>
      </React.Fragment>
    );
  }
}

export default SpecialInputTypeButtons;
