import State from "./State.js";
import TagModel from "../../TagModel.js";
class PossibleTagState extends State {
  constructor(dispatcher) {
    super("possible tag", dispatcher);
    this.data = new TagModel();
  }
  spawn(command, ch, t) {
    let outputState = null;
    let insertCommands = new Set(["insert single"]);

    if (insertCommands.has(command) && ch.search(this.validTag) !== -1) {
      outputState = new this.dispatcher.types["tag"](this.dispatcher);
      this.data.insert(t.start, ch);
    } else
      outputState = new this.dispatcher.types["modification"](this.dispatcher);

    this.setOutputState(outputState);
    return outputState;
  }
}

export default PossibleTagState;
