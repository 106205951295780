import Insert from "./Insert.js";
import RemoveSelection from "./RemoveSelection.js";
import Command from "./Command.js";

class Replace extends Command {
  constructor(data, attributes, cursor) {
    super("replace", data, attributes, cursor);
  }
  exec(string) {
    this.input_state = string.hashify();
    let removeSelection = this.manager.commandFactory.make("remove selection", [
      this.cursor,
    ]);
    let insert = this.manager.commandFactory.make("insert", [
      this.data,
      this.attributes,
      this.cursor,
    ]);
    this.subCommands.push(removeSelection);
    this.subCommands.push(insert);

    this.execSubCommands(string);
    this.output_state = string.hashify();
  }

  execSubCommands(string) {
    for (let i = 0; i < this.subCommands.length; i++)
      this.subCommands[i].exec(string);
  }
}
export default Replace;
