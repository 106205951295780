import State from "./State.js";
class TagState extends State {
  constructor(dispatcher) {
    super("tag", dispatcher);
  }
  spawn(command, ch, t) {
    let outputState = null;
    let insertCommands = new Set(["insert single"]);
    let removeCommands = new Set(["remove single", "remove selection"]);

    if (insertCommands.has(command) && ch.search(this.validTag) !== -1) {
      outputState = new this.dispatcher.types["tag"](this.dispatcher);
      this.data.insert(t.start, ch);
    } else if (removeCommands.has(command) && ch.search(this.validTag) !== -1) {
      this.data.string = this.data.string.slice(0, this.data.string.length - 1);
      outputState = new this.dispatcher.types["tag"](this.dispatcher);
      this.data.remove(t.start);
    } else if (removeCommands.has(command) && ch === "#") {
      this.data = null;
      outputState = new this.dispatcher.types["modification"](this.dispatcher);
    } else outputState = new this.dispatcher.types["tag end"](this.dispatcher);

    this.setOutputState(outputState);
    return outputState;
  }
}

export default TagState;
