import Font from "../Font.js";

const AngloRunes= new Font(218,"AngloRunes");
AngloRunes.initCharacterSet("normal",219);

AngloRunes.character_sets["normal"].setCharacter(null,"a","ᚪ");
AngloRunes.character_sets["normal"].setCharacter(null,"b","ᛒ");
AngloRunes.character_sets["normal"].setCharacter(null,"c","ᚳ");
AngloRunes.character_sets["normal"].setCharacter(null,"d","ᛞ");
AngloRunes.character_sets["normal"].setCharacter(null,"e","ᛖ");
AngloRunes.character_sets["normal"].setCharacter(null,"f","ᚠ");
AngloRunes.character_sets["normal"].setCharacter(null,"g","ᚷ");
AngloRunes.character_sets["normal"].setCharacter(null,"h","ᚻ");
AngloRunes.character_sets["normal"].setCharacter(null,"i","ᛁ");
AngloRunes.character_sets["normal"].setCharacter(null,"j","ᛄ");
AngloRunes.character_sets["normal"].setCharacter(null,"k","ᛣ");
AngloRunes.character_sets["normal"].setCharacter(null,"l","ᛚ");
AngloRunes.character_sets["normal"].setCharacter(null,"m","ᛗ");
AngloRunes.character_sets["normal"].setCharacter(null,"n","ᚾ");
AngloRunes.character_sets["normal"].setCharacter(null,"o","ᚩ");
AngloRunes.character_sets["normal"].setCharacter(null,"p","ᛈ");
AngloRunes.character_sets["normal"].setCharacter(null,"q","ᚳ");
AngloRunes.character_sets["normal"].setCharacter(null,"r","ᚱ");
AngloRunes.character_sets["normal"].setCharacter(null,"s","ᛋ");
AngloRunes.character_sets["normal"].setCharacter(null,"t","ᛏ");
AngloRunes.character_sets["normal"].setCharacter(null,"u","ᚢ");
AngloRunes.character_sets["normal"].setCharacter(null,"v","ᚹ");
AngloRunes.character_sets["normal"].setCharacter(null,"w","ᚹ");
AngloRunes.character_sets["normal"].setCharacter(null,"x","ᛉ");
AngloRunes.character_sets["normal"].setCharacter(null,"y","ᚣ");
AngloRunes.character_sets["normal"].setCharacter(null,"z","ᛋ");
AngloRunes.character_sets["normal"].setCharacter(null,"ᚦ","ᚦ");
AngloRunes.character_sets["normal"].setCharacter(null,"ᛝ","ᛝ");
AngloRunes.character_sets["normal"].setCharacter(null," ","᛫");


export default AngloRunes;