import Font from "../Font.js";

const Slipknot = new Font(216,"Slipknot");

Slipknot.initCharacterSet("normal",217);
Slipknot.character_sets["normal"].setCharacter(null,"A","₳");
Slipknot.character_sets["normal"].setCharacter(null,"B","฿");
Slipknot.character_sets["normal"].setCharacter(null,"C","₵");
Slipknot.character_sets["normal"].setCharacter(null,"D","Đ");
Slipknot.character_sets["normal"].setCharacter(null,"E","Ɇ");
Slipknot.character_sets["normal"].setCharacter(null,"F","₣");
Slipknot.character_sets["normal"].setCharacter(null,"G","₲");
Slipknot.character_sets["normal"].setCharacter(null,"H","Ⱨ");
Slipknot.character_sets["normal"].setCharacter(null,"I","ł");
Slipknot.character_sets["normal"].setCharacter(null,"J","J");
Slipknot.character_sets["normal"].setCharacter(null,"K","₭");
Slipknot.character_sets["normal"].setCharacter(null,"L","Ⱡ");
Slipknot.character_sets["normal"].setCharacter(null,"M","₥");
Slipknot.character_sets["normal"].setCharacter(null,"N","₦");
Slipknot.character_sets["normal"].setCharacter(null,"O","Ø");
Slipknot.character_sets["normal"].setCharacter(null,"P","₱");
Slipknot.character_sets["normal"].setCharacter(null,"Q","Q");
Slipknot.character_sets["normal"].setCharacter(null,"R","Ɽ");
Slipknot.character_sets["normal"].setCharacter(null,"S","₴");
Slipknot.character_sets["normal"].setCharacter(null,"T","₮");
Slipknot.character_sets["normal"].setCharacter(null,"U","Ʉ");
Slipknot.character_sets["normal"].setCharacter(null,"V","V");
Slipknot.character_sets["normal"].setCharacter(null,"W","₩");
Slipknot.character_sets["normal"].setCharacter(null,"X","Ӿ");
Slipknot.character_sets["normal"].setCharacter(null,"Y","Ɏ");
Slipknot.character_sets["normal"].setCharacter(null,"Z","Ⱬ");
Slipknot.character_sets["normal"].setCharacter(null,"a","₳");
Slipknot.character_sets["normal"].setCharacter(null,"b","฿");
Slipknot.character_sets["normal"].setCharacter(null,"c","₵");
Slipknot.character_sets["normal"].setCharacter(null,"d","Đ");
Slipknot.character_sets["normal"].setCharacter(null,"e","Ɇ");
Slipknot.character_sets["normal"].setCharacter(null,"f","₣");
Slipknot.character_sets["normal"].setCharacter(null,"g","₲");
Slipknot.character_sets["normal"].setCharacter(null,"h","Ⱨ");
Slipknot.character_sets["normal"].setCharacter(null,"i","ł");
Slipknot.character_sets["normal"].setCharacter(null,"j","J");
Slipknot.character_sets["normal"].setCharacter(null,"k","₭");
Slipknot.character_sets["normal"].setCharacter(null,"l","Ⱡ");
Slipknot.character_sets["normal"].setCharacter(null,"m","₥");
Slipknot.character_sets["normal"].setCharacter(null,"n","₦");
Slipknot.character_sets["normal"].setCharacter(null,"o","Ø");
Slipknot.character_sets["normal"].setCharacter(null,"p","₱");
Slipknot.character_sets["normal"].setCharacter(null,"q","Q");
Slipknot.character_sets["normal"].setCharacter(null,"r","Ɽ");
Slipknot.character_sets["normal"].setCharacter(null,"s","₴");
Slipknot.character_sets["normal"].setCharacter(null,"t","₮");
Slipknot.character_sets["normal"].setCharacter(null,"u","Ʉ");
Slipknot.character_sets["normal"].setCharacter(null,"v","V");
Slipknot.character_sets["normal"].setCharacter(null,"w","₩");
Slipknot.character_sets["normal"].setCharacter(null,"x","Ӿ");
Slipknot.character_sets["normal"].setCharacter(null,"y","Ɏ");
Slipknot.character_sets["normal"].setCharacter(null,"z","Ⱬ");
Slipknot.character_sets["normal"].setCharacter(null,"0","0");
Slipknot.character_sets["normal"].setCharacter(null,"1","1");
Slipknot.character_sets["normal"].setCharacter(null,"2","2");
Slipknot.character_sets["normal"].setCharacter(null,"3","3");
Slipknot.character_sets["normal"].setCharacter(null,"4","4");
Slipknot.character_sets["normal"].setCharacter(null,"5","5");
Slipknot.character_sets["normal"].setCharacter(null,"6","6");
Slipknot.character_sets["normal"].setCharacter(null,"7","7");
Slipknot.character_sets["normal"].setCharacter(null,"8","8");
Slipknot.character_sets["normal"].setCharacter(null,"9","9");

export default Slipknot;