import runes from "runes";
class ComplexUnicodeChar {
  /***********************************************************************/
  constructor(parent, symbol, actualSymbol = "", relativeIndex = 0) {
    this.parent = parent;
    this.symbol = symbol;
    this.transformations = [];
    this.transformationTypes = new Set();
    this.specialType = null;
    this.relativeIndex = relativeIndex;
    this.children = actualSymbol !== "" ? runes(actualSymbol) : [];
    this.actualLength = actualSymbol.length;
  }
  /***********************************************************************/
  addTransformation(t) {
    let name = Object.keys(t)[0];
    if (!this.transformationTypes.has(name)) {
      this.transformations.push(t);
      this.transformationTypes.add(name);
    }
  }
  /***********************************************************************/
  hasTransformations() {
    return this.transformations.length > 0;
  }
  /***********************************************************************/
  getTransformations() {
    return this.transformations;
  }
  /***********************************************************************/
  getSpecialType() {
    return this.specialType;
  }
  /***********************************************************************/
  hasSpecialType() {
    return this.specialType !== null;
  }
  /***********************************************************************/
  setSpecialType(s) {
    this.specialType = s;
  }
  /***********************************************************************/
  hasChildren() {
    return this.children.length > 0;
  }
  /***********************************************************************/
  isValid() {
    return this.symbol !== "";
  }
  /***********************************************************************/
  isComplex() {
    return this.hasChildren() && this.children.length > 1;
  }
  /***********************************************************************/
  length() {
    if (this.isValid()) return this.symbol.length;
    return 0;
  }
  /***********************************************************************/
  createActualSymbol() {
    let str = "";
    for (let i = 0; i < this.children.length; i++) str += this.children[i];

    return str;
  }
  /***********************************************************************/
  hashify() {
    return {
      parent: this.parent.hashify(),
      symbol: this.symbol,
      children: this.children,
      actualSymbol: this.createActualSymbol(),
      relativeIndex: this.relativeIndex,
    };
  }
}

export default ComplexUnicodeChar;
