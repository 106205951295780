import React from "react";
import IconButton from "@mui/material/IconButton";
import { UndoOutlined, RedoOutlined } from "@mui/icons-material";
import "./History.scss";

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: this.props.manager.stringManager.current,
      commands: this.props.manager.stringManager.commands,
    };
    this.handleRedo = this.handleRedo.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
  }
  /***************************************************************************/
  componentDidMount() {
    this.unicode_string_manager_id =
      this.props.manager.stringManager.registerListener((e, changed) => {
        let changedSet = new Set(changed);
        if (changedSet.has("state")) {
          this.setState((state, props) => {
            return {
              current: this.props.manager.stringManager.current,
              commands: this.props.manager.stringManager.commands,
            };
          });
        }
      });
  }
  /***************************************************************************/
  componentWillUnmount() {
    this.props.manager.stringManager.unregisterListener(
      this.unicode_string_manager_id
    );
  }
  /***************************************************************************/
  handleUndo() {
    this.props.manager.stringManager.historyManager.undo();
  }
  handleRedo() {
    this.props.manager.stringManager.historyManager.redo();
  }
  /***************************************************************************/

  render() {
    const lastIndex = this.state.commands.length - 1;
    return (
      <React.Fragment>
        <IconButton
          size="small"
          disabled={!(this.state.current >= 1)}
          onClick={this.handleUndo}
        >
          <UndoOutlined />
        </IconButton>
        <IconButton
          size="small"
          disabled={!(this.state.current < lastIndex)}
          onClick={this.handleRedo}
        >
          <RedoOutlined />
        </IconButton>
      </React.Fragment>
    );
  }
}

export default History;
