import Font from "../Font.js";

const Strikethrough = new Font(204,"Strikethrough");

Strikethrough.initCharacterSet("normal",205);
Strikethrough.character_sets["normal"].setCharacter(null,"A","A̶");
Strikethrough.character_sets["normal"].setCharacter(null,"B","B̶");
Strikethrough.character_sets["normal"].setCharacter(null,"C","C̶");
Strikethrough.character_sets["normal"].setCharacter(null,"D","D̶");
Strikethrough.character_sets["normal"].setCharacter(null,"E","E̶");
Strikethrough.character_sets["normal"].setCharacter(null,"F","F̶");
Strikethrough.character_sets["normal"].setCharacter(null,"G","G̶");
Strikethrough.character_sets["normal"].setCharacter(null,"H","H̶");
Strikethrough.character_sets["normal"].setCharacter(null,"I","I̶");
Strikethrough.character_sets["normal"].setCharacter(null,"J","J̶");
Strikethrough.character_sets["normal"].setCharacter(null,"K","K̶");
Strikethrough.character_sets["normal"].setCharacter(null,"L","L̶");
Strikethrough.character_sets["normal"].setCharacter(null,"M","M̶");
Strikethrough.character_sets["normal"].setCharacter(null,"N","N̶");
Strikethrough.character_sets["normal"].setCharacter(null,"O","O̶");
Strikethrough.character_sets["normal"].setCharacter(null,"P","P̶");
Strikethrough.character_sets["normal"].setCharacter(null,"Q","Q̶");
Strikethrough.character_sets["normal"].setCharacter(null,"R","R̶");
Strikethrough.character_sets["normal"].setCharacter(null,"S","S̶");
Strikethrough.character_sets["normal"].setCharacter(null,"T","T̶");
Strikethrough.character_sets["normal"].setCharacter(null,"U","U̶");
Strikethrough.character_sets["normal"].setCharacter(null,"V","V̶");
Strikethrough.character_sets["normal"].setCharacter(null,"W","W̶");
Strikethrough.character_sets["normal"].setCharacter(null,"X","X̶");
Strikethrough.character_sets["normal"].setCharacter(null,"Y","Y̶");
Strikethrough.character_sets["normal"].setCharacter(null,"Z","Z̶");
Strikethrough.character_sets["normal"].setCharacter(null,"a","a̶");
Strikethrough.character_sets["normal"].setCharacter(null,"b","b̶");
Strikethrough.character_sets["normal"].setCharacter(null,"c","c̶");
Strikethrough.character_sets["normal"].setCharacter(null,"d","d̶");
Strikethrough.character_sets["normal"].setCharacter(null,"e","e̶");
Strikethrough.character_sets["normal"].setCharacter(null,"f","f̶");
Strikethrough.character_sets["normal"].setCharacter(null,"g","g̶");
Strikethrough.character_sets["normal"].setCharacter(null,"h","h̶");
Strikethrough.character_sets["normal"].setCharacter(null,"i","i̶");
Strikethrough.character_sets["normal"].setCharacter(null,"j","j̶");
Strikethrough.character_sets["normal"].setCharacter(null,"k","k̶");
Strikethrough.character_sets["normal"].setCharacter(null,"l","l̶");
Strikethrough.character_sets["normal"].setCharacter(null,"m","m̶");
Strikethrough.character_sets["normal"].setCharacter(null,"n","n̶");
Strikethrough.character_sets["normal"].setCharacter(null,"o","o̶");
Strikethrough.character_sets["normal"].setCharacter(null,"p","p̶");
Strikethrough.character_sets["normal"].setCharacter(null,"q","q̶");
Strikethrough.character_sets["normal"].setCharacter(null,"r","r̶");
Strikethrough.character_sets["normal"].setCharacter(null,"s","s̶");
Strikethrough.character_sets["normal"].setCharacter(null,"t","t̶");
Strikethrough.character_sets["normal"].setCharacter(null,"u","u̶");
Strikethrough.character_sets["normal"].setCharacter(null,"v","v̶");
Strikethrough.character_sets["normal"].setCharacter(null,"w","w̶");
Strikethrough.character_sets["normal"].setCharacter(null,"x","x̶");
Strikethrough.character_sets["normal"].setCharacter(null,"y","y̶");
Strikethrough.character_sets["normal"].setCharacter(null,"z","z̶");
Strikethrough.character_sets["normal"].setCharacter(null,"0","0̶");
Strikethrough.character_sets["normal"].setCharacter(null,"1","1̶");
Strikethrough.character_sets["normal"].setCharacter(null,"2","2̶");
Strikethrough.character_sets["normal"].setCharacter(null,"3","3̶");
Strikethrough.character_sets["normal"].setCharacter(null,"4","4̶");
Strikethrough.character_sets["normal"].setCharacter(null,"5","5̶");
Strikethrough.character_sets["normal"].setCharacter(null,"6","6̶");
Strikethrough.character_sets["normal"].setCharacter(null,"7","7̶");
Strikethrough.character_sets["normal"].setCharacter(null,"8","8̶");
Strikethrough.character_sets["normal"].setCharacter(null,"9","9̶");

export default Strikethrough;