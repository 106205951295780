import React from "react";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./HelpModal.scss";

class HelpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.manager.getPanel("help"),
      shown: [false, false],
    };
    this.app_manager_id = null;
    this.handleClose = this.handleClose.bind(this);
  }
  /***************************************************************************/
  componentDidMount() {
    this.app_manager_id = this.props.manager.registerListener((changed) => {
      let changedSet = new Set(changed);
      if (changedSet.has("panelsOpen")) {
        this.setState((state, props) => {
          return {
            open: this.props.manager.getPanel("help"),
          };
        });
      }
    });
  }
  /***************************************************************************/
  componentWillUnmount() {
    this.props.manager.unregisterListener(this.app_manager_id);
  }
  /***************************************************************************/
  handleClose() {
    this.props.manager.togglePanel("help");
  }

  /***************************************************************************/
  render() {
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
        fullWidth={true}
      >
        <MuiDialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          Help
        </MuiDialogTitle>
        <MuiDialogContent dividers>
          <Typography gutterBottom>
            <ul className="help-list">
              <li
                onClick={() => {
                  this.setState((state, props) => {
                    let shown = [...this.state.shown];
                    shown[0] = !shown[0];
                    return { shown: shown };
                  });
                }}
              >
                <div className="question">
                  What fonts have bold and italic styles?
                </div>
                <div className={this.state.shown[0] ? "show" : ""}>
                  The fonts with bold styles are
                  <b> SansSerif, Serif, Fraktur</b> and <b>Script</b>. Only{" "}
                  <b>SansSerif</b> and <b>Serif</b> have italic styles.
                </div>
              </li>
              <li
                onClick={() => {
                  this.setState((state, props) => {
                    let shown = [...this.state.shown];
                    shown[1] = !shown[1];
                    return { shown: shown };
                  });
                }}
              >
                <div className="question">
                  I added some hashtags and tags to my caption, but when I tried
                  to use them on Instagram, they didn't work. What is happening?
                </div>
                <div className={this.state.shown[1] ? "show" : ""}>
                  Instagram does not allow hashtags or tags to contain formatted
                  text. To make sure your hashtags or tags do not contain any
                  formatted text, select them and then mark them as
                  hashtags/tags. To mark them as hashtags/tags, click on the
                  three dots. It will launch a submenu. Choose "Mark as
                  Hashtag/Tag" from that menu.
                </div>
              </li>
            </ul>
          </Typography>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button autoFocus onClick={this.handleClose} color="primary">
            Close
          </Button>
        </MuiDialogActions>
      </Dialog>
    );
  }
}

export default HelpModal;
