import Font from "../Font.js";

const Lite = new Font(228,"Lite");

Lite.initCharacterSet("normal",229);
Lite.character_sets["normal"].setCharacter(null,"A","Ａ");
Lite.character_sets["normal"].setCharacter(null,"B","Ｂ");
Lite.character_sets["normal"].setCharacter(null,"C","Ｃ");
Lite.character_sets["normal"].setCharacter(null,"D","Ｄ");
Lite.character_sets["normal"].setCharacter(null,"E","Ｅ");
Lite.character_sets["normal"].setCharacter(null,"F","Ｆ");
Lite.character_sets["normal"].setCharacter(null,"G","Ｇ");
Lite.character_sets["normal"].setCharacter(null,"H","Ｈ");
Lite.character_sets["normal"].setCharacter(null,"I","Ｉ");
Lite.character_sets["normal"].setCharacter(null,"J","Ｊ");
Lite.character_sets["normal"].setCharacter(null,"K","Ｋ");
Lite.character_sets["normal"].setCharacter(null,"L","Ｌ");
Lite.character_sets["normal"].setCharacter(null,"M","Ｍ");
Lite.character_sets["normal"].setCharacter(null,"N","Ｎ");
Lite.character_sets["normal"].setCharacter(null,"O","Ｏ");
Lite.character_sets["normal"].setCharacter(null,"P","Ｐ");
Lite.character_sets["normal"].setCharacter(null,"Q","Ｑ");
Lite.character_sets["normal"].setCharacter(null,"R","Ｒ");
Lite.character_sets["normal"].setCharacter(null,"S","Ｓ");
Lite.character_sets["normal"].setCharacter(null,"T","Ｔ");
Lite.character_sets["normal"].setCharacter(null,"U","Ｕ");
Lite.character_sets["normal"].setCharacter(null,"V","Ｖ");
Lite.character_sets["normal"].setCharacter(null,"W","Ｗ");
Lite.character_sets["normal"].setCharacter(null,"X","Ｘ");
Lite.character_sets["normal"].setCharacter(null,"Y","Ｙ");
Lite.character_sets["normal"].setCharacter(null,"Z","Ｚ");
Lite.character_sets["normal"].setCharacter(null,"a","ａ");
Lite.character_sets["normal"].setCharacter(null,"b","ｂ");
Lite.character_sets["normal"].setCharacter(null,"c","ｃ");
Lite.character_sets["normal"].setCharacter(null,"d","ｄ");
Lite.character_sets["normal"].setCharacter(null,"e","ｅ");
Lite.character_sets["normal"].setCharacter(null,"f","ｆ");
Lite.character_sets["normal"].setCharacter(null,"g","ｇ");
Lite.character_sets["normal"].setCharacter(null,"h","ｈ");
Lite.character_sets["normal"].setCharacter(null,"i","ｉ");
Lite.character_sets["normal"].setCharacter(null,"j","ｊ");
Lite.character_sets["normal"].setCharacter(null,"k","ｋ");
Lite.character_sets["normal"].setCharacter(null,"l","ｌ");
Lite.character_sets["normal"].setCharacter(null,"m","ｍ");
Lite.character_sets["normal"].setCharacter(null,"n","ｎ");
Lite.character_sets["normal"].setCharacter(null,"o","ｏ");
Lite.character_sets["normal"].setCharacter(null,"p","ｐ");
Lite.character_sets["normal"].setCharacter(null,"q","ｑ");
Lite.character_sets["normal"].setCharacter(null,"r","ｒ");
Lite.character_sets["normal"].setCharacter(null,"s","ｓ");
Lite.character_sets["normal"].setCharacter(null,"t","ｔ");
Lite.character_sets["normal"].setCharacter(null,"u","ｕ");
Lite.character_sets["normal"].setCharacter(null,"v","ｖ");
Lite.character_sets["normal"].setCharacter(null,"w","ｗ");
Lite.character_sets["normal"].setCharacter(null,"x","ｘ");
Lite.character_sets["normal"].setCharacter(null,"y","ｙ");
Lite.character_sets["normal"].setCharacter(null,"z","ｚ");
Lite.character_sets["normal"].setCharacter(null,"0","０");
Lite.character_sets["normal"].setCharacter(null,"1","１");
Lite.character_sets["normal"].setCharacter(null,"2","２");
Lite.character_sets["normal"].setCharacter(null,"3","３");
Lite.character_sets["normal"].setCharacter(null,"4","４");
Lite.character_sets["normal"].setCharacter(null,"5","５");
Lite.character_sets["normal"].setCharacter(null,"6","６");
Lite.character_sets["normal"].setCharacter(null,"7","７");
Lite.character_sets["normal"].setCharacter(null,"8","８");
Lite.character_sets["normal"].setCharacter(null,"9","９");

export default Lite;