class HistoryManager {
  constructor(manager) {
    this.manager = manager;
  }
  /***********************************************************************/
  canRedo() {
    const lastIndex = this.manager.commands.length - 1;
    return this.manager.current < lastIndex;
  }
  /***********************************************************************/
  canUndo() {
    return this.manager.current >= 1;
  }
  /***********************************************************************/
  undo() {
    if (this.canUndo()) {
      const hash =
        this.manager.commands[this.manager.current - 1].getOutputState();
      this.manager.model.reinstateFromHash(hash);
      this.manager.current--;
      this.manager.notifyListeners(["state", "current"]);
    }
  }
  /***********************************************************************/
  redo() {
    if (this.canRedo()) {
      const hash =
        this.manager.commands[this.manager.current + 1].getOutputState();
      this.manager.model.reinstateFromHash(hash);
      this.manager.current++;
      this.manager.notifyListeners(["state", "current"]);
    }
  }
}

export default HistoryManager;
