class Command {
  constructor(name, data, attributes, cursor) {
    this.name = name;
    this.data = data;
    this.attributes = attributes;
    this.cursor = cursor;
    this.manager = null;
    this.input_state = null;
    this.output_state = null;
    this.subCommands = [];
    this.state = 0; // 1 = processing, 2 = done
  }

  startCommand() {
    this.state = 1;
  }

  getCommandState() {
    return this.state;
  }

  isComplete() {
    return this.state === 2;
  }

  endCommand() {
    this.state = 2;
  }

  getStateManager() {
    return this.manager.stateManager;
  }

  getOutputState() {
    return this.output_state;
  }

  getInputState() {
    return this.input_state;
  }
  equals(command) {
    return (
      this.name === command.name &&
      this.data === command.data &&
      this.attributes.equals(command.attributes) &&
      this.cursor.equals(command.cursor)
    );
  }

  getName() {
    return this.name;
  }

  setManager(m) {
    this.manager = m;
  }
  exec() {
    return null;
  }
  hashify() {
    return {
      name: this.name,
      data: this.data,
      attributes: this.attributes.hashify(),
      cursor: this.cursor.hashify(),
    };
  }
}

export default Command;
