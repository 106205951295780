import State from "./State.js";

class ModificationState extends State {
  constructor(dispatcher) {
    super("modification", dispatcher);
  }
  spawn(command, ch, t) {
    let outputState = null;
    if (ch === "@") {
      outputState = new this.dispatcher.types["possible tag"](this.dispatcher);
      outputState.data.startIndex = t.start;
      outputState.data.endIndex = t.start;
    } else if (ch === "#") {
      outputState = new this.dispatcher.types["possible hashtag"](
        this.dispatcher
      );
      outputState.data.startIndex = t.start;
      outputState.data.endIndex = t.start;
    } else
      outputState = new this.dispatcher.types["modification"](this.dispatcher);

    this.setOutputState(outputState);
    return outputState;
  }
}

export default ModificationState;
