class HashtagModel {
  constructor() {
    this.string = {};
    this.startIndex = null;
    this.endIndex = null;
  }
  /*************************************************************/
  insert(pos, c) {
    if (pos < this.startIndex || pos > this.endIndex + 1) return null;

    // if appending to end
    if (pos === this.endIndex + 1) this.string[pos] = c;

    // if inserting in the middle
    if (pos >= this.startIndex && pos <= this.endIndex) {
      // copy after after insertion point
      let copy = {};
      for (let i = pos, j = 0; i <= this.endIndex; i++, j++)
        copy[j] = this.string[i];

      // Now let's do insertion
      this.string[pos] = c;
      for (let i = pos + 1, j = 0; i <= this.endIndex + 1; i++, j++)
        this.string[i] = copy[j];
    }

    if (this.endIndex !== null && pos > this.endIndex) this.endIndex = pos;
  }
  /*************************************************************/
  remove(pos) {
    for (let i = pos + 1; i <= this.endIndex; i++)
      this.string[i - 1] = this.string[i];

    this.endIndex -= 1;
  }
}
export default HashtagModel;
