import Font from "../Font.js";

const Its_All_Greek = new Font(248,"Its All Greek");

Its_All_Greek.initCharacterSet("normal",249);
Its_All_Greek.character_sets["normal"].setCharacter(null,"A","Δ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"B","Ɓ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"C","C");
Its_All_Greek.character_sets["normal"].setCharacter(null,"D","D");
Its_All_Greek.character_sets["normal"].setCharacter(null,"E","Σ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"F","F");
Its_All_Greek.character_sets["normal"].setCharacter(null,"G","G");
Its_All_Greek.character_sets["normal"].setCharacter(null,"H","H");
Its_All_Greek.character_sets["normal"].setCharacter(null,"I","I");
Its_All_Greek.character_sets["normal"].setCharacter(null,"J","J");
Its_All_Greek.character_sets["normal"].setCharacter(null,"K","Ƙ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"L","L");
Its_All_Greek.character_sets["normal"].setCharacter(null,"M","Μ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"N","∏");
Its_All_Greek.character_sets["normal"].setCharacter(null,"O","Θ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"P","Ƥ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"Q","Ⴓ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"R","Γ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"S","Ѕ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"T","Ƭ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"U","Ʊ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"V","Ʋ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"W","Ш");
Its_All_Greek.character_sets["normal"].setCharacter(null,"X","Ж");
Its_All_Greek.character_sets["normal"].setCharacter(null,"Y","Ψ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"Z","Z");
Its_All_Greek.character_sets["normal"].setCharacter(null,"a","λ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"b","ϐ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"c","ς");
Its_All_Greek.character_sets["normal"].setCharacter(null,"d","d");
Its_All_Greek.character_sets["normal"].setCharacter(null,"e","ε");
Its_All_Greek.character_sets["normal"].setCharacter(null,"f","ғ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"g","ϑ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"h","ɢ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"i","н");
Its_All_Greek.character_sets["normal"].setCharacter(null,"j","ι");
Its_All_Greek.character_sets["normal"].setCharacter(null,"k","ϳ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"l","κ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"m","l");
Its_All_Greek.character_sets["normal"].setCharacter(null,"n","ϻ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"o","π");
Its_All_Greek.character_sets["normal"].setCharacter(null,"p","σ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"q","ρ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"r","φ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"s","г");
Its_All_Greek.character_sets["normal"].setCharacter(null,"t","s");
Its_All_Greek.character_sets["normal"].setCharacter(null,"u","τ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"v","υ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"w","v");
Its_All_Greek.character_sets["normal"].setCharacter(null,"x","ш");
Its_All_Greek.character_sets["normal"].setCharacter(null,"y","ϰ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"z","ψ");
Its_All_Greek.character_sets["normal"].setCharacter(null,"0","z");
Its_All_Greek.character_sets["normal"].setCharacter(null,"1","0");
Its_All_Greek.character_sets["normal"].setCharacter(null,"2","1");
Its_All_Greek.character_sets["normal"].setCharacter(null,"3","2");
Its_All_Greek.character_sets["normal"].setCharacter(null,"4","3");
Its_All_Greek.character_sets["normal"].setCharacter(null,"5","4");
Its_All_Greek.character_sets["normal"].setCharacter(null,"6","5");
Its_All_Greek.character_sets["normal"].setCharacter(null,"7","6");
Its_All_Greek.character_sets["normal"].setCharacter(null,"8","7");
Its_All_Greek.character_sets["normal"].setCharacter(null,"9","8");

export default Its_All_Greek;