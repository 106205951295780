import State from "./State.js";
import HashtagModel from "../../HashtagModel.js";
class PossibleHashtagState extends State {
  constructor(dispatcher) {
    super("possible hashtag", dispatcher);
    this.data = new HashtagModel();
  }
  spawn(command, ch, t) {
    let outputState = null;
    let insertCommands = new Set(["insert single"]);

    if (insertCommands.has(command) && ch.search(this.validHashtag) !== -1) {
      outputState = new this.dispatcher.types["hashtag"](this.dispatcher);
      this.data.insert(t.start, ch);
    } else
      outputState = new this.dispatcher.types["modification"](this.dispatcher);

    this.setOutputState(outputState);
    return outputState;
  }
}

export default PossibleHashtagState;
