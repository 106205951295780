import React from "react";
import IconButton from "@mui/material/IconButton";
import { FormatBold, FormatItalic } from "@mui/icons-material";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import "./FormatBar.scss";

class FormatBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bold: this.props.manager.stringManager.getCurrentStyle().bold,
      italic: this.props.manager.stringManager.getCurrentStyle().italic,
      font: {
        name: this.props.manager.stringManager.getCurrentFont(),
        hasBold: this.props.manager.stringManager
          .getCurrentFontModel()
          .hasStyle("bold"),
        hasItalic: this.props.manager.stringManager
          .getCurrentFontModel()
          .hasStyle("italic"),
      },
    };

    this.unicode_string_manager_id = null;
    this.handleBold = this.handleBold.bind(this);
    this.handleItalic = this.handleItalic.bind(this);
    this.handleOpenFonts = this.handleOpenFonts.bind(this);
  }

  /***************************************************************************/
  componentDidMount() {
    this.unicode_string_manager_id =
      this.props.manager.stringManager.registerListener((e, changed) => {
        let changedSet = new Set(changed);
        if (changedSet.has("style") && !changedSet.has("font")) {
          this.setState((state, props) => {
            return {
              bold: this.props.manager.stringManager.getCurrentStyle().bold,
              italic: this.props.manager.stringManager.getCurrentStyle().italic,
            };
          });
        } else if (changedSet.has("font")) {
          const fontModel =
            this.props.manager.stringManager.getCurrentFontModel();
          this.setState((state, props) => {
            return {
              font: {
                name: this.props.manager.stringManager.getCurrentFont(),
                hasBold: fontModel.hasStyle("bold"),
                hasItalic: fontModel.hasStyle("italic"),
              },
              bold: this.props.manager.stringManager.getCurrentStyle().bold,
              italic: this.props.manager.stringManager.getCurrentStyle().italic,
            };
          });
        }
      });
  }
  /***************************************************************************/
  componentWillUnmount() {
    this.props.manager.stringManager.unregisterListener(
      this.unicode_string_manager_id
    );
    this.props.manager.unregisterListener(this.app_manager_id);
  }
  /***************************************************************************/
  handleBold(e) {
    this.props.manager.stringManager.setStyle({
      bold: !this.state.bold,
      italic: this.state.italic,
    });
  }
  /***************************************************************************/
  handleItalic(e) {
    this.props.manager.stringManager.setStyle({
      bold: this.state.bold,
      italic: !this.state.italic,
    });
  }
  /***************************************************************************/
  handleOpenFonts(e) {
    this.props.manager.togglePanel("font selector");
  }
  /***************************************************************************/
  render() {
    return (
      <React.Fragment>
        <IconButton
          onClick={this.handleBold}
          className={this.state.bold ? "active-button" : ""}
          size="small"
          disabled={!this.state.font.hasBold}
        >
          <FormatBold />
        </IconButton>
        <IconButton
          onClick={this.handleItalic}
          className={this.state.italic ? "active-button" : ""}
          size="small"
          disabled={!this.state.font.hasItalic}
        >
          <FormatItalic />
        </IconButton>
        <IconButton size="small" onClick={this.handleOpenFonts}>
          <FontDownloadIcon />
        </IconButton>
      </React.Fragment>
    );
  }
}

export default FormatBar;
