import Font from "../Font.js";

const XOut = new Font(232,"X-Out");

XOut.initCharacterSet("normal",233);
XOut.character_sets["normal"].setCharacter(null,"A","A͓̽");
XOut.character_sets["normal"].setCharacter(null,"B","B͓̽");
XOut.character_sets["normal"].setCharacter(null,"C","C͓̽");
XOut.character_sets["normal"].setCharacter(null,"D","D͓̽");
XOut.character_sets["normal"].setCharacter(null,"E","E͓̽");
XOut.character_sets["normal"].setCharacter(null,"F","F͓̽");
XOut.character_sets["normal"].setCharacter(null,"G","G͓̽");
XOut.character_sets["normal"].setCharacter(null,"H","H͓̽");
XOut.character_sets["normal"].setCharacter(null,"I","I͓̽");
XOut.character_sets["normal"].setCharacter(null,"J","J͓̽");
XOut.character_sets["normal"].setCharacter(null,"K","K͓̽");
XOut.character_sets["normal"].setCharacter(null,"L","L͓̽");
XOut.character_sets["normal"].setCharacter(null,"M","M͓̽");
XOut.character_sets["normal"].setCharacter(null,"N","N͓̽");
XOut.character_sets["normal"].setCharacter(null,"O","O͓̽");
XOut.character_sets["normal"].setCharacter(null,"P","P͓̽");
XOut.character_sets["normal"].setCharacter(null,"Q","Q͓̽");
XOut.character_sets["normal"].setCharacter(null,"R","R͓̽");
XOut.character_sets["normal"].setCharacter(null,"S","S͓̽");
XOut.character_sets["normal"].setCharacter(null,"T","T͓̽");
XOut.character_sets["normal"].setCharacter(null,"U","U͓̽");
XOut.character_sets["normal"].setCharacter(null,"V","V͓̽");
XOut.character_sets["normal"].setCharacter(null,"W","W͓̽");
XOut.character_sets["normal"].setCharacter(null,"X","X͓̽");
XOut.character_sets["normal"].setCharacter(null,"Y","Y͓̽");
XOut.character_sets["normal"].setCharacter(null,"Z","Z͓̽");
XOut.character_sets["normal"].setCharacter(null,"a","a͓̽");
XOut.character_sets["normal"].setCharacter(null,"b","b͓̽");
XOut.character_sets["normal"].setCharacter(null,"c","c͓̽");
XOut.character_sets["normal"].setCharacter(null,"d","d͓̽");
XOut.character_sets["normal"].setCharacter(null,"e","e͓̽");
XOut.character_sets["normal"].setCharacter(null,"f","f͓̽");
XOut.character_sets["normal"].setCharacter(null,"g","g͓̽");
XOut.character_sets["normal"].setCharacter(null,"h","h͓̽");
XOut.character_sets["normal"].setCharacter(null,"i","i͓̽");
XOut.character_sets["normal"].setCharacter(null,"j","j͓̽");
XOut.character_sets["normal"].setCharacter(null,"k","k͓̽");
XOut.character_sets["normal"].setCharacter(null,"l","l͓̽");
XOut.character_sets["normal"].setCharacter(null,"m","m͓̽");
XOut.character_sets["normal"].setCharacter(null,"n","n͓̽");
XOut.character_sets["normal"].setCharacter(null,"o","o͓̽");
XOut.character_sets["normal"].setCharacter(null,"p","p͓̽");
XOut.character_sets["normal"].setCharacter(null,"q","q͓̽");
XOut.character_sets["normal"].setCharacter(null,"r","r͓̽");
XOut.character_sets["normal"].setCharacter(null,"s","s͓̽");
XOut.character_sets["normal"].setCharacter(null,"t","t͓̽");
XOut.character_sets["normal"].setCharacter(null,"u","u͓̽");
XOut.character_sets["normal"].setCharacter(null,"v","v͓̽");
XOut.character_sets["normal"].setCharacter(null,"w","w͓̽");
XOut.character_sets["normal"].setCharacter(null,"x","x͓̽");
XOut.character_sets["normal"].setCharacter(null,"y","y͓̽");
XOut.character_sets["normal"].setCharacter(null,"z","z͓̽");
XOut.character_sets["normal"].setCharacter(null,"0","0͓̽");
XOut.character_sets["normal"].setCharacter(null,"1","1͓̽");
XOut.character_sets["normal"].setCharacter(null,"2","2͓̽");
XOut.character_sets["normal"].setCharacter(null,"3","3͓̽");
XOut.character_sets["normal"].setCharacter(null,"4","4͓̽");
XOut.character_sets["normal"].setCharacter(null,"5","5͓̽");
XOut.character_sets["normal"].setCharacter(null,"6","6͓̽");
XOut.character_sets["normal"].setCharacter(null,"7","7͓̽");
XOut.character_sets["normal"].setCharacter(null,"8","8͓̽");
XOut.character_sets["normal"].setCharacter(null,"9","9͓̽");

export default XOut;