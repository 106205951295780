import Font from "../Font.js";

const Zalgo = new Font(210,"Zalgo");

Zalgo.initCharacterSet("normal",211);
Zalgo.character_sets["normal"].setCharacter(null,"A","A̷͙ͭͫ̕");
Zalgo.character_sets["normal"].setCharacter(null,"B","B̩͎͍̾ͅ");
Zalgo.character_sets["normal"].setCharacter(null,"C","C̵͉͋̔͞");
Zalgo.character_sets["normal"].setCharacter(null,"D","D̶͔̭̪̻");
Zalgo.character_sets["normal"].setCharacter(null,"E","Ḛͭ̉̇͟");
Zalgo.character_sets["normal"].setCharacter(null,"F","F̘͍͖ͫ͘");
Zalgo.character_sets["normal"].setCharacter(null,"G","G̩̱ͩ̏͜");
Zalgo.character_sets["normal"].setCharacter(null,"H","Hͥ̽ͣ̃̔");
Zalgo.character_sets["normal"].setCharacter(null,"I","I̍̅̀̎̊");
Zalgo.character_sets["normal"].setCharacter(null,"J","J̶̳̀́̃");
Zalgo.character_sets["normal"].setCharacter(null,"K","K͕͓͌̎̾");
Zalgo.character_sets["normal"].setCharacter(null,"L","L̸̖̽̌͂");
Zalgo.character_sets["normal"].setCharacter(null,"M","M͉̅ͮ͒ͤ");
Zalgo.character_sets["normal"].setCharacter(null,"N","N̺̻̔̆ͅ");
Zalgo.character_sets["normal"].setCharacter(null,"O","O̖̼ͩ͌͐");
Zalgo.character_sets["normal"].setCharacter(null,"P","P̧͕̒̊͘");
Zalgo.character_sets["normal"].setCharacter(null,"Q","Q̦̭̀̾͜");
Zalgo.character_sets["normal"].setCharacter(null,"R","R͉̜̎͡͠");
Zalgo.character_sets["normal"].setCharacter(null,"S","S̵̙͕̀̃");
Zalgo.character_sets["normal"].setCharacter(null,"T","T̨͈͗̌ͥ");
Zalgo.character_sets["normal"].setCharacter(null,"U","U̠҉̷̙ͦ");
Zalgo.character_sets["normal"].setCharacter(null,"V","V̘̪͆̂̅");
Zalgo.character_sets["normal"].setCharacter(null,"W","W̯ͤ̾ͣ͝");
Zalgo.character_sets["normal"].setCharacter(null,"X","X̵̹̬̄̽");
Zalgo.character_sets["normal"].setCharacter(null,"Y","Ỵ̛̖͋͢");
Zalgo.character_sets["normal"].setCharacter(null,"Z","Z̟̈́̆̉͜");
Zalgo.character_sets["normal"].setCharacter(null,"a","ā̤̓̍͘");
Zalgo.character_sets["normal"].setCharacter(null,"b","b̬͖̏́͢");
Zalgo.character_sets["normal"].setCharacter(null,"c","c͕͗ͤ̕̕");
Zalgo.character_sets["normal"].setCharacter(null,"d","ḑ̴̞͛̒");
Zalgo.character_sets["normal"].setCharacter(null,"e","ẹ̿͋̒̕");
Zalgo.character_sets["normal"].setCharacter(null,"f","f̵͖̜̉ͅ");
Zalgo.character_sets["normal"].setCharacter(null,"g","ĝ̽̓̀͑");
Zalgo.character_sets["normal"].setCharacter(null,"h","ḣ̖̻͛̓");
Zalgo.character_sets["normal"].setCharacter(null,"i","ỉ͔͖̜͌");
Zalgo.character_sets["normal"].setCharacter(null,"j","j̪̟̮̔ͩ");
Zalgo.character_sets["normal"].setCharacter(null,"k","ḳ̯͍̑ͦ");
Zalgo.character_sets["normal"].setCharacter(null,"l","l̙͖̑̾ͣ");
Zalgo.character_sets["normal"].setCharacter(null,"m","ḿ̬̏ͤͅ");
Zalgo.character_sets["normal"].setCharacter(null,"n","ṇ̤͛̒̍");
Zalgo.character_sets["normal"].setCharacter(null,"o","o̯̱̊͊͢");
Zalgo.character_sets["normal"].setCharacter(null,"p","p̞̈͑̚͞");
Zalgo.character_sets["normal"].setCharacter(null,"q","q͉ͬ͋̇ͥ");
Zalgo.character_sets["normal"].setCharacter(null,"r","r̴̨̦͕̝");
Zalgo.character_sets["normal"].setCharacter(null,"s","s̠҉͍͊ͅ");
Zalgo.character_sets["normal"].setCharacter(null,"t","t̲̂̓ͩ̑");
Zalgo.character_sets["normal"].setCharacter(null,"u","ư̡͕̭̇");
Zalgo.character_sets["normal"].setCharacter(null,"v","v͒̄ͭ̏̇");
Zalgo.character_sets["normal"].setCharacter(null,"w","w̦̺̐̐͟");
Zalgo.character_sets["normal"].setCharacter(null,"x","x̛̘̠̹͋");
Zalgo.character_sets["normal"].setCharacter(null,"y","y҉̃̀̋̑");
Zalgo.character_sets["normal"].setCharacter(null,"z","z̼͙̓́ͭ");
Zalgo.character_sets["normal"].setCharacter(null,"0","0̗̜͕̅̃");
Zalgo.character_sets["normal"].setCharacter(null,"1","1̨̹̦͍̀");
Zalgo.character_sets["normal"].setCharacter(null,"2","2̷́̃̉̕");
Zalgo.character_sets["normal"].setCharacter(null,"3","3̤̰̺̂̃");
Zalgo.character_sets["normal"].setCharacter(null,"4","4̶̣̠̖̳");
Zalgo.character_sets["normal"].setCharacter(null,"5","5̷̧̼́͌");
Zalgo.character_sets["normal"].setCharacter(null,"6","6͙̜̤ͩ̆");
Zalgo.character_sets["normal"].setCharacter(null,"7","7̸̹̲ͮ̒");
Zalgo.character_sets["normal"].setCharacter(null,"8","8̯̭̓̇͂");
Zalgo.character_sets["normal"].setCharacter(null,"9","9͉̳ͬ̃ͥ");

export default Zalgo;