import React from "react";
import FormatBar from "../FormatBar/FormatBar.js";
import SpecialInputTypeButtons from "../SpecialInputTypeButtons/SpecialInputTypeButtons.js";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { Delete } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TransformIcon from "@mui/icons-material/Transform";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import ReplayIcon from "@mui/icons-material/Replay";
import Fade from "@mui/material/Fade";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import HelpIcon from "@mui/icons-material/Help";
import "./BottomToolbar.scss";
import { withStyles } from "@mui/styles";

const StyledMenu = withStyles({
  paper: {
    "background-color": "#1e2735",
  },
})((props) => (
  <Menu
    elevation={3}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
    }}
    transformOrigin={{
      vertical: "top",
    }}
    {...props}
  />
));

class BottomToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMoreMenu: false,
      anchorEl: null,
      currentSelected: "none",
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleThreeDots = this.handleThreeDots.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.ref = React.createRef();
    this.moreMenuAnchor = React.createRef();
  }
  /***************************************************************************/
  componentDidMount() {
  //  this.props.setHeights(this.ref.current.offsetHeight, "bottomToolbarHeight");
  }
  /***************************************************************************/
  handleDelete() {
    this.props.manager.stringManager.deleteAll();
  }
  /***************************************************************************/
  handleThreeDots(event) {
    this.moreMenuAnchor.current.blur();
    this.setState((state, props) => {
      return { anchorEl: this.moreMenuAnchor.current, openMoreMenu: true };
    });
  }
  /***************************************************************************/
  handleClose(event) {
    event.stopPropagation();
    let value = event.currentTarget.dataset.value;

    if (value === "capitalize")
      this.props.manager.stringManager.capitalizeSubstring();
    else if (value === "reverse")
      this.props.manager.stringManager.reverseSubstring();
    else if (value === "revert to default")
      this.props.manager.stringManager.revertToDefault();
    else if (value === "mark as tag")
      this.props.manager.stringManager.revertToDefault();
    else if (value === "delete") this.handleDelete();
    else if (value === "help") this.props.manager.togglePanel("help");

    this.setState((state, props) => {
      return {
        anchorEl: null,
        openMoreMenu: false,
        currentSelected: value,
      };
    });
  }
  /***************************************************************************/
  render() {
    let options = [
      { value: "delete", label: "Delete", icon: <Delete /> },
      { value: "capitalize", label: "Capitalize", icon: <FormatSizeIcon /> },
      { value: "reverse", label: "Reverse", icon: <TransformIcon /> },
      {
        value: "revert to default",
        label: "Revert to Default",
        icon: <ReplayIcon />,
      },
      {
        value: "mark as tag",
        label: "Mark as Tag/Hashtag",
        icon: <LoyaltyIcon />,
      },
      { value: "help", label: "Help", icon: <HelpIcon /> },
      //  { value: "about", label: "About", icon: <InfoIcon /> },
    ];
    return (
      <Paper
        ref={this.ref}
        elevation={2}
        square
        className="bottom-toolbar toolbar"
      >
        <FormatBar manager={this.props.manager} />
        <SpecialInputTypeButtons manager={this.props.manager} />
        <IconButton ref={this.moreMenuAnchor} onClick={this.handleThreeDots}>
          <MoreVertIcon />
        </IconButton>
        <StyledMenu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          onClose={this.handleClose}
          open={this.state.openMoreMenu}
          TransitionComponent={Fade}
        >
          {options.map((option) => (
            <MenuItem
              sx={{
                backgroundColor: "#1e2735"
              }}
              key={option.value}
              className="more-menu-item"
              data-value={option.value}
              onClick={this.handleClose}
            >
              <span className="icon">{option.icon}</span>
              <span className="value">{option.label}</span>
            </MenuItem>
          ))}
        </StyledMenu>
      </Paper>
    );
  }
}

export default BottomToolbar;
