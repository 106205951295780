import Font from "../Font.js";

const Funky_Bunch = new Font(234,"Funky Bunch");

Funky_Bunch.initCharacterSet("normal",235);
Funky_Bunch.character_sets["normal"].setCharacter(null,"A","Ⱥ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"B","β");
Funky_Bunch.character_sets["normal"].setCharacter(null,"C","↻");
Funky_Bunch.character_sets["normal"].setCharacter(null,"D","Ꭰ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"E","Ɛ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"F","Ƒ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"G","Ɠ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"H","Ƕ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"I","į");
Funky_Bunch.character_sets["normal"].setCharacter(null,"J","ل");
Funky_Bunch.character_sets["normal"].setCharacter(null,"K","Ҡ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"L","Ꝉ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"M","Ɱ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"N","ហ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"O","ට");
Funky_Bunch.character_sets["normal"].setCharacter(null,"P","φ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"Q","Ҩ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"R","འ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"S","Ϛ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"T","Ͳ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"U","Ա");
Funky_Bunch.character_sets["normal"].setCharacter(null,"V","Ỽ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"W","చ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"X","ჯ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"Y","Ӌ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"Z","ɀ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"a","ą");
Funky_Bunch.character_sets["normal"].setCharacter(null,"b","ҍ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"c","ç");
Funky_Bunch.character_sets["normal"].setCharacter(null,"d","ժ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"e","ҽ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"f","ƒ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"g","ց");
Funky_Bunch.character_sets["normal"].setCharacter(null,"h","հ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"i","ì");
Funky_Bunch.character_sets["normal"].setCharacter(null,"j","ʝ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"k","ҟ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"l","Ӏ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"m","ʍ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"n","ղ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"o","օ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"p","ք");
Funky_Bunch.character_sets["normal"].setCharacter(null,"q","զ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"r","ɾ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"s","ʂ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"t","է");
Funky_Bunch.character_sets["normal"].setCharacter(null,"u","մ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"v","ѵ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"w","ա");
Funky_Bunch.character_sets["normal"].setCharacter(null,"x","×");
Funky_Bunch.character_sets["normal"].setCharacter(null,"y","վ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"z","Հ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"0","⊘");
Funky_Bunch.character_sets["normal"].setCharacter(null,"1","𝟙");
Funky_Bunch.character_sets["normal"].setCharacter(null,"2","ϩ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"3","Ӡ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"4","५");
Funky_Bunch.character_sets["normal"].setCharacter(null,"5","Ƽ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"6","Ϭ");
Funky_Bunch.character_sets["normal"].setCharacter(null,"7","7");
Funky_Bunch.character_sets["normal"].setCharacter(null,"8","𝟠");
Funky_Bunch.character_sets["normal"].setCharacter(null,"9","९");

export default Funky_Bunch;