class Selection {
  constructor(start, end) {
    this.direction = "static";
    if (start < end) this.direction = "forward";
    else if (start > end) this.direction = "backward";

    if (start < end) {
      this.start = start;
      this.end = end;
    } else {
      this.start = end;
      this.end = start;
    }
  }
  isCursor() {
    return this.start === this.end;
  }
  isSelection() {
    return this.start !== this.end;
  }
  inRange(index) {
    return index >= this.start && index < this.end;
  }
  length() {
    return this.end - this.start;
  }
  isForward() {
    return this.direction === "forward";
  }
  isBackward() {
    return this.direction === "backward";
  }
  hashify() {
    return { start: this.start, end: this.end };
  }
  clone() {
    return new Selection(this.start, this.end);
  }
  equals(otherSelection) {
    return (
      this.start === otherSelection.start && this.end === otherSelection.end
    );
  }
}

export default Selection;
