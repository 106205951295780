import Font from "../Font.js";

const Slicer = new Font(240,"Slicer");

Slicer.initCharacterSet("normal",241);
Slicer.character_sets["normal"].setCharacter(null,"A","Ⱥ");
Slicer.character_sets["normal"].setCharacter(null,"B","Ƀ");
Slicer.character_sets["normal"].setCharacter(null,"C","Ȼ");
Slicer.character_sets["normal"].setCharacter(null,"D","Đ");
Slicer.character_sets["normal"].setCharacter(null,"E","Ɇ");
Slicer.character_sets["normal"].setCharacter(null,"F","F");
Slicer.character_sets["normal"].setCharacter(null,"G","Ǥ");
Slicer.character_sets["normal"].setCharacter(null,"H","Ħ");
Slicer.character_sets["normal"].setCharacter(null,"I","Ɨ");
Slicer.character_sets["normal"].setCharacter(null,"J","Ɉ");
Slicer.character_sets["normal"].setCharacter(null,"K","Ꝁ");
Slicer.character_sets["normal"].setCharacter(null,"L","Ł");
Slicer.character_sets["normal"].setCharacter(null,"M","M");
Slicer.character_sets["normal"].setCharacter(null,"N","N");
Slicer.character_sets["normal"].setCharacter(null,"O","Ø");
Slicer.character_sets["normal"].setCharacter(null,"P","Ᵽ");
Slicer.character_sets["normal"].setCharacter(null,"Q","Ꝗ");
Slicer.character_sets["normal"].setCharacter(null,"R","Ɍ");
Slicer.character_sets["normal"].setCharacter(null,"S","S");
Slicer.character_sets["normal"].setCharacter(null,"T","Ŧ");
Slicer.character_sets["normal"].setCharacter(null,"U","ᵾ");
Slicer.character_sets["normal"].setCharacter(null,"V","V");
Slicer.character_sets["normal"].setCharacter(null,"W","W");
Slicer.character_sets["normal"].setCharacter(null,"X","X");
Slicer.character_sets["normal"].setCharacter(null,"Y","Ɏ");
Slicer.character_sets["normal"].setCharacter(null,"Z","Ƶ");
Slicer.character_sets["normal"].setCharacter(null,"a","Ⱥ");
Slicer.character_sets["normal"].setCharacter(null,"b","ƀ");
Slicer.character_sets["normal"].setCharacter(null,"c","ȼ");
Slicer.character_sets["normal"].setCharacter(null,"d","đ");
Slicer.character_sets["normal"].setCharacter(null,"e","ɇ");
Slicer.character_sets["normal"].setCharacter(null,"f","f");
Slicer.character_sets["normal"].setCharacter(null,"g","ǥ");
Slicer.character_sets["normal"].setCharacter(null,"h","ħ");
Slicer.character_sets["normal"].setCharacter(null,"i","ɨ");
Slicer.character_sets["normal"].setCharacter(null,"j","ɉ");
Slicer.character_sets["normal"].setCharacter(null,"k","ꝁ");
Slicer.character_sets["normal"].setCharacter(null,"l","ł");
Slicer.character_sets["normal"].setCharacter(null,"m","m");
Slicer.character_sets["normal"].setCharacter(null,"n","n");
Slicer.character_sets["normal"].setCharacter(null,"o","ø");
Slicer.character_sets["normal"].setCharacter(null,"p","ᵽ");
Slicer.character_sets["normal"].setCharacter(null,"q","ꝗ");
Slicer.character_sets["normal"].setCharacter(null,"r","ɍ");
Slicer.character_sets["normal"].setCharacter(null,"s","s");
Slicer.character_sets["normal"].setCharacter(null,"t","ŧ");
Slicer.character_sets["normal"].setCharacter(null,"u","ᵾ");
Slicer.character_sets["normal"].setCharacter(null,"v","v");
Slicer.character_sets["normal"].setCharacter(null,"w","w");
Slicer.character_sets["normal"].setCharacter(null,"x","x");
Slicer.character_sets["normal"].setCharacter(null,"y","ɏ");
Slicer.character_sets["normal"].setCharacter(null,"z","ƶ");
Slicer.character_sets["normal"].setCharacter(null,"0","0");
Slicer.character_sets["normal"].setCharacter(null,"1","1");
Slicer.character_sets["normal"].setCharacter(null,"2","ƻ");
Slicer.character_sets["normal"].setCharacter(null,"3","3");
Slicer.character_sets["normal"].setCharacter(null,"4","4");
Slicer.character_sets["normal"].setCharacter(null,"5","5");
Slicer.character_sets["normal"].setCharacter(null,"6","6");
Slicer.character_sets["normal"].setCharacter(null,"7","7");
Slicer.character_sets["normal"].setCharacter(null,"8","8");
Slicer.character_sets["normal"].setCharacter(null,"9","9");

export default Slicer;