import Command from "./Command.js";
import Selection from "../../../Selection.js";
/***********************************************************************/
class Captialize extends Command {
  constructor(selection, attributes) {
    super("capitalize", null, attributes, selection);
  }
  /***********************************************************************/
  exec(string) {
    this.input_state = string.hashify();
    const substring = string.getSubstring(this.cursor);
    const capitialized = substring.substring.toUpperCase();

    // create the subcommands and save them
    let removeSelection = this.manager.commandFactory.make("remove selection", [
      this.cursor,
    ]);
    let insert = this.manager.commandFactory.make("insert", [
      capitialized,
      substring.attributes,
      this.cursor,
    ]);
    this.subCommands.push(removeSelection);
    this.subCommands.push(insert);
    this.execSubCommands(string);
    const newCursor = this.manager.getCurrentSelection();
    const newSelection = new Selection(this.cursor.start, newCursor.end);
    this.manager.setSelection(newSelection);
    // get new substring
    const newSubstring = string.getSubstring(newSelection);
    for (let i = 0; i < newSubstring.importantIndices.length; i++) {
      let old_index = substring.importantIndices[i];
      let new_index = newSubstring.importantIndices[i];

      newSubstring.substringModel[new_index].addTransformation({
        capitalize: substring.substringModel[old_index],
      });
    }

    this.output_state = string.hashify();
  }
  /***********************************************************************/
  execSubCommands(string) {
    for (let i = 0; i < this.subCommands.length; i++)
      this.subCommands[i].exec(string);
  }
}

export default Captialize;
