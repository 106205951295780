import React from "react";
import DualInputOutputbox from "./DualInputOutputbox.js";
//import EmojiPicker from "../EmojiPicker/EmojiPicker.js";
//import GlyphPicker from "../GlyphPicker/GlyphPicker.js";
import TopToolbar from "../TopToolbar/TopToolbar.js";
import FontSelector from "../FontSelector/FontSelector.js";
import BottomToolbar from "../BottomToolbar/BottomToolbar.js";
import ShareModal from "../ShareModal/ShareModal.js";
import HelpModal from "../HelpModal/HelpModal.js";
import Settings from "../Settings/Settings.js";
import SnippetModal from "../SnippetModal/SnippetModal.js";
//import Grid from "@material-ui/core/Grid";
import Grid from '@mui/material/Grid';
import "./DualInputOutputbox.scss";

class DualInputOutputFormatter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      topToolbarHeight: null,
      bottomToolbarHeight: null,
    };
    this.setHeights = this.setHeights.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  /***************************************************************************/
  updateDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  /***************************************************************************/
  componentDidMount() {
    if (this.props.captionId !== undefined) {
      this.props.manager.saveId = this.props.captionId;
      fetch(
        "https://apps.lesfleursdelanuit.com/aformatter/api/api/caption/" +
          this.props.captionId +
          "/version/" +
          this.props.version
      )
        .then((res) => res.json())
        .then((res) => {
          this.props.manager.reinstateFromHash(res.value);
        });
    }
    window.addEventListener("resize", this.updateDimensions);
  }
  /***************************************************************************/
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  /***************************************************************************/
  setHeights(height, prop) {
    this.setState((state, props) => {
      let ret = {};
      ret[prop] = height;
      return ret;
    });
  }
  /***************************************************************************/
  calcInputHeight() {
    const margin = 40;
    let height =
      this.state.height -
      (this.state.topToolbarHeight +
        this.state.bottomToolbarHeight +
        2 * margin);

    return height;
  }
  /***************************************************************************/
  render() {
    return (
      <React.Fragment>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          className="formatter"
        >
          <Grid item xs={12}>
            <TopToolbar
              manager={this.props.manager}
            />
          </Grid>
          <Grid item xs={12}>
            <DualInputOutputbox
              manager={this.props.manager}
            />
          </Grid>
          <div className="bottom-group">
            <BottomToolbar
              manager={this.props.manager}
            />
          </div>
        </Grid>
        <ShareModal manager={this.props.manager} />
        <FontSelector manager={this.props.manager} />
        <SnippetModal manager={this.props.manager} />
        <HelpModal manager={this.props.manager} />
        <Settings manager={this.props.manager} />
      </React.Fragment>
    );
  }
}

export default DualInputOutputFormatter;
