import React from "react";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ShareIcon from "@mui/icons-material/Share";
import Clipboard from "simple-react-clipboard";
import swal from "sweetalert";
import "./ShareButtons.scss";

class ShareButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.manager.stringManager.getCurrentState().string,
    };
    this.unicode_string_manager_id = null;
    this.handleCopy = this.handleCopy.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShare = this.handleShare.bind(this);
  }
  /***************************************************************************/
  componentDidMount() {
    this.unicode_string_manager_id =
      this.props.manager.stringManager.registerListener((e, changed) => {
        let changedSet = new Set(changed);
        if (changedSet.has("state")) {
          this.setState((state, props) => {
            return {
              text: this.props.manager.stringManager.getCurrentState().string,
            };
          });
        }
      });
  }
  /***************************************************************************/
  componentWillUnmount() {
    this.props.manager.stringManager.unregisterListener(
      this.unicode_string_manager_id
    );

    this.props.manager.unregisterListener(this.app_manager_id);
  }
  /***************************************************************************/
  async handleCopy() {
    let caption = this.state.text;
    let convertedCaption = caption.replace(/\n/g, " \n");
  }
  /***************************************************************************/
  async handleSave(useAlert = true) {
    const docHash = this.props.manager.stringManager.model.hashify();
    const manager = this.props.manager;
    fetch("https://apps.lesfleursdelanuit.com/aformatter/api/api/caption/new", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(docHash),
    }).then(async (result) => {
      const responseBody = await result.json();
      manager.setSaveId(responseBody.saveId);
      if (useAlert) {
        swal("Saved!", "Caption has been saved!", "success", {
          button: "Yes!",
        });
      }
    });
  }
  /***************************************************************************/
  async handleShare() {
    await this.handleSave(false);
    this.props.manager.togglePanel("share");
  }

  /***************************************************************************/
  render() {
    return (
      <React.Fragment>
        <Clipboard
          text={this.state.text.replace(/\n/g, " \n")}
          onSuccess={() => {
            swal("GREAT!", "Caption copied to clipboard!", "success", {
              button: "Yes!",
            });
          }}
          render={({ copy }) => (
            <IconButton size="small" onClick={copy}>
              <FileCopyIcon />
            </IconButton>
          )}
        />
        <IconButton size="small" onClick={this.handleShare}>
          <ShareIcon />
        </IconButton>
      </React.Fragment>
    );
  }
}

export default ShareButtons;
