import Command from "./Command.js";
import Selection from "../../../Selection.js";

class UpdateSelectionStyle extends Command {
  constructor(selection, attributes, changeType) {
    super("update selection style", null, attributes, selection);
    this.changeType = changeType;
  }
  exec(string) {
    this.input_state = string.hashify();
    const substring = string.getSubstring(this.cursor);

    this.startCommand();
    console.log("Update selection style called");

    let attributes = this.attributes;
    if (this.changeType === "bold" || this.changeType === "italic") {
      for (let i = 0; i < substring.attributes.length; i++) {
        let currentAttribute = substring.attributes[i];
        if (this.changeType === "bold")
          currentAttribute.style.bold = this.attributes.style.bold;
        if (this.changeType === "italic")
          currentAttribute.style.italic = this.attributes.style.italic;
      }
      attributes = substring.attributes;
    }

    // create the subcommands and save them
    let removeSelection = this.manager.commandFactory.make("remove selection", [
      this.cursor,
    ]);
    let insert = this.manager.commandFactory.make("insert", [
      substring.substring,
      attributes,
      this.cursor,
      substring.transformations,
    ]);

    // console.log("current selection");
    // console.log(this.cursor);
    this.subCommands.push(removeSelection);
    this.subCommands.push(insert);
    this.execSubCommands(string);

    const newCursor = this.manager.getCurrentSelection();
    // console.log("new cursor");
    // console.log(newCursor);
    this.manager.setSelection(new Selection(this.cursor.start, newCursor.end));
    this.output_state = string.hashify();

    return this;
  }
  execSubCommands(string) {
    for (let i = 0; i < this.subCommands.length; i++)
      this.subCommands[i].exec(string);
  }
}

export default UpdateSelectionStyle;
