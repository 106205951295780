//import { createGenerateClassName } from "@material-ui/styles";
import State from "./State.js";
class HashtagState extends State {
  constructor(dispatcher) {
    super("hashtag", dispatcher);
  }
  spawn(command, ch, t) {
    let outputState = null;
    let insertCommands = new Set(["insert single"]);
    let removeCommands = new Set(["remove single", "remove selection"]);

    if (insertCommands.has(command) && ch.search(this.validHashtag) !== -1) {
      outputState = new this.dispatcher.types["hashtag"](this.dispatcher);
      this.data.insert(t.start, ch);
    } else if (
      removeCommands.has(command) &&
      ch.search(this.validHashtag) !== -1
    ) {
      outputState = new this.dispatcher.types["hashtag"](this.dispatcher);
      this.data.remove(t.start);
    } else if (
      removeCommands.has(command) &&
      ch === "#" &&
      t === this.data.startIndex
    ) {
      this.data = null;
      outputState = new this.dispatcher.types["modification"](this.dispatcher);
    } else {
      outputState = new this.dispatcher.types["hashtag end"](this.dispatcher);
    }

    this.setOutputState(outputState);
    return outputState;
  }
}

export default HashtagState;
