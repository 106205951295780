import StartState from "./StartState.js";
import ModificationState from "./ModificationState.js";
import TagState from "./TagState.js";
import HashtagState from "./HashtagState.js";
import PossibleTagState from "./PossibleTagState.js";
import PossibleHashtagState from "./PossibleHashtagState.js";
import HashTagEndState from "./HashtagEndState.js";
import TagEndState from "./TagEndState.js";

class StateManager {
  constructor(stringManager) {
    this.stringManager = stringManager;

    this.types = {
      start: StartState,
      modification: ModificationState,
      tag: TagState,
      hashtag: HashtagState,
      "possible tag": PossibleTagState,
      "possible hashtag": PossibleHashtagState,
      "hashtag end": HashTagEndState,
      "tag end": TagEndState,
    };

    this.states = [];
    this.current = -1;
  }
  /***********************************************************************/
  init() {
    let start = new StartState(this);
    start.setInputState(null);
    this.states.push(start);
    this.current++;
  }
  /***********************************************************************/
  calcNextState(command, input, cursor) {
    let lastState = this.states[this.states.length - 1];
    let outputState = lastState.spawn(command, input, cursor);
    this.states.push(outputState);
    this.current++;

    return outputState;
  }
}

export default StateManager;
