import Font from "../Font.js";

const ElderRunes= new Font(218,"ElderRunes");
ElderRunes.initCharacterSet("normal",219);

ElderRunes.character_sets["normal"].setCharacter(null,"a","ᚨ");
ElderRunes.character_sets["normal"].setCharacter(null,"b","ᛒ");
ElderRunes.character_sets["normal"].setCharacter(null,"c","ᚲ");
ElderRunes.character_sets["normal"].setCharacter(null,"d","ᛞ");
ElderRunes.character_sets["normal"].setCharacter(null,"e","ᛖ");
ElderRunes.character_sets["normal"].setCharacter(null,"f","ᚠ");
ElderRunes.character_sets["normal"].setCharacter(null,"g","ᚷ");
ElderRunes.character_sets["normal"].setCharacter(null,"h","ᚺ");
ElderRunes.character_sets["normal"].setCharacter(null,"i","ᛁ");
ElderRunes.character_sets["normal"].setCharacter(null,"j","ᛃ");
ElderRunes.character_sets["normal"].setCharacter(null,"k","ᚲ");
ElderRunes.character_sets["normal"].setCharacter(null,"l","ᛚ");
ElderRunes.character_sets["normal"].setCharacter(null,"m","ᛗ");
ElderRunes.character_sets["normal"].setCharacter(null,"n","ᚾ");
ElderRunes.character_sets["normal"].setCharacter(null,"o","ᛟ");
ElderRunes.character_sets["normal"].setCharacter(null,"p","ᛈ");
ElderRunes.character_sets["normal"].setCharacter(null,"q","ᚲ");
ElderRunes.character_sets["normal"].setCharacter(null,"r","ᚱ");
ElderRunes.character_sets["normal"].setCharacter(null,"s","ᛊ");
ElderRunes.character_sets["normal"].setCharacter(null,"t","ᛏ");
ElderRunes.character_sets["normal"].setCharacter(null,"u","ᚢ");
ElderRunes.character_sets["normal"].setCharacter(null,"v","ᚹ");
ElderRunes.character_sets["normal"].setCharacter(null,"w","ᚹ");
ElderRunes.character_sets["normal"].setCharacter(null,"x","ᚲᛊ");
ElderRunes.character_sets["normal"].setCharacter(null,"y","ᛃ");
ElderRunes.character_sets["normal"].setCharacter(null,"z","ᛉ");
ElderRunes.character_sets["normal"].setCharacter(null,"ᚦ","ᚦ");
ElderRunes.character_sets["normal"].setCharacter(null,"ᛜ","ᛜ");
ElderRunes.character_sets["normal"].setCharacter(null," ","᛫");


export default ElderRunes;