import Font from "../Font.js";

const SansSerif = new Font(13, "SansSerif");

SansSerif.initCharacterSet("bold",14);
SansSerif.character_sets["bold"].setCharacter("U+1D5D4","A","𝗔");
SansSerif.character_sets["bold"].setCharacter("U+1D5D5","B","𝗕");
SansSerif.character_sets["bold"].setCharacter("U+1D5D6","C","𝗖");
SansSerif.character_sets["bold"].setCharacter("U+1D5D7","D","𝗗");
SansSerif.character_sets["bold"].setCharacter("U+1D5D8","E","𝗘");
SansSerif.character_sets["bold"].setCharacter("U+1D5D9","F","𝗙");
SansSerif.character_sets["bold"].setCharacter("U+1D5DA","G","𝗚");
SansSerif.character_sets["bold"].setCharacter("U+1D5DB","H","𝗛");
SansSerif.character_sets["bold"].setCharacter("U+1D5DC","I","𝗜");
SansSerif.character_sets["bold"].setCharacter("U+1D5DD","J","𝗝");
SansSerif.character_sets["bold"].setCharacter("U+1D5DE","K","𝗞");
SansSerif.character_sets["bold"].setCharacter("U+1D5DF","L","𝗟");
SansSerif.character_sets["bold"].setCharacter("U+1D5E0","M","𝗠");
SansSerif.character_sets["bold"].setCharacter("U+1D5E1","N","𝗡");
SansSerif.character_sets["bold"].setCharacter("U+1D5E2","O","𝗢");
SansSerif.character_sets["bold"].setCharacter("U+1D5E3","P","𝗣");
SansSerif.character_sets["bold"].setCharacter("U+1D5E4","Q","𝗤");
SansSerif.character_sets["bold"].setCharacter("U+1D5E5","R","𝗥");
SansSerif.character_sets["bold"].setCharacter("U+1D5E6","S","𝗦");
SansSerif.character_sets["bold"].setCharacter("U+1D5E7","T","𝗧");
SansSerif.character_sets["bold"].setCharacter("U+1D5E8","U","𝗨");
SansSerif.character_sets["bold"].setCharacter("U+1D5E9","V","𝗩");
SansSerif.character_sets["bold"].setCharacter("U+1D5EA","W","𝗪");
SansSerif.character_sets["bold"].setCharacter("U+1D5EB","X","𝗫");
SansSerif.character_sets["bold"].setCharacter("U+1D5EC","Y","𝗬");
SansSerif.character_sets["bold"].setCharacter("U+1D5ED","Z","𝗭");
SansSerif.character_sets["bold"].setCharacter("U+1D5EE","a","𝗮");
SansSerif.character_sets["bold"].setCharacter("U+1D5EF","b","𝗯");
SansSerif.character_sets["bold"].setCharacter("U+1D5F0","c","𝗰");
SansSerif.character_sets["bold"].setCharacter("U+1D5F1","d","𝗱");
SansSerif.character_sets["bold"].setCharacter("U+1D5F2","e","𝗲");
SansSerif.character_sets["bold"].setCharacter("U+1D5F3","f","𝗳");
SansSerif.character_sets["bold"].setCharacter("U+1D5F4","g","𝗴");
SansSerif.character_sets["bold"].setCharacter("U+1D5F5","h","𝗵");
SansSerif.character_sets["bold"].setCharacter("U+1D5F6","i","𝗶");
SansSerif.character_sets["bold"].setCharacter("U+1D5F7","j","𝗷");
SansSerif.character_sets["bold"].setCharacter("U+1D5F8","k","𝗸");
SansSerif.character_sets["bold"].setCharacter("U+1D5F9","l","𝗹");
SansSerif.character_sets["bold"].setCharacter("U+1D5FA","m","𝗺");
SansSerif.character_sets["bold"].setCharacter("U+1D5FB","n","𝗻");
SansSerif.character_sets["bold"].setCharacter("U+1D5FC","o","𝗼");
SansSerif.character_sets["bold"].setCharacter("U+1D5FD","p","𝗽");
SansSerif.character_sets["bold"].setCharacter("U+1D5FE","q","𝗾");
SansSerif.character_sets["bold"].setCharacter("U+1D5FF","r","𝗿");
SansSerif.character_sets["bold"].setCharacter("U+1D600","s","𝘀");
SansSerif.character_sets["bold"].setCharacter("U+1D601","t","𝘁");
SansSerif.character_sets["bold"].setCharacter("U+1D602","u","𝘂");
SansSerif.character_sets["bold"].setCharacter("U+1D603","v","𝘃");
SansSerif.character_sets["bold"].setCharacter("U+1D604","w","𝘄");
SansSerif.character_sets["bold"].setCharacter("U+1D605","x","𝘅");
SansSerif.character_sets["bold"].setCharacter("U+1D606","y","𝘆");
SansSerif.character_sets["bold"].setCharacter("U+1D607","z","𝘇");
SansSerif.character_sets["bold"].setCharacter("U+1D7EC","0","𝟬");
SansSerif.character_sets["bold"].setCharacter("U+1D7ED","1","𝟭");
SansSerif.character_sets["bold"].setCharacter("U+1D7EE","2","𝟮");
SansSerif.character_sets["bold"].setCharacter("U+1D7EF","3","𝟯");
SansSerif.character_sets["bold"].setCharacter("U+1D7F0","4","𝟰");
SansSerif.character_sets["bold"].setCharacter("U+1D7F1","5","𝟱");
SansSerif.character_sets["bold"].setCharacter("U+1D7F2","6","𝟲");
SansSerif.character_sets["bold"].setCharacter("U+1D7F3","7","𝟳");
SansSerif.character_sets["bold"].setCharacter("U+1D7F4","8","𝟴");
SansSerif.character_sets["bold"].setCharacter("U+1D7F5","9","𝟵");

SansSerif.initCharacterSet("bold italic",15);
SansSerif.character_sets["bold italic"].setCharacter("U+1D63C","A","𝘼");
SansSerif.character_sets["bold italic"].setCharacter("U+1D63D","B","𝘽");
SansSerif.character_sets["bold italic"].setCharacter("U+1D63E","C","𝘾");
SansSerif.character_sets["bold italic"].setCharacter("U+1D63F","D","𝘿");
SansSerif.character_sets["bold italic"].setCharacter("U+1D640","E","𝙀");
SansSerif.character_sets["bold italic"].setCharacter("U+1D641","F","𝙁");
SansSerif.character_sets["bold italic"].setCharacter("U+1D642","G","𝙂");
SansSerif.character_sets["bold italic"].setCharacter("U+1D643","H","𝙃");
SansSerif.character_sets["bold italic"].setCharacter("U+1D644","I","𝙄");
SansSerif.character_sets["bold italic"].setCharacter("U+1D645","J","𝙅");
SansSerif.character_sets["bold italic"].setCharacter("U+1D646","K","𝙆");
SansSerif.character_sets["bold italic"].setCharacter("U+1D647","L","𝙇");
SansSerif.character_sets["bold italic"].setCharacter("U+1D648","M","𝙈");
SansSerif.character_sets["bold italic"].setCharacter("U+1D649","N","𝙉");
SansSerif.character_sets["bold italic"].setCharacter("U+1D64A","O","𝙊");
SansSerif.character_sets["bold italic"].setCharacter("U+1D64B","P","𝙋");
SansSerif.character_sets["bold italic"].setCharacter("U+1D64C","Q","𝙌");
SansSerif.character_sets["bold italic"].setCharacter("U+1D64D","R","𝙍");
SansSerif.character_sets["bold italic"].setCharacter("U+1D64E","S","𝙎");
SansSerif.character_sets["bold italic"].setCharacter("U+1D64F","T","𝙏");
SansSerif.character_sets["bold italic"].setCharacter("U+1D650","U","𝙐");
SansSerif.character_sets["bold italic"].setCharacter("U+1D651","V","𝙑");
SansSerif.character_sets["bold italic"].setCharacter("U+1D652","W","𝙒");
SansSerif.character_sets["bold italic"].setCharacter("U+1D653","X","𝙓");
SansSerif.character_sets["bold italic"].setCharacter("U+1D654","Y","𝙔");
SansSerif.character_sets["bold italic"].setCharacter("U+1D655","Z","𝙕");
SansSerif.character_sets["bold italic"].setCharacter("U+1D656","a","𝙖");
SansSerif.character_sets["bold italic"].setCharacter("U+1D657","b","𝙗");
SansSerif.character_sets["bold italic"].setCharacter("U+1D658","c","𝙘");
SansSerif.character_sets["bold italic"].setCharacter("U+1D659","d","𝙙");
SansSerif.character_sets["bold italic"].setCharacter("U+1D65A","e","𝙚");
SansSerif.character_sets["bold italic"].setCharacter("U+1D65B","f","𝙛");
SansSerif.character_sets["bold italic"].setCharacter("U+1D65C","g","𝙜");
SansSerif.character_sets["bold italic"].setCharacter("U+1D65D","h","𝙝");
SansSerif.character_sets["bold italic"].setCharacter("U+1D65E","i","𝙞");
SansSerif.character_sets["bold italic"].setCharacter("U+1D65F","j","𝙟");
SansSerif.character_sets["bold italic"].setCharacter("U+1D660","k","𝙠");
SansSerif.character_sets["bold italic"].setCharacter("U+1D661","l","𝙡");
SansSerif.character_sets["bold italic"].setCharacter("U+1D662","m","𝙢");
SansSerif.character_sets["bold italic"].setCharacter("U+1D663","n","𝙣");
SansSerif.character_sets["bold italic"].setCharacter("U+1D664","o","𝙤");
SansSerif.character_sets["bold italic"].setCharacter("U+1D665","p","𝙥");
SansSerif.character_sets["bold italic"].setCharacter("U+1D666","q","𝙦");
SansSerif.character_sets["bold italic"].setCharacter("U+1D667","r","𝙧");
SansSerif.character_sets["bold italic"].setCharacter("U+1D668","s","𝙨");
SansSerif.character_sets["bold italic"].setCharacter("U+1D669","t","𝙩");
SansSerif.character_sets["bold italic"].setCharacter("U+1D66A","u","𝙪");
SansSerif.character_sets["bold italic"].setCharacter("U+1D66B","v","𝙫");
SansSerif.character_sets["bold italic"].setCharacter("U+1D66C","w","𝙬");
SansSerif.character_sets["bold italic"].setCharacter("U+1D66D","x","𝙭");
SansSerif.character_sets["bold italic"].setCharacter("U+1D66E","y","𝙮");
SansSerif.character_sets["bold italic"].setCharacter("U+1D66F","z","𝙯");

SansSerif.initCharacterSet("italic",16);
SansSerif.character_sets["italic"].setCharacter("U+1D608","A","𝘈");
SansSerif.character_sets["italic"].setCharacter("U+1D609","B","𝘉");
SansSerif.character_sets["italic"].setCharacter("U+1D60A","C","𝘊");
SansSerif.character_sets["italic"].setCharacter("U+1D60B","D","𝘋");
SansSerif.character_sets["italic"].setCharacter("U+1D60C","E","𝘌");
SansSerif.character_sets["italic"].setCharacter("U+1D60D","F","𝘍");
SansSerif.character_sets["italic"].setCharacter("U+1D60E","G","𝘎");
SansSerif.character_sets["italic"].setCharacter("U+1D60F","H","𝘏");
SansSerif.character_sets["italic"].setCharacter("U+1D610","I","𝘐");
SansSerif.character_sets["italic"].setCharacter("U+1D611","J","𝘑");
SansSerif.character_sets["italic"].setCharacter("U+1D612","K","𝘒");
SansSerif.character_sets["italic"].setCharacter("U+1D613","L","𝘓");
SansSerif.character_sets["italic"].setCharacter("U+1D614","M","𝘔");
SansSerif.character_sets["italic"].setCharacter("U+1D615","N","𝘕");
SansSerif.character_sets["italic"].setCharacter("U+1D616","O","𝘖");
SansSerif.character_sets["italic"].setCharacter("U+1D617","P","𝘗");
SansSerif.character_sets["italic"].setCharacter("U+1D618","Q","𝘘");
SansSerif.character_sets["italic"].setCharacter("U+1D619","R","𝘙");
SansSerif.character_sets["italic"].setCharacter("U+1D61A","S","𝘚");
SansSerif.character_sets["italic"].setCharacter("U+1D61B","T","𝘛");
SansSerif.character_sets["italic"].setCharacter("U+1D61C","U","𝘜");
SansSerif.character_sets["italic"].setCharacter("U+1D61D","V","𝘝");
SansSerif.character_sets["italic"].setCharacter("U+1D61E","W","𝘞");
SansSerif.character_sets["italic"].setCharacter("U+1D61F","X","𝘟");
SansSerif.character_sets["italic"].setCharacter("U+1D620","Y","𝘠");
SansSerif.character_sets["italic"].setCharacter("U+1D621","Z","𝘡");
SansSerif.character_sets["italic"].setCharacter("U+1D622","a","𝘢");
SansSerif.character_sets["italic"].setCharacter("U+1D623","b","𝘣");
SansSerif.character_sets["italic"].setCharacter("U+1D624","c","𝘤");
SansSerif.character_sets["italic"].setCharacter("U+1D625","d","𝘥");
SansSerif.character_sets["italic"].setCharacter("U+1D626","e","𝘦");
SansSerif.character_sets["italic"].setCharacter("U+1D627","f","𝘧");
SansSerif.character_sets["italic"].setCharacter("U+1D628","g","𝘨");
SansSerif.character_sets["italic"].setCharacter("U+1D629","h","𝘩");
SansSerif.character_sets["italic"].setCharacter("U+1D62A","i","𝘪");
SansSerif.character_sets["italic"].setCharacter("U+1D62B","j","𝘫");
SansSerif.character_sets["italic"].setCharacter("U+1D62C","k","𝘬");
SansSerif.character_sets["italic"].setCharacter("U+1D62D","l","𝘭");
SansSerif.character_sets["italic"].setCharacter("U+1D62E","m","𝘮");
SansSerif.character_sets["italic"].setCharacter("U+1D62F","n","𝘯");
SansSerif.character_sets["italic"].setCharacter("U+1D630","o","𝘰");
SansSerif.character_sets["italic"].setCharacter("U+1D631","p","𝘱");
SansSerif.character_sets["italic"].setCharacter("U+1D632","q","𝘲");
SansSerif.character_sets["italic"].setCharacter("U+1D633","r","𝘳");
SansSerif.character_sets["italic"].setCharacter("U+1D634","s","𝘴");
SansSerif.character_sets["italic"].setCharacter("U+1D635","t","𝘵");
SansSerif.character_sets["italic"].setCharacter("U+1D636","u","𝘶");
SansSerif.character_sets["italic"].setCharacter("U+1D637","v","𝘷");
SansSerif.character_sets["italic"].setCharacter("U+1D638","w","𝘸");
SansSerif.character_sets["italic"].setCharacter("U+1D639","x","𝘹");
SansSerif.character_sets["italic"].setCharacter("U+1D63A","y","𝘺");
SansSerif.character_sets["italic"].setCharacter("U+1D63B","z","𝘻");

SansSerif.initCharacterSet("normal",17);
SansSerif.character_sets["normal"].setCharacter("U+1D5A0","A","𝖠");
SansSerif.character_sets["normal"].setCharacter("U+1D5A1","B","𝖡");
SansSerif.character_sets["normal"].setCharacter("U+1D5A2","C","𝖢");
SansSerif.character_sets["normal"].setCharacter("U+1D5A3","D","𝖣");
SansSerif.character_sets["normal"].setCharacter("U+1D5A4","E","𝖤");
SansSerif.character_sets["normal"].setCharacter("U+1D5A5","F","𝖥");
SansSerif.character_sets["normal"].setCharacter("U+1D5A6","G","𝖦");
SansSerif.character_sets["normal"].setCharacter("U+1D5A7","H","𝖧");
SansSerif.character_sets["normal"].setCharacter("U+1D5A8","I","𝖨");
SansSerif.character_sets["normal"].setCharacter("U+1D5A9","J","𝖩");
SansSerif.character_sets["normal"].setCharacter("U+1D5AA","K","𝖪");
SansSerif.character_sets["normal"].setCharacter("U+1D5AB","L","𝖫");
SansSerif.character_sets["normal"].setCharacter("U+1D5AC","M","𝖬");
SansSerif.character_sets["normal"].setCharacter("U+1D5AD","N","𝖭");
SansSerif.character_sets["normal"].setCharacter("U+1D5AE","O","𝖮");
SansSerif.character_sets["normal"].setCharacter("U+1D5AF","P","𝖯");
SansSerif.character_sets["normal"].setCharacter("U+1D5B0","Q","𝖰");
SansSerif.character_sets["normal"].setCharacter("U+1D5B1","R","𝖱");
SansSerif.character_sets["normal"].setCharacter("U+1D5B2","S","𝖲");
SansSerif.character_sets["normal"].setCharacter("U+1D5B3","T","𝖳");
SansSerif.character_sets["normal"].setCharacter("U+1D5B4","U","𝖴");
SansSerif.character_sets["normal"].setCharacter("U+1D5B5","V","𝖵");
SansSerif.character_sets["normal"].setCharacter("U+1D5B6","W","𝖶");
SansSerif.character_sets["normal"].setCharacter("U+1D5B7","X","𝖷");
SansSerif.character_sets["normal"].setCharacter("U+1D5B8","Y","𝖸");
SansSerif.character_sets["normal"].setCharacter("U+1D5B9","Z","𝖹");
SansSerif.character_sets["normal"].setCharacter("U+1D5BA","a","𝖺");
SansSerif.character_sets["normal"].setCharacter("U+1D5BB","b","𝖻");
SansSerif.character_sets["normal"].setCharacter("U+1D5BC","c","𝖼");
SansSerif.character_sets["normal"].setCharacter("U+1D5BD","d","𝖽");
SansSerif.character_sets["normal"].setCharacter("U+1D5BE","e","𝖾");
SansSerif.character_sets["normal"].setCharacter("U+1D5BF","f","𝖿");
SansSerif.character_sets["normal"].setCharacter("U+1D5C0","g","𝗀");
SansSerif.character_sets["normal"].setCharacter("U+1D5C1","h","𝗁");
SansSerif.character_sets["normal"].setCharacter("U+1D5C2","i","𝗂");
SansSerif.character_sets["normal"].setCharacter("U+1D5C3","j","𝗃");
SansSerif.character_sets["normal"].setCharacter("U+1D5C4","k","𝗄");
SansSerif.character_sets["normal"].setCharacter("U+1D5C5","l","𝗅");
SansSerif.character_sets["normal"].setCharacter("U+1D5C6","m","𝗆");
SansSerif.character_sets["normal"].setCharacter("U+1D5C7","n","𝗇");
SansSerif.character_sets["normal"].setCharacter("U+1D5C8","o","𝗈");
SansSerif.character_sets["normal"].setCharacter("U+1D5C9","p","𝗉");
SansSerif.character_sets["normal"].setCharacter("U+1D5CA","q","𝗊");
SansSerif.character_sets["normal"].setCharacter("U+1D5CB","r","𝗋");
SansSerif.character_sets["normal"].setCharacter("U+1D5CC","s","𝗌");
SansSerif.character_sets["normal"].setCharacter("U+1D5CD","t","𝗍");
SansSerif.character_sets["normal"].setCharacter("U+1D5CE","u","𝗎");
SansSerif.character_sets["normal"].setCharacter("U+1D5CF","v","𝗏");
SansSerif.character_sets["normal"].setCharacter("U+1D5D0","w","𝗐");
SansSerif.character_sets["normal"].setCharacter("U+1D5D1","x","𝗑");
SansSerif.character_sets["normal"].setCharacter("U+1D5D2","y","𝗒");
SansSerif.character_sets["normal"].setCharacter("U+1D5D3","z","𝗓");
SansSerif.character_sets["normal"].setCharacter("U+1D7E2","0","𝟢");
SansSerif.character_sets["normal"].setCharacter("U+1D7E3","1","𝟣");
SansSerif.character_sets["normal"].setCharacter("U+1D7E4","2","𝟤");
SansSerif.character_sets["normal"].setCharacter("U+1D7E5","3","𝟥");
SansSerif.character_sets["normal"].setCharacter("U+1D7E6","4","𝟦");
SansSerif.character_sets["normal"].setCharacter("U+1D7E7","5","𝟧");
SansSerif.character_sets["normal"].setCharacter("U+1D7E8","6","𝟨");
SansSerif.character_sets["normal"].setCharacter("U+1D7E9","7","𝟩");
SansSerif.character_sets["normal"].setCharacter("U+1D7EA","8","𝟪");
SansSerif.character_sets["normal"].setCharacter("U+1D7EB","9","𝟫");

export default SansSerif;