import State from "./State.js";
class HashtagEndState extends State {
  constructor(dispatcher) {
    super("hashtag end", dispatcher);
  }
  spawn(command, ch) {
    let outputState = null;
    let hashtag = (" " + this.data.string).slice(1);
    this.data = null;

    if (ch === "#") {
      outputState = new this.dispatcher.types["possible hashtag"](
        this.dispatcher
      );
    } else if (ch === "@") {
      outputState = new this.dispatcher.types["possible tag"](this.dispatcher);
    } else {
      outputState = new this.dispatcher.types["modification"](this.dispatcher);
    }

    this.setOutputState(outputState);
    return outputState;
  }
}

export default HashtagEndState;
