import Command from "./Command.js";

class Paste extends Command {
  constructor(data, attributes, cursor) {
    super("paste", data, attributes, cursor);
  }

  exec(string) {
    this.input_state = string.hashify();
    let insert = this.manager.commandFactory.make("insert", [
      this.data,
      this.attributes,
      this.cursor,
    ]);
    this.subCommands.push(insert);
    this.execSubCommands(string);
    this.output_state = string.hashify();
  }

  execSubCommands(string) {
    for (let i = 0; i < this.subCommands.length; i++)
      this.subCommands[i].exec(string);
  }
}

export default Paste;
