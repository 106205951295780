import Font from "../Font.js";

const Undermine = new Font(260,"Undermine");

Undermine.initCharacterSet("normal",261);
Undermine.character_sets["normal"].setCharacter(null,"A","A̲");
Undermine.character_sets["normal"].setCharacter(null,"B","B̲");
Undermine.character_sets["normal"].setCharacter(null,"C","C̲");
Undermine.character_sets["normal"].setCharacter(null,"D","D̲");
Undermine.character_sets["normal"].setCharacter(null,"E","E̲");
Undermine.character_sets["normal"].setCharacter(null,"F","F̲");
Undermine.character_sets["normal"].setCharacter(null,"G","G̲");
Undermine.character_sets["normal"].setCharacter(null,"H","H̲");
Undermine.character_sets["normal"].setCharacter(null,"I","I̲");
Undermine.character_sets["normal"].setCharacter(null,"J","J̲");
Undermine.character_sets["normal"].setCharacter(null,"K","K̲");
Undermine.character_sets["normal"].setCharacter(null,"L","L̲");
Undermine.character_sets["normal"].setCharacter(null,"M","M̲");
Undermine.character_sets["normal"].setCharacter(null,"N","N̲");
Undermine.character_sets["normal"].setCharacter(null,"O","O̲");
Undermine.character_sets["normal"].setCharacter(null,"P","P̲");
Undermine.character_sets["normal"].setCharacter(null,"Q","Q̲");
Undermine.character_sets["normal"].setCharacter(null,"R","R̲");
Undermine.character_sets["normal"].setCharacter(null,"S","S̲");
Undermine.character_sets["normal"].setCharacter(null,"T","T̲");
Undermine.character_sets["normal"].setCharacter(null,"U","U̲");
Undermine.character_sets["normal"].setCharacter(null,"V","V̲");
Undermine.character_sets["normal"].setCharacter(null,"W","W̲");
Undermine.character_sets["normal"].setCharacter(null,"X","X̲");
Undermine.character_sets["normal"].setCharacter(null,"Y","Y̲");
Undermine.character_sets["normal"].setCharacter(null,"Z","Z̲");
Undermine.character_sets["normal"].setCharacter(null,"a","a̲");
Undermine.character_sets["normal"].setCharacter(null,"b","b̲");
Undermine.character_sets["normal"].setCharacter(null,"c","c̲");
Undermine.character_sets["normal"].setCharacter(null,"d","d̲");
Undermine.character_sets["normal"].setCharacter(null,"e","e̲");
Undermine.character_sets["normal"].setCharacter(null,"f","f̲");
Undermine.character_sets["normal"].setCharacter(null,"g","g̲");
Undermine.character_sets["normal"].setCharacter(null,"h","h̲");
Undermine.character_sets["normal"].setCharacter(null,"i","i̲");
Undermine.character_sets["normal"].setCharacter(null,"j","j̲");
Undermine.character_sets["normal"].setCharacter(null,"k","k̲");
Undermine.character_sets["normal"].setCharacter(null,"l","l̲");
Undermine.character_sets["normal"].setCharacter(null,"m","m̲");
Undermine.character_sets["normal"].setCharacter(null,"n","n̲");
Undermine.character_sets["normal"].setCharacter(null,"o","o̲");
Undermine.character_sets["normal"].setCharacter(null,"p","p̲");
Undermine.character_sets["normal"].setCharacter(null,"q","q̲");
Undermine.character_sets["normal"].setCharacter(null,"r","r̲");
Undermine.character_sets["normal"].setCharacter(null,"s","s̲");
Undermine.character_sets["normal"].setCharacter(null,"t","t̲");
Undermine.character_sets["normal"].setCharacter(null,"u","u̲");
Undermine.character_sets["normal"].setCharacter(null,"v","v̲");
Undermine.character_sets["normal"].setCharacter(null,"w","w̲");
Undermine.character_sets["normal"].setCharacter(null,"x","x̲");
Undermine.character_sets["normal"].setCharacter(null,"y","y̲");
Undermine.character_sets["normal"].setCharacter(null,"z","z̲");
Undermine.character_sets["normal"].setCharacter(null,"0","0̲");
Undermine.character_sets["normal"].setCharacter(null,"1","1̲");
Undermine.character_sets["normal"].setCharacter(null,"2","2̲");
Undermine.character_sets["normal"].setCharacter(null,"3","3̲");
Undermine.character_sets["normal"].setCharacter(null,"4","4̲");
Undermine.character_sets["normal"].setCharacter(null,"5","5̲");
Undermine.character_sets["normal"].setCharacter(null,"6","6̲");
Undermine.character_sets["normal"].setCharacter(null,"7","7̲");
Undermine.character_sets["normal"].setCharacter(null,"8","8̲");
Undermine.character_sets["normal"].setCharacter(null,"9","9̲");

export default Undermine;