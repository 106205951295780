import Font from "../Font.js";

const Impression = new Font(256,"Impression");

Impression.initCharacterSet("normal",257);
Impression.character_sets["normal"].setCharacter(null,"A","A");
Impression.character_sets["normal"].setCharacter(null,"B","B");
Impression.character_sets["normal"].setCharacter(null,"C","C");
Impression.character_sets["normal"].setCharacter(null,"D","D");
Impression.character_sets["normal"].setCharacter(null,"E","E");
Impression.character_sets["normal"].setCharacter(null,"F","F");
Impression.character_sets["normal"].setCharacter(null,"G","G");
Impression.character_sets["normal"].setCharacter(null,"H","H");
Impression.character_sets["normal"].setCharacter(null,"I","I");
Impression.character_sets["normal"].setCharacter(null,"J","J");
Impression.character_sets["normal"].setCharacter(null,"K","K");
Impression.character_sets["normal"].setCharacter(null,"L","L");
Impression.character_sets["normal"].setCharacter(null,"M","M");
Impression.character_sets["normal"].setCharacter(null,"N","N");
Impression.character_sets["normal"].setCharacter(null,"O","O");
Impression.character_sets["normal"].setCharacter(null,"P","P");
Impression.character_sets["normal"].setCharacter(null,"Q","Q");
Impression.character_sets["normal"].setCharacter(null,"R","R");
Impression.character_sets["normal"].setCharacter(null,"S","S");
Impression.character_sets["normal"].setCharacter(null,"T","T");
Impression.character_sets["normal"].setCharacter(null,"U","U");
Impression.character_sets["normal"].setCharacter(null,"V","V");
Impression.character_sets["normal"].setCharacter(null,"W","W");
Impression.character_sets["normal"].setCharacter(null,"X","X");
Impression.character_sets["normal"].setCharacter(null,"Y","Y");
Impression.character_sets["normal"].setCharacter(null,"Z","Z");
Impression.character_sets["normal"].setCharacter(null,"a","ã");
Impression.character_sets["normal"].setCharacter(null,"b","b");
Impression.character_sets["normal"].setCharacter(null,"c","ĉ");
Impression.character_sets["normal"].setCharacter(null,"d","d");
Impression.character_sets["normal"].setCharacter(null,"e","ê");
Impression.character_sets["normal"].setCharacter(null,"f","f");
Impression.character_sets["normal"].setCharacter(null,"g","ĝ");
Impression.character_sets["normal"].setCharacter(null,"h","ĥ");
Impression.character_sets["normal"].setCharacter(null,"i","ĩ");
Impression.character_sets["normal"].setCharacter(null,"j","ĵ");
Impression.character_sets["normal"].setCharacter(null,"k","k");
Impression.character_sets["normal"].setCharacter(null,"l","l");
Impression.character_sets["normal"].setCharacter(null,"m","m");
Impression.character_sets["normal"].setCharacter(null,"n","ñ");
Impression.character_sets["normal"].setCharacter(null,"o","ǿ");
Impression.character_sets["normal"].setCharacter(null,"p","p");
Impression.character_sets["normal"].setCharacter(null,"q","q");
Impression.character_sets["normal"].setCharacter(null,"r","ѓ");
Impression.character_sets["normal"].setCharacter(null,"s","ş");
Impression.character_sets["normal"].setCharacter(null,"t","ţ");
Impression.character_sets["normal"].setCharacter(null,"u","ʉ");
Impression.character_sets["normal"].setCharacter(null,"v","v");
Impression.character_sets["normal"].setCharacter(null,"w","ŵ");
Impression.character_sets["normal"].setCharacter(null,"x","x");
Impression.character_sets["normal"].setCharacter(null,"y","ŷ");
Impression.character_sets["normal"].setCharacter(null,"z","ż");
Impression.character_sets["normal"].setCharacter(null,"0","0");
Impression.character_sets["normal"].setCharacter(null,"1","1");
Impression.character_sets["normal"].setCharacter(null,"2","2");
Impression.character_sets["normal"].setCharacter(null,"3","3");
Impression.character_sets["normal"].setCharacter(null,"4","4");
Impression.character_sets["normal"].setCharacter(null,"5","5");
Impression.character_sets["normal"].setCharacter(null,"6","6");
Impression.character_sets["normal"].setCharacter(null,"7","7");
Impression.character_sets["normal"].setCharacter(null,"8","8");
Impression.character_sets["normal"].setCharacter(null,"9","9");

export default Impression;