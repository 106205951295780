import Font from "../Font.js";

const YoungerRunes= new Font(218,"YoungerRunes");
YoungerRunes.initCharacterSet("normal",219);

YoungerRunes.character_sets["normal"].setCharacter(null,"a","ᛅ");
YoungerRunes.character_sets["normal"].setCharacter(null,"b","ᛒ");
YoungerRunes.character_sets["normal"].setCharacter(null,"c","ᚴ");
YoungerRunes.character_sets["normal"].setCharacter(null,"d","ᛏ");
YoungerRunes.character_sets["normal"].setCharacter(null,"e","ᛁ");
YoungerRunes.character_sets["normal"].setCharacter(null,"f","ᚠ");
YoungerRunes.character_sets["normal"].setCharacter(null,"g","ᚴ");
YoungerRunes.character_sets["normal"].setCharacter(null,"h","ᚼ");
YoungerRunes.character_sets["normal"].setCharacter(null,"i","ᛁ");
YoungerRunes.character_sets["normal"].setCharacter(null,"j","ᛁ");
YoungerRunes.character_sets["normal"].setCharacter(null,"k","ᚴ");
YoungerRunes.character_sets["normal"].setCharacter(null,"l","ᛚ");
YoungerRunes.character_sets["normal"].setCharacter(null,"m","ᛘ");
YoungerRunes.character_sets["normal"].setCharacter(null,"n","ᚾ");
YoungerRunes.character_sets["normal"].setCharacter(null,"o","ᚢ");
YoungerRunes.character_sets["normal"].setCharacter(null,"p","ᛒ");
YoungerRunes.character_sets["normal"].setCharacter(null,"q","ᚴ");
YoungerRunes.character_sets["normal"].setCharacter(null,"r","ᚱ");
YoungerRunes.character_sets["normal"].setCharacter(null,"s","ᛋ");
YoungerRunes.character_sets["normal"].setCharacter(null,"t","ᛏ");
YoungerRunes.character_sets["normal"].setCharacter(null,"u","ᚢ");
YoungerRunes.character_sets["normal"].setCharacter(null,"v","ᚢ");
YoungerRunes.character_sets["normal"].setCharacter(null,"w","ᚢ");
YoungerRunes.character_sets["normal"].setCharacter(null,"x","ᚴᛋ");
YoungerRunes.character_sets["normal"].setCharacter(null,"y","ᛁ");
YoungerRunes.character_sets["normal"].setCharacter(null,"z","ᛋ");
YoungerRunes.character_sets["normal"].setCharacter(null,"ᚦ","ᚦ");
YoungerRunes.character_sets["normal"].setCharacter(null,"ᚴ","ᚴ");
YoungerRunes.character_sets["normal"].setCharacter(null," ","᛫");


export default YoungerRunes;