import Circular from "./Circular.js";
import All_Caps from "./All_Caps.js";
import Strikethrough from "./Strikethrough.js";
import Underline from "./Underline.js";
import Black_Pill from "./Black_Pill.js";
import Zalgo from "./Zalgo.js";
import Price_is_Wrong from "./Price_is_Wrong.js";
import Domino from "./Domino.js";
import Slipknot from "./Slipknot.js";
import Typewriter from "./Typewriter.js";
import Souvlaki from "./Souvlaki.js";
import Wasai from "./Wasai.js";
import Notebook from "./Notebook.js";
import Upside_Down from "./Upside_Down.js";
import Lite from "./Lite.js";
import Rando from "./Rando.js";
import XOut from "./XOut.js";
import Funky_Bunch from "./Funky_Bunch.js";
import Bracket from "./Bracket.js";
import Freaky_Friday from "./Freaky_Friday.js";
import Slicer from "./Slicer.js";
import Pound_Cake from "./Pound_Cake.js";
import Gawd_Mode from "./Gawd_Mode.js";
import Wiggle_Arms from "./Wiggle_Arms.js";
import Its_All_Greek from "./Its_All_Greek.js";
import Wonky_Doodle from "./Wonky_Doodle.js";
import Quebec from "./Quebec.js";
import Spicy_ABC from "./Spicy_ABC.js";
import Impression from "./Impression.js";
import Top_Hat from "./Top_Hat.js";
import Undermine from "./Undermine.js";
import Dot_Matrix from "./Dot_Matrix.js";
import Slash from "./Slash.js";
import X_Axis from "./X_Axis.js";
import Notes from "./Notes.js";
import Hat from "./Hat.js";
import Function from "./Function.js";
import WiFi from "./WiFi.js";
import Short_Caps from "./Short_Caps.js";
import Fancy_Text from "./Fancy_Text.js";
import All_Caps_2 from "./All_Caps_2.js";
import Default from "./Default.js";
import ElderRunes from "./ElderRunes.js";
import AngloRunes from "./AngloRunes.js";
import YoungerRunes from "./YoungerRunes.js";

let extraFonts = {
  Circular: Circular,
  //Default: Default,
  "All Caps": All_Caps,
  "All Caps 2": All_Caps_2,
  Strikethrough: Strikethrough,
  Underline: Underline,
  "Black Pill": Black_Pill,
  Zalgo: Zalgo,
  "Price is Wrong": Price_is_Wrong,
  Domino: Domino,
  Slipknot: Slipknot,
  Typewriter: Typewriter,
  Souvlaki: Souvlaki,
  Wasai: Wasai,
  Notebook: Notebook,
  "Upside Down": Upside_Down,
  Lite: Lite,
  Rando: Rando,
  "X-Out": XOut,
  "Funky Bunch": Funky_Bunch,
  //Bracket: Bracket,
  "Freaky Friday": Freaky_Friday,
  Slicer: Slicer,
  "Pound Cake": Pound_Cake,
  "Gawd Mode": Gawd_Mode,
  "Wiggle Arms": Wiggle_Arms,
  "Its All Greek": Its_All_Greek,
  "Wonky Doodle": Wonky_Doodle,
  Quebec: Quebec,
  "Spicy ABC": Spicy_ABC,
  Impression: Impression,
  "Top Hat": Top_Hat,
  Undermine: Undermine,
  "Dot Matrix": Dot_Matrix,
  Slash: Slash,
  "X Axis": X_Axis,
  Notes: Notes,
  Hat: Hat,
  Function: Function,
  WiFi: WiFi,
  "Short Caps": Short_Caps,
  "Fancy Text": Fancy_Text,
  ElderRunes: ElderRunes,
  AngloRunes: AngloRunes,
  YoungerRunes: YoungerRunes,
};

export {
  Circular,
  All_Caps,
  Strikethrough,
  Underline,
  Black_Pill,
  Zalgo,
  Price_is_Wrong,
  Domino,
  Slipknot,
  Typewriter,
  Souvlaki,
  Wasai,
  Notebook,
  Upside_Down,
  Lite,
  Rando,
  XOut,
  Funky_Bunch,
  Bracket,
  Freaky_Friday,
  Slicer,
  Pound_Cake,
  Gawd_Mode,
  Wiggle_Arms,
  Its_All_Greek,
  Wonky_Doodle,
  Quebec,
  Spicy_ABC,
  Impression,
  Top_Hat,
  Undermine,
  Dot_Matrix,
  Slash,
  X_Axis,
  Notes,
  Hat,
  Function,
  WiFi,
  Short_Caps,
  Fancy_Text,
  ElderRunes,
  AngloRunes,
  YoungerRunes,
  extraFonts,
};
