import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import "./ShareModal.scss";

class ShareModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.manager.getPanel("share"),
      saveId: this.props.manager.saveId,
    };
    this.app_manager_id = null;
    this.handleClose = this.handleClose.bind(this);
  }
  /***************************************************************************/
  componentDidMount() {
    this.app_manager_id = this.props.manager.registerListener((changed) => {
      let changedSet = new Set(changed);
      if (changedSet.has("panelsOpen")) {
        this.setState((state, props) => {
          return {
            open: this.props.manager.getPanel("share"),
          };
        });
      } else if (changedSet.has("saveId")) {
        this.setState((state, props) => {
          return {
            saveId: this.props.manager.getSaveId(),
          };
        });
      }
    });
  }
  /***************************************************************************/
  componentWillUnmount() {
    this.props.manager.unregisterListener(this.app_manager_id);
  }
  /***************************************************************************/
  handleClose() {
    this.props.manager.togglePanel("share");
  }

  /***************************************************************************/
  render() {
    const shareUrl =
      this.props.manager.baseUrl +
      "caption/" +
      this.state.saveId +
      "/version/0";
    return (
      //<CopyToClipboard text={this.state.text.replace(/\n/g, " \n")}>
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
      >
        <MuiDialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          Share
        </MuiDialogTitle>
        <MuiDialogContent dividers>
          <Typography gutterBottom>
            Copy link below to share your formatted text:
          </Typography>
          <div className="share-link">
            {shareUrl}
            <CopyToClipboard text={shareUrl}>
              <Button className="copy-button" color="primary">
                Copy
              </Button>
            </CopyToClipboard>
          </div>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button autoFocus onClick={this.handleClose} color="primary">
            Close
          </Button>
        </MuiDialogActions>
      </Dialog>
    );
  }
}

export default ShareModal;
