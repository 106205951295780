import Font from "../Font.js";

const Fancy_Text = new Font(278,"Fancy Text");

Fancy_Text.initCharacterSet("normal",279);
Fancy_Text.character_sets["normal"].setCharacter(null,"A","A");
Fancy_Text.character_sets["normal"].setCharacter(null,"B","B");
Fancy_Text.character_sets["normal"].setCharacter(null,"C","C");
Fancy_Text.character_sets["normal"].setCharacter(null,"D","D");
Fancy_Text.character_sets["normal"].setCharacter(null,"E","E");
Fancy_Text.character_sets["normal"].setCharacter(null,"F","F");
Fancy_Text.character_sets["normal"].setCharacter(null,"G","G");
Fancy_Text.character_sets["normal"].setCharacter(null,"H","H");
Fancy_Text.character_sets["normal"].setCharacter(null,"I","I");
Fancy_Text.character_sets["normal"].setCharacter(null,"J","J");
Fancy_Text.character_sets["normal"].setCharacter(null,"K","K");
Fancy_Text.character_sets["normal"].setCharacter(null,"L","L");
Fancy_Text.character_sets["normal"].setCharacter(null,"M","M");
Fancy_Text.character_sets["normal"].setCharacter(null,"N","N");
Fancy_Text.character_sets["normal"].setCharacter(null,"O","O");
Fancy_Text.character_sets["normal"].setCharacter(null,"P","P");
Fancy_Text.character_sets["normal"].setCharacter(null,"Q","Q");
Fancy_Text.character_sets["normal"].setCharacter(null,"R","R");
Fancy_Text.character_sets["normal"].setCharacter(null,"S","S");
Fancy_Text.character_sets["normal"].setCharacter(null,"T","T");
Fancy_Text.character_sets["normal"].setCharacter(null,"U","U");
Fancy_Text.character_sets["normal"].setCharacter(null,"V","V");
Fancy_Text.character_sets["normal"].setCharacter(null,"W","W");
Fancy_Text.character_sets["normal"].setCharacter(null,"X","X");
Fancy_Text.character_sets["normal"].setCharacter(null,"Y","Y");
Fancy_Text.character_sets["normal"].setCharacter(null,"Z","Z");
Fancy_Text.character_sets["normal"].setCharacter(null,"a","α");
Fancy_Text.character_sets["normal"].setCharacter(null,"b","в");
Fancy_Text.character_sets["normal"].setCharacter(null,"c","¢");
Fancy_Text.character_sets["normal"].setCharacter(null,"d","∂");
Fancy_Text.character_sets["normal"].setCharacter(null,"e","є");
Fancy_Text.character_sets["normal"].setCharacter(null,"f","ƒ");
Fancy_Text.character_sets["normal"].setCharacter(null,"g","g");
Fancy_Text.character_sets["normal"].setCharacter(null,"h","н");
Fancy_Text.character_sets["normal"].setCharacter(null,"i","ι");
Fancy_Text.character_sets["normal"].setCharacter(null,"j","נ");
Fancy_Text.character_sets["normal"].setCharacter(null,"k","к");
Fancy_Text.character_sets["normal"].setCharacter(null,"l","ℓ");
Fancy_Text.character_sets["normal"].setCharacter(null,"m","м");
Fancy_Text.character_sets["normal"].setCharacter(null,"n","η");
Fancy_Text.character_sets["normal"].setCharacter(null,"o","σ");
Fancy_Text.character_sets["normal"].setCharacter(null,"p","ρ");
Fancy_Text.character_sets["normal"].setCharacter(null,"q","q");
Fancy_Text.character_sets["normal"].setCharacter(null,"r","я");
Fancy_Text.character_sets["normal"].setCharacter(null,"s","ѕ");
Fancy_Text.character_sets["normal"].setCharacter(null,"t","т");
Fancy_Text.character_sets["normal"].setCharacter(null,"u","υ");
Fancy_Text.character_sets["normal"].setCharacter(null,"v","ν");
Fancy_Text.character_sets["normal"].setCharacter(null,"w","ω");
Fancy_Text.character_sets["normal"].setCharacter(null,"x","χ");
Fancy_Text.character_sets["normal"].setCharacter(null,"y","у");
Fancy_Text.character_sets["normal"].setCharacter(null,"z","z");
Fancy_Text.character_sets["normal"].setCharacter(null,"0","0");
Fancy_Text.character_sets["normal"].setCharacter(null,"1","1");
Fancy_Text.character_sets["normal"].setCharacter(null,"2","2");
Fancy_Text.character_sets["normal"].setCharacter(null,"3","3");
Fancy_Text.character_sets["normal"].setCharacter(null,"4","4");
Fancy_Text.character_sets["normal"].setCharacter(null,"5","5");
Fancy_Text.character_sets["normal"].setCharacter(null,"6","6");
Fancy_Text.character_sets["normal"].setCharacter(null,"7","7");
Fancy_Text.character_sets["normal"].setCharacter(null,"8","8");
Fancy_Text.character_sets["normal"].setCharacter(null,"9","9");

export default Fancy_Text;