import Font from "../Font.js";

const Top_Hat = new Font(258,"Top Hat");

Top_Hat.initCharacterSet("normal",259);
Top_Hat.character_sets["normal"].setCharacter(null,"A","𝑨̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"B","𝑩̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"C","𝑪̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"D","𝑫̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"E","𝑬̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"F","𝑭̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"G","𝑮̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"H","𝑯̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"I","𝑰̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"J","𝑱̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"K","𝑲̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"L","𝑳̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"M","𝑴̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"N","𝑵̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"O","𝑶̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"P","𝑷̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"Q","𝑸̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"R","𝑹̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"S","𝑺̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"T","𝑻̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"U","𝑼̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"V","𝑽̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"W","𝑾̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"X","𝑿̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"Y","𝒀̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"Z","𝒁̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"a","𝒂̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"b","𝒃̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"c","𝒄̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"d","𝒅̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"e","𝒆̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"f","𝒇̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"g","𝒈̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"h","𝒉̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"i","𝒊̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"j","𝒋̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"k","𝒌̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"l","𝒍̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"m","𝒎̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"n","𝒏̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"o","𝒐̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"p","𝒑̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"q","𝒒̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"r","𝒓̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"s","𝒔̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"t","𝒕̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"u","𝒖̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"v","𝒗̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"w","𝒘̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"x","𝒙̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"y","𝒚̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"z","𝒛̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"0","0̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"1","1̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"2","2̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"3","3̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"4","4̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"5","5̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"6","6̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"7","7̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"8","8̲̅");
Top_Hat.character_sets["normal"].setCharacter(null,"9","9̲̅");

export default Top_Hat;