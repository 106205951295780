import Command from "./Command.js";
import Selection from "../../../Selection.js";

class RemoveSelection extends Command {
  constructor(selection) {
    super("remove selection", null, null, selection);
  }
  exec(string) {
    this.startCommand();
    this.input_state = string.hashify();

    const chLen = this.cursor.end - this.cursor.start;
    const substring = string.getSubstring(this.cursor);
    string.removeSymbol(this.cursor.start, chLen);
    this.manager.setSelection(
      new Selection(this.cursor.end - chLen, this.cursor.end - chLen)
    );

    for (let i = 0; i < substring.substring.length; i++) {
      let char = substring.substring[i];
      this.manager.stateManager.calcNextState("remove single", char);
    }
    this.output_state = string.hashify();
  }
}

export default RemoveSelection;
