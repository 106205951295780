import UnicodeChar from "./UnicodeChar.js";

const emptyChar = new UnicodeChar("", "Fraktur", "normal", 0);
const EMPTY_CHAR_INDEX = "EmptyChar";
class UnicodeCharFactory {
  constructor() {
    this.bank = {};
    this.bank[EMPTY_CHAR_INDEX] = emptyChar;
  }
  hashify() {
    const keys = Object.keys(this.bank);
    const hash = { bank: {} };
    for (let i = 0; i < keys.length; i++) {
      hash.bank[keys[i]] = this.bank[keys[i]].hashify();
    }

    return hash;
  }
  createKey(ch, font, style) {
    return [ch, font, style].join(",");
  }
  hasChar(ch, font, style) {
    const key = this.createKey(ch, font, style);
    return this.bank.hasOwnProperty(key);
  }
  make(ch, font, style) {
    const key = this.createKey(ch, font, style);

    if (!this.hasChar(ch, font, style)) {
      const symbol = new UnicodeChar(ch, font, style, key);
      this.bank[key] = symbol;
    }

    return this.bank[key];
  }
  getChar(key) {
    return this.bank[key];
  }

  getEmptyChar() {
    return this.bank[EMPTY_CHAR_INDEX];
  }
}

export default UnicodeCharFactory;
