import Font from "../Font.js";

const X_Axis = new Font(266,"X Axis");

X_Axis.initCharacterSet("normal",267);
X_Axis.character_sets["normal"].setCharacter(null,"A","A͓");
X_Axis.character_sets["normal"].setCharacter(null,"B","B͓");
X_Axis.character_sets["normal"].setCharacter(null,"C","C͓");
X_Axis.character_sets["normal"].setCharacter(null,"D","D͓");
X_Axis.character_sets["normal"].setCharacter(null,"E","E͓");
X_Axis.character_sets["normal"].setCharacter(null,"F","F͓");
X_Axis.character_sets["normal"].setCharacter(null,"G","G͓");
X_Axis.character_sets["normal"].setCharacter(null,"H","H͓");
X_Axis.character_sets["normal"].setCharacter(null,"I","I͓");
X_Axis.character_sets["normal"].setCharacter(null,"J","J͓");
X_Axis.character_sets["normal"].setCharacter(null,"K","K͓");
X_Axis.character_sets["normal"].setCharacter(null,"L","L͓");
X_Axis.character_sets["normal"].setCharacter(null,"M","M͓");
X_Axis.character_sets["normal"].setCharacter(null,"N","N͓");
X_Axis.character_sets["normal"].setCharacter(null,"O","O͓");
X_Axis.character_sets["normal"].setCharacter(null,"P","P͓");
X_Axis.character_sets["normal"].setCharacter(null,"Q","Q͓");
X_Axis.character_sets["normal"].setCharacter(null,"R","R͓");
X_Axis.character_sets["normal"].setCharacter(null,"S","S͓");
X_Axis.character_sets["normal"].setCharacter(null,"T","T͓");
X_Axis.character_sets["normal"].setCharacter(null,"U","U͓");
X_Axis.character_sets["normal"].setCharacter(null,"V","V͓");
X_Axis.character_sets["normal"].setCharacter(null,"W","W͓");
X_Axis.character_sets["normal"].setCharacter(null,"X","X͓");
X_Axis.character_sets["normal"].setCharacter(null,"Y","Y͓");
X_Axis.character_sets["normal"].setCharacter(null,"Z","Z͓");
X_Axis.character_sets["normal"].setCharacter(null,"a","a͓");
X_Axis.character_sets["normal"].setCharacter(null,"b","b͓");
X_Axis.character_sets["normal"].setCharacter(null,"c","c͓");
X_Axis.character_sets["normal"].setCharacter(null,"d","d͓");
X_Axis.character_sets["normal"].setCharacter(null,"e","e͓");
X_Axis.character_sets["normal"].setCharacter(null,"f","f͓");
X_Axis.character_sets["normal"].setCharacter(null,"g","g͓");
X_Axis.character_sets["normal"].setCharacter(null,"h","h͓");
X_Axis.character_sets["normal"].setCharacter(null,"i","i͓");
X_Axis.character_sets["normal"].setCharacter(null,"j","j͓");
X_Axis.character_sets["normal"].setCharacter(null,"k","k͓");
X_Axis.character_sets["normal"].setCharacter(null,"l","l͓");
X_Axis.character_sets["normal"].setCharacter(null,"m","m͓");
X_Axis.character_sets["normal"].setCharacter(null,"n","n͓");
X_Axis.character_sets["normal"].setCharacter(null,"o","o͓");
X_Axis.character_sets["normal"].setCharacter(null,"p","p͓");
X_Axis.character_sets["normal"].setCharacter(null,"q","q͓");
X_Axis.character_sets["normal"].setCharacter(null,"r","r͓");
X_Axis.character_sets["normal"].setCharacter(null,"s","s͓");
X_Axis.character_sets["normal"].setCharacter(null,"t","t͓");
X_Axis.character_sets["normal"].setCharacter(null,"u","u͓");
X_Axis.character_sets["normal"].setCharacter(null,"v","v͓");
X_Axis.character_sets["normal"].setCharacter(null,"w","w͓");
X_Axis.character_sets["normal"].setCharacter(null,"x","x͓");
X_Axis.character_sets["normal"].setCharacter(null,"y","y͓");
X_Axis.character_sets["normal"].setCharacter(null,"z","z͓");
X_Axis.character_sets["normal"].setCharacter(null,"0","0͓");
X_Axis.character_sets["normal"].setCharacter(null,"1","1͓");
X_Axis.character_sets["normal"].setCharacter(null,"2","2͓");
X_Axis.character_sets["normal"].setCharacter(null,"3","3͓");
X_Axis.character_sets["normal"].setCharacter(null,"4","4͓");
X_Axis.character_sets["normal"].setCharacter(null,"5","5͓");
X_Axis.character_sets["normal"].setCharacter(null,"6","6͓");
X_Axis.character_sets["normal"].setCharacter(null,"7","7͓");
X_Axis.character_sets["normal"].setCharacter(null,"8","8͓");
X_Axis.character_sets["normal"].setCharacter(null,"9","9͓");

export default X_Axis;