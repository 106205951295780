import React from "react";
import "./FontSelector.scss";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
//import CharacterStyle from "../../UnicodeString/CharacterStyle";
import CharacterStyle from "../../models/UnicodeString/CharacterStyle.js";

class FontSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      font: this.props.manager.stringManager.getCurrentFont(),
      fonts: Object.keys(
        this.props.manager.stringManager.getFontManager().fonts
      ),
      open: this.props.manager.panelsOpen["font selector"],
      selection: null,
    };
    this.makeFonts = this.makeFonts.bind(this);
    this.handleSelectFont = this.handleSelectFont.bind(this);
    this.handleSearchFont = this.handleSearchFont.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.unicode_string_manager_id = null;
    this.app_manager_id = null;
  }
  /***************************************************************************/
  componentDidMount() {
    this.unicode_string_manager_id =
      this.props.manager.stringManager.registerListener((e, changed) => {
        let changedSet = new Set(changed);
        if (changedSet.has("font")) {
          this.setState((state, props) => {
            return { font: this.props.manager.stringManager.getCurrentFont() };
          });
        }

        if (changedSet.has("selection")) {
          this.setState((state, props) => {
            return {
              selection: this.props.manager.stringManager.getCurrentSelection(),
            };
          });
        }
      });

    this.app_manager_id = this.props.manager.registerListener((changed) => {
      let changedSet = new Set(changed);

      if (changedSet.has("panelsOpen")) {
        this.setState((state, props) => {
          return {
            open: this.props.manager.panelsOpen["font selector"],
          };
        });
      }
    });
  }
  /***************************************************************************/
  componentWillUnmount() {
    this.props.manager.stringManager.unregisterListener(
      this.unicode_string_manager_id
    );
    this.props.manager.unregisterListener(this.app_manager_id);
  }
  /***************************************************************************/
  handleSelectFont(e) {
    const currentStyle = this.props.manager.stringManager.getCurrentStyle();
    const value = e.target.value;
    if (
      value === "Serif" &&
      currentStyle.bold === false &&
      currentStyle.italic === false
    )
      this.props.manager.stringManager.setStyle(
        new CharacterStyle(false, true)
      );
    this.props.manager.stringManager.setFont(value);
  }
  /***************************************************************************/
  handleSearchFont(e) {
    const allFonts = Object.keys(
      this.props.manager.stringManager.getFontManager().fonts
    );
    let newFontValues = allFonts;
    if (e.target.value !== "") {
      let regex = new RegExp("^" + e.target.value, "i");
      newFontValues = allFonts.filter((value) => {
        return value.match(regex) !== null;
      });
    }

    this.setState((state, props) => {
      return { fonts: newFontValues };
    });
  }
  /***************************************************************************/
  makeFonts() {
    const fontManager = this.props.manager.stringManager.getFontManager();
    let substring = "";
    if (this.state.selection !== null) {
      substring = this.props.manager.stringManager.model.makeSubstring(
        this.state.selection.start,
        this.state.selection.end
      );
    }

    let fonts = this.state.fonts.sort().map((name) => {
      return (
        <MenuItem className="font-selector" key={name} value={name}>
          <span className="option-value">{name}</span>
          <span className="option-preview">
            {fontManager.write(
              name,
              "normal",
              substring === "" ? name : substring
            )}
          </span>
        </MenuItem>
      );
    });

    return fonts;
  }
  /***************************************************************************/
  handleCheckBoxChange() {
    this.props.manager.setChangeText();
  }
  /***************************************************************************/
  render() {
    return (
      <Drawer
        anchor="bottom"
        open={this.state.open}
        onClose={() => {
          this.props.manager.togglePanel("font selector");
        }}
      >
        <div className="font-selector-wrapper">
          <p>Font Selector</p>
          <FormControl variant="outlined" className="font-selector-wrapper">
            <Select
              onChange={this.handleSelectFont}
              value={this.state.font}
              inputProps={{
                name: "font",
                id: "outlined-age-native-simple",
              }}
              className="font-selector"
            >
              {this.makeFonts()}
            </Select>
          </FormControl>
        </div>
      </Drawer>
    );
  }
}

export default FontSelector;
