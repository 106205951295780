import Font from "../Font.js";

const Underline = new Font(206,"Underline");

Underline.initCharacterSet("normal",207);
Underline.character_sets["normal"].setCharacter(null,"A","A͟");
Underline.character_sets["normal"].setCharacter(null,"B","B͟");
Underline.character_sets["normal"].setCharacter(null,"C","C͟");
Underline.character_sets["normal"].setCharacter(null,"D","D͟");
Underline.character_sets["normal"].setCharacter(null,"E","E͟");
Underline.character_sets["normal"].setCharacter(null,"F","F͟");
Underline.character_sets["normal"].setCharacter(null,"G","G͟");
Underline.character_sets["normal"].setCharacter(null,"H","H͟");
Underline.character_sets["normal"].setCharacter(null,"I","I͟");
Underline.character_sets["normal"].setCharacter(null,"J","J͟");
Underline.character_sets["normal"].setCharacter(null,"K","K͟");
Underline.character_sets["normal"].setCharacter(null,"L","L͟");
Underline.character_sets["normal"].setCharacter(null,"M","M͟");
Underline.character_sets["normal"].setCharacter(null,"N","N͟");
Underline.character_sets["normal"].setCharacter(null,"O","O͟");
Underline.character_sets["normal"].setCharacter(null,"P","P͟");
Underline.character_sets["normal"].setCharacter(null,"Q","Q͟");
Underline.character_sets["normal"].setCharacter(null,"R","R͟");
Underline.character_sets["normal"].setCharacter(null,"S","S͟");
Underline.character_sets["normal"].setCharacter(null,"T","T͟");
Underline.character_sets["normal"].setCharacter(null,"U","U͟");
Underline.character_sets["normal"].setCharacter(null,"V","V͟");
Underline.character_sets["normal"].setCharacter(null,"W","W͟");
Underline.character_sets["normal"].setCharacter(null,"X","X͟");
Underline.character_sets["normal"].setCharacter(null,"Y","Y͟");
Underline.character_sets["normal"].setCharacter(null,"Z","Z͟");
Underline.character_sets["normal"].setCharacter(null,"a","a͟");
Underline.character_sets["normal"].setCharacter(null,"b","b͟");
Underline.character_sets["normal"].setCharacter(null,"c","c͟");
Underline.character_sets["normal"].setCharacter(null,"d","d͟");
Underline.character_sets["normal"].setCharacter(null,"e","e͟");
Underline.character_sets["normal"].setCharacter(null,"f","f͟");
Underline.character_sets["normal"].setCharacter(null,"g","g͟");
Underline.character_sets["normal"].setCharacter(null,"h","h͟");
Underline.character_sets["normal"].setCharacter(null,"i","i͟");
Underline.character_sets["normal"].setCharacter(null,"j","j͟");
Underline.character_sets["normal"].setCharacter(null,"k","k͟");
Underline.character_sets["normal"].setCharacter(null,"l","l͟");
Underline.character_sets["normal"].setCharacter(null,"m","m͟");
Underline.character_sets["normal"].setCharacter(null,"n","n͟");
Underline.character_sets["normal"].setCharacter(null,"o","o͟");
Underline.character_sets["normal"].setCharacter(null,"p","p͟");
Underline.character_sets["normal"].setCharacter(null,"q","q͟");
Underline.character_sets["normal"].setCharacter(null,"r","r͟");
Underline.character_sets["normal"].setCharacter(null,"s","s͟");
Underline.character_sets["normal"].setCharacter(null,"t","t͟");
Underline.character_sets["normal"].setCharacter(null,"u","u͟");
Underline.character_sets["normal"].setCharacter(null,"v","v͟");
Underline.character_sets["normal"].setCharacter(null,"w","w͟");
Underline.character_sets["normal"].setCharacter(null,"x","x͟");
Underline.character_sets["normal"].setCharacter(null,"y","y͟");
Underline.character_sets["normal"].setCharacter(null,"z","z͟");
Underline.character_sets["normal"].setCharacter(null,"0","0͟");
Underline.character_sets["normal"].setCharacter(null,"1","1͟");
Underline.character_sets["normal"].setCharacter(null,"2","2͟");
Underline.character_sets["normal"].setCharacter(null,"3","3͟");
Underline.character_sets["normal"].setCharacter(null,"4","4͟");
Underline.character_sets["normal"].setCharacter(null,"5","5͟");
Underline.character_sets["normal"].setCharacter(null,"6","6͟");
Underline.character_sets["normal"].setCharacter(null,"7","7͟");
Underline.character_sets["normal"].setCharacter(null,"8","8͟");
Underline.character_sets["normal"].setCharacter(null,"9","9͟");

export default Underline;