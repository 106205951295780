import Font from "../Font.js";

const Serif = new Font(9, "Serif");

Serif.initCharacterSet("bold",10);
Serif.character_sets["bold"].setCharacter("U+1D400","A","𝐀");
Serif.character_sets["bold"].setCharacter("U+1D401","B","𝐁");
Serif.character_sets["bold"].setCharacter("U+1D402","C","𝐂");
Serif.character_sets["bold"].setCharacter("U+1D403","D","𝐃");
Serif.character_sets["bold"].setCharacter("U+1D404","E","𝐄");
Serif.character_sets["bold"].setCharacter("U+1D405","F","𝐅");
Serif.character_sets["bold"].setCharacter("U+1D406","G","𝐆");
Serif.character_sets["bold"].setCharacter("U+1D407","H","𝐇");
Serif.character_sets["bold"].setCharacter("U+1D408","I","𝐈");
Serif.character_sets["bold"].setCharacter("U+1D409","J","𝐉");
Serif.character_sets["bold"].setCharacter("U+1D40A","K","𝐊");
Serif.character_sets["bold"].setCharacter("U+1D40B","L","𝐋");
Serif.character_sets["bold"].setCharacter("U+1D40C","M","𝐌");
Serif.character_sets["bold"].setCharacter("U+1D40D","N","𝐍");
Serif.character_sets["bold"].setCharacter("U+1D40E","O","𝐎");
Serif.character_sets["bold"].setCharacter("U+1D40F","P","𝐏");
Serif.character_sets["bold"].setCharacter("U+1D410","Q","𝐐");
Serif.character_sets["bold"].setCharacter("U+1D411","R","𝐑");
Serif.character_sets["bold"].setCharacter("U+1D412","S","𝐒");
Serif.character_sets["bold"].setCharacter("U+1D413","T","𝐓");
Serif.character_sets["bold"].setCharacter("U+1D414","U","𝐔");
Serif.character_sets["bold"].setCharacter("U+1D415","V","𝐕");
Serif.character_sets["bold"].setCharacter("U+1D416","W","𝐖");
Serif.character_sets["bold"].setCharacter("U+1D417","X","𝐗");
Serif.character_sets["bold"].setCharacter("U+1D418","Y","𝐘");
Serif.character_sets["bold"].setCharacter("U+1D419","Z","𝐙");
Serif.character_sets["bold"].setCharacter("U+1D41A","a","𝐚");
Serif.character_sets["bold"].setCharacter("U+1D41B","b","𝐛");
Serif.character_sets["bold"].setCharacter("U+1D41C","c","𝐜");
Serif.character_sets["bold"].setCharacter("U+1D41D","d","𝐝");
Serif.character_sets["bold"].setCharacter("U+1D41E","e","𝐞");
Serif.character_sets["bold"].setCharacter("U+1D41F","f","𝐟");
Serif.character_sets["bold"].setCharacter("U+1D420","g","𝐠");
Serif.character_sets["bold"].setCharacter("U+1D421","h","𝐡");
Serif.character_sets["bold"].setCharacter("U+1D422","i","𝐢");
Serif.character_sets["bold"].setCharacter("U+1D423","j","𝐣");
Serif.character_sets["bold"].setCharacter("U+1D424","k","𝐤");
Serif.character_sets["bold"].setCharacter("U+1D425","l","𝐥");
Serif.character_sets["bold"].setCharacter("U+1D426","m","𝐦");
Serif.character_sets["bold"].setCharacter("U+1D427","n","𝐧");
Serif.character_sets["bold"].setCharacter("U+1D428","o","𝐨");
Serif.character_sets["bold"].setCharacter("U+1D429","p","𝐩");
Serif.character_sets["bold"].setCharacter("U+1D42A","q","𝐪");
Serif.character_sets["bold"].setCharacter("U+1D42B","r","𝐫");
Serif.character_sets["bold"].setCharacter("U+1D42C","s","𝐬");
Serif.character_sets["bold"].setCharacter("U+1D42D","t","𝐭");
Serif.character_sets["bold"].setCharacter("U+1D42E","u","𝐮");
Serif.character_sets["bold"].setCharacter("U+1D42F","v","𝐯");
Serif.character_sets["bold"].setCharacter("U+1D430","w","𝐰");
Serif.character_sets["bold"].setCharacter("U+1D431","x","𝐱");
Serif.character_sets["bold"].setCharacter("U+1D432","y","𝐲");
Serif.character_sets["bold"].setCharacter("U+1D433","z","𝐳");
Serif.character_sets["bold"].setCharacter("U+1D7CE","0","𝟎");
Serif.character_sets["bold"].setCharacter("U+1D7CF","1","𝟏");
Serif.character_sets["bold"].setCharacter("U+1D7D0","2","𝟐");
Serif.character_sets["bold"].setCharacter("U+1D7D1","3","𝟑");
Serif.character_sets["bold"].setCharacter("U+1D7D2","4","𝟒");
Serif.character_sets["bold"].setCharacter("U+1D7D3","5","𝟓");
Serif.character_sets["bold"].setCharacter("U+1D7D4","6","𝟔");
Serif.character_sets["bold"].setCharacter("U+1D7D5","7","𝟕");
Serif.character_sets["bold"].setCharacter("U+1D7D6","8","𝟖");
Serif.character_sets["bold"].setCharacter("U+1D7D7","9","𝟗");

Serif.initCharacterSet("bold italic",11);
Serif.character_sets["bold italic"].setCharacter("U+1D468","A","𝑨");
Serif.character_sets["bold italic"].setCharacter("U+1D469","B","𝑩");
Serif.character_sets["bold italic"].setCharacter("U+1D46A","C","𝑪");
Serif.character_sets["bold italic"].setCharacter("U+1D46B","D","𝑫");
Serif.character_sets["bold italic"].setCharacter("U+1D46C","E","𝑬");
Serif.character_sets["bold italic"].setCharacter("U+1D46D","F","𝑭");
Serif.character_sets["bold italic"].setCharacter("U+1D46E","G","𝑮");
Serif.character_sets["bold italic"].setCharacter("U+1D46F","H","𝑯");
Serif.character_sets["bold italic"].setCharacter("U+1D470","I","𝑰");
Serif.character_sets["bold italic"].setCharacter("U+1D471","J","𝑱");
Serif.character_sets["bold italic"].setCharacter("U+1D472","K","𝑲");
Serif.character_sets["bold italic"].setCharacter("U+1D473","L","𝑳");
Serif.character_sets["bold italic"].setCharacter("U+1D474","M","𝑴");
Serif.character_sets["bold italic"].setCharacter("U+1D475","N","𝑵");
Serif.character_sets["bold italic"].setCharacter("U+1D476","O","𝑶");
Serif.character_sets["bold italic"].setCharacter("U+1D477","P","𝑷");
Serif.character_sets["bold italic"].setCharacter("U+1D478","Q","𝑸");
Serif.character_sets["bold italic"].setCharacter("U+1D479","R","𝑹");
Serif.character_sets["bold italic"].setCharacter("U+1D47A","S","𝑺");
Serif.character_sets["bold italic"].setCharacter("U+1D47B","T","𝑻");
Serif.character_sets["bold italic"].setCharacter("U+1D47C","U","𝑼");
Serif.character_sets["bold italic"].setCharacter("U+1D47D","V","𝑽");
Serif.character_sets["bold italic"].setCharacter("U+1D47E","W","𝑾");
Serif.character_sets["bold italic"].setCharacter("U+1D47F","X","𝑿");
Serif.character_sets["bold italic"].setCharacter("U+1D480","Y","𝒀");
Serif.character_sets["bold italic"].setCharacter("U+1D481","Z","𝒁");
Serif.character_sets["bold italic"].setCharacter("U+1D482","a","𝒂");
Serif.character_sets["bold italic"].setCharacter("U+1D483","b","𝒃");
Serif.character_sets["bold italic"].setCharacter("U+1D484","c","𝒄");
Serif.character_sets["bold italic"].setCharacter("U+1D485","d","𝒅");
Serif.character_sets["bold italic"].setCharacter("U+1D486","e","𝒆");
Serif.character_sets["bold italic"].setCharacter("U+1D487","f","𝒇");
Serif.character_sets["bold italic"].setCharacter("U+1D488","g","𝒈");
Serif.character_sets["bold italic"].setCharacter("U+1D489","h","𝒉");
Serif.character_sets["bold italic"].setCharacter("U+1D48A","i","𝒊");
Serif.character_sets["bold italic"].setCharacter("U+1D48B","j","𝒋");
Serif.character_sets["bold italic"].setCharacter("U+1D48C","k","𝒌");
Serif.character_sets["bold italic"].setCharacter("U+1D48D","l","𝒍");
Serif.character_sets["bold italic"].setCharacter("U+1D48E","m","𝒎");
Serif.character_sets["bold italic"].setCharacter("U+1D48F","n","𝒏");
Serif.character_sets["bold italic"].setCharacter("U+1D490","o","𝒐");
Serif.character_sets["bold italic"].setCharacter("U+1D491","p","𝒑");
Serif.character_sets["bold italic"].setCharacter("U+1D492","q","𝒒");
Serif.character_sets["bold italic"].setCharacter("U+1D493","r","𝒓");
Serif.character_sets["bold italic"].setCharacter("U+1D494","s","𝒔");
Serif.character_sets["bold italic"].setCharacter("U+1D495","t","𝒕");
Serif.character_sets["bold italic"].setCharacter("U+1D496","u","𝒖");
Serif.character_sets["bold italic"].setCharacter("U+1D497","v","𝒗");
Serif.character_sets["bold italic"].setCharacter("U+1D498","w","𝒘");
Serif.character_sets["bold italic"].setCharacter("U+1D499","x","𝒙");
Serif.character_sets["bold italic"].setCharacter("U+1D49A","y","𝒚");
Serif.character_sets["bold italic"].setCharacter("U+1D49B","z","𝒛");

Serif.initCharacterSet("italic",12);
Serif.character_sets["italic"].setCharacter("U+1D434","A","𝐴");
Serif.character_sets["italic"].setCharacter("U+1D435","B","𝐵");
Serif.character_sets["italic"].setCharacter("U+1D436","C","𝐶");
Serif.character_sets["italic"].setCharacter("U+1D437","D","𝐷");
Serif.character_sets["italic"].setCharacter("U+1D438","E","𝐸");
Serif.character_sets["italic"].setCharacter("U+1D439","F","𝐹");
Serif.character_sets["italic"].setCharacter("U+1D43A","G","𝐺");
Serif.character_sets["italic"].setCharacter("U+1D43B","H","𝐻");
Serif.character_sets["italic"].setCharacter("U+1D43C","I","𝐼");
Serif.character_sets["italic"].setCharacter("U+1D43D","J","𝐽");
Serif.character_sets["italic"].setCharacter("U+1D43E","K","𝐾");
Serif.character_sets["italic"].setCharacter("U+1D43F","L","𝐿");
Serif.character_sets["italic"].setCharacter("U+1D440","M","𝑀");
Serif.character_sets["italic"].setCharacter("U+1D441","N","𝑁");
Serif.character_sets["italic"].setCharacter("U+1D442","O","𝑂");
Serif.character_sets["italic"].setCharacter("U+1D443","P","𝑃");
Serif.character_sets["italic"].setCharacter("U+1D444","Q","𝑄");
Serif.character_sets["italic"].setCharacter("U+1D445","R","𝑅");
Serif.character_sets["italic"].setCharacter("U+1D446","S","𝑆");
Serif.character_sets["italic"].setCharacter("U+1D447","T","𝑇");
Serif.character_sets["italic"].setCharacter("U+1D448","U","𝑈");
Serif.character_sets["italic"].setCharacter("U+1D449","V","𝑉");
Serif.character_sets["italic"].setCharacter("U+1D44A","W","𝑊");
Serif.character_sets["italic"].setCharacter("U+1D44B","X","𝑋");
Serif.character_sets["italic"].setCharacter("U+1D44C","Y","𝑌");
Serif.character_sets["italic"].setCharacter("U+1D44D","Z","𝑍");
Serif.character_sets["italic"].setCharacter("U+1D44E","a","𝑎");
Serif.character_sets["italic"].setCharacter("U+1D44F","b","𝑏");
Serif.character_sets["italic"].setCharacter("U+1D450","c","𝑐");
Serif.character_sets["italic"].setCharacter("U+1D451","d","𝑑");
Serif.character_sets["italic"].setCharacter("U+1D452","e","𝑒");
Serif.character_sets["italic"].setCharacter("U+1D453","f","𝑓");
Serif.character_sets["italic"].setCharacter("U+1D454","g","𝑔");
Serif.character_sets["italic"].setCharacter("U+1D456","i","𝑖");
Serif.character_sets["italic"].setCharacter("U+1D457","j","𝑗");
Serif.character_sets["italic"].setCharacter("U+1D458","k","𝑘");
Serif.character_sets["italic"].setCharacter("U+1D459","l","𝑙");
Serif.character_sets["italic"].setCharacter("U+1D45A","m","𝑚");
Serif.character_sets["italic"].setCharacter("U+1D45B","n","𝑛");
Serif.character_sets["italic"].setCharacter("U+1D45C","o","𝑜");
Serif.character_sets["italic"].setCharacter("U+1D45D","p","𝑝");
Serif.character_sets["italic"].setCharacter("U+1D45E","q","𝑞");
Serif.character_sets["italic"].setCharacter("U+1D45F","r","𝑟");
Serif.character_sets["italic"].setCharacter("U+1D460","s","𝑠");
Serif.character_sets["italic"].setCharacter("U+1D461","t","𝑡");
Serif.character_sets["italic"].setCharacter("U+1D462","u","𝑢");
Serif.character_sets["italic"].setCharacter("U+1D463","v","𝑣");
Serif.character_sets["italic"].setCharacter("U+1D464","w","𝑤");
Serif.character_sets["italic"].setCharacter("U+1D465","x","𝑥");
Serif.character_sets["italic"].setCharacter("U+1D466","y","𝑦");
Serif.character_sets["italic"].setCharacter("U+1D467","z","𝑧");
Serif.character_sets["italic"].setCharacter("U+210E","h","ℎ");

export default Serif;