import Font from "../Font.js";

const Fraktur = new Font(0, "Fraktur");

Fraktur.initCharacterSet("bold",1);
Fraktur.character_sets["bold"].setCharacter("U+1D56C","A","𝕬");
Fraktur.character_sets["bold"].setCharacter("U+1D56D","B","𝕭");
Fraktur.character_sets["bold"].setCharacter("U+1D56E","C","𝕮");
Fraktur.character_sets["bold"].setCharacter("U+1D56F","D","𝕯");
Fraktur.character_sets["bold"].setCharacter("U+1D570","E","𝕰");
Fraktur.character_sets["bold"].setCharacter("U+1D571","F","𝕱");
Fraktur.character_sets["bold"].setCharacter("U+1D572","G","𝕲");
Fraktur.character_sets["bold"].setCharacter("U+1D573","H","𝕳");
Fraktur.character_sets["bold"].setCharacter("U+1D574","I","𝕴");
Fraktur.character_sets["bold"].setCharacter("U+1D575","J","𝕵");
Fraktur.character_sets["bold"].setCharacter("U+1D576","K","𝕶");
Fraktur.character_sets["bold"].setCharacter("U+1D577","L","𝕷");
Fraktur.character_sets["bold"].setCharacter("U+1D578","M","𝕸");
Fraktur.character_sets["bold"].setCharacter("U+1D579","N","𝕹");
Fraktur.character_sets["bold"].setCharacter("U+1D57A","O","𝕺");
Fraktur.character_sets["bold"].setCharacter("U+1D57B","P","𝕻");
Fraktur.character_sets["bold"].setCharacter("U+1D57C","Q","𝕼");
Fraktur.character_sets["bold"].setCharacter("U+1D57D","R","𝕽");
Fraktur.character_sets["bold"].setCharacter("U+1D57E","S","𝕾");
Fraktur.character_sets["bold"].setCharacter("U+1D57F","T","𝕿");
Fraktur.character_sets["bold"].setCharacter("U+1D580","U","𝖀");
Fraktur.character_sets["bold"].setCharacter("U+1D581","V","𝖁");
Fraktur.character_sets["bold"].setCharacter("U+1D582","W","𝖂");
Fraktur.character_sets["bold"].setCharacter("U+1D583","X","𝖃");
Fraktur.character_sets["bold"].setCharacter("U+1D584","Y","𝖄");
Fraktur.character_sets["bold"].setCharacter("U+1D585","Z","𝖅");
Fraktur.character_sets["bold"].setCharacter("U+1D586","a","𝖆");
Fraktur.character_sets["bold"].setCharacter("U+1D587","b","𝖇");
Fraktur.character_sets["bold"].setCharacter("U+1D588","c","𝖈");
Fraktur.character_sets["bold"].setCharacter("U+1D589","d","𝖉");
Fraktur.character_sets["bold"].setCharacter("U+1D58A","e","𝖊");
Fraktur.character_sets["bold"].setCharacter("U+1D58B","f","𝖋");
Fraktur.character_sets["bold"].setCharacter("U+1D58C","g","𝖌");
Fraktur.character_sets["bold"].setCharacter("U+1D58D","h","𝖍");
Fraktur.character_sets["bold"].setCharacter("U+1D58E","i","𝖎");
Fraktur.character_sets["bold"].setCharacter("U+1D58F","j","𝖏");
Fraktur.character_sets["bold"].setCharacter("U+1D590","k","𝖐");
Fraktur.character_sets["bold"].setCharacter("U+1D591","l","𝖑");
Fraktur.character_sets["bold"].setCharacter("U+1D592","m","𝖒");
Fraktur.character_sets["bold"].setCharacter("U+1D593","n","𝖓");
Fraktur.character_sets["bold"].setCharacter("U+1D594","o","𝖔");
Fraktur.character_sets["bold"].setCharacter("U+1D595","p","𝖕");
Fraktur.character_sets["bold"].setCharacter("U+1D596","q","𝖖");
Fraktur.character_sets["bold"].setCharacter("U+1D597","r","𝖗");
Fraktur.character_sets["bold"].setCharacter("U+1D598","s","𝖘");
Fraktur.character_sets["bold"].setCharacter("U+1D599","t","𝖙");
Fraktur.character_sets["bold"].setCharacter("U+1D59A","u","𝖚");
Fraktur.character_sets["bold"].setCharacter("U+1D59B","v","𝖛");
Fraktur.character_sets["bold"].setCharacter("U+1D59C","w","𝖜");
Fraktur.character_sets["bold"].setCharacter("U+1D59D","x","𝖝");
Fraktur.character_sets["bold"].setCharacter("U+1D59E","y","𝖞");
Fraktur.character_sets["bold"].setCharacter("U+1D59F","z","𝖟");

Fraktur.initCharacterSet("normal",2);
Fraktur.character_sets["normal"].setCharacter("U+1D504","A","𝔄");
Fraktur.character_sets["normal"].setCharacter("U+1D505","B","𝔅");
Fraktur.character_sets["normal"].setCharacter("U+1D507","D","𝔇");
Fraktur.character_sets["normal"].setCharacter("U+1D508","E","𝔈");
Fraktur.character_sets["normal"].setCharacter("U+1D509","F","𝔉");
Fraktur.character_sets["normal"].setCharacter("U+1D50A","G","𝔊");
Fraktur.character_sets["normal"].setCharacter("U+1D50D","J","𝔍");
Fraktur.character_sets["normal"].setCharacter("U+1D50E","K","𝔎");
Fraktur.character_sets["normal"].setCharacter("U+1D50F","L","𝔏");
Fraktur.character_sets["normal"].setCharacter("U+1D510","M","𝔐");
Fraktur.character_sets["normal"].setCharacter("U+1D511","N","𝔑");
Fraktur.character_sets["normal"].setCharacter("U+1D512","O","𝔒");
Fraktur.character_sets["normal"].setCharacter("U+1D513","P","𝔓");
Fraktur.character_sets["normal"].setCharacter("U+1D514","Q","𝔔");
Fraktur.character_sets["normal"].setCharacter("U+1D516","S","𝔖");
Fraktur.character_sets["normal"].setCharacter("U+1D517","T","𝔗");
Fraktur.character_sets["normal"].setCharacter("U+1D518","U","𝔘");
Fraktur.character_sets["normal"].setCharacter("U+1D519","V","𝔙");
Fraktur.character_sets["normal"].setCharacter("U+1D51A","W","𝔚");
Fraktur.character_sets["normal"].setCharacter("U+1D51B","X","𝔛");
Fraktur.character_sets["normal"].setCharacter("U+1D51C","Y","𝔜");
Fraktur.character_sets["normal"].setCharacter("U+1D51E","a","𝔞");
Fraktur.character_sets["normal"].setCharacter("U+1D51F","b","𝔟");
Fraktur.character_sets["normal"].setCharacter("U+1D520","c","𝔠");
Fraktur.character_sets["normal"].setCharacter("U+1D521","d","𝔡");
Fraktur.character_sets["normal"].setCharacter("U+1D522","e","𝔢");
Fraktur.character_sets["normal"].setCharacter("U+1D523","f","𝔣");
Fraktur.character_sets["normal"].setCharacter("U+1D524","g","𝔤");
Fraktur.character_sets["normal"].setCharacter("U+1D525","h","𝔥");
Fraktur.character_sets["normal"].setCharacter("U+1D526","i","𝔦");
Fraktur.character_sets["normal"].setCharacter("U+1D527","j","𝔧");
Fraktur.character_sets["normal"].setCharacter("U+1D528","k","𝔨");
Fraktur.character_sets["normal"].setCharacter("U+1D529","l","𝔩");
Fraktur.character_sets["normal"].setCharacter("U+1D52A","m","𝔪");
Fraktur.character_sets["normal"].setCharacter("U+1D52B","n","𝔫");
Fraktur.character_sets["normal"].setCharacter("U+1D52C","o","𝔬");
Fraktur.character_sets["normal"].setCharacter("U+1D52D","p","𝔭");
Fraktur.character_sets["normal"].setCharacter("U+1D52E","q","𝔮");
Fraktur.character_sets["normal"].setCharacter("U+1D52F","r","𝔯");
Fraktur.character_sets["normal"].setCharacter("U+1D530","s","𝔰");
Fraktur.character_sets["normal"].setCharacter("U+1D531","t","𝔱");
Fraktur.character_sets["normal"].setCharacter("U+1D532","u","𝔲");
Fraktur.character_sets["normal"].setCharacter("U+1D533","v","𝔳");
Fraktur.character_sets["normal"].setCharacter("U+1D534","w","𝔴");
Fraktur.character_sets["normal"].setCharacter("U+1D535","x","𝔵");
Fraktur.character_sets["normal"].setCharacter("U+1D536","y","𝔶");
Fraktur.character_sets["normal"].setCharacter("U+1D537","z","𝔷");
Fraktur.character_sets["normal"].setCharacter("U+212D","C","ℭ");
Fraktur.character_sets["normal"].setCharacter("U+210C","H","ℌ");
Fraktur.character_sets["normal"].setCharacter("U+2111","I","ℑ");
Fraktur.character_sets["normal"].setCharacter("U+211C","R","ℜ");
Fraktur.character_sets["normal"].setCharacter("U+2128","Z","ℨ");
Fraktur.character_sets["normal"].setCharacter("U+2111","I","ℑ");
Fraktur.character_sets["normal"].setCharacter("U+211C","R","ℜ");
Fraktur.character_sets["normal"].setCharacter("U+212D","C","ℭ");
Fraktur.character_sets["normal"].setCharacter("U+212D","C","ℭ");
Fraktur.character_sets["normal"].setCharacter("U+2111","I","ℑ");
Fraktur.character_sets["normal"].setCharacter("U+211C","R","ℜ");
Fraktur.character_sets["normal"].setCharacter("U+2128","Z","ℨ");

export default Fraktur;