import Font from "../Font.js";

const Pound_Cake = new Font(242,"Pound Cake");

Pound_Cake.initCharacterSet("normal",243);
Pound_Cake.character_sets["normal"].setCharacter(null,"A","Ɑ");
Pound_Cake.character_sets["normal"].setCharacter(null,"B","Ɓ");
Pound_Cake.character_sets["normal"].setCharacter(null,"C","Ƈ");
Pound_Cake.character_sets["normal"].setCharacter(null,"D","Ɗ");
Pound_Cake.character_sets["normal"].setCharacter(null,"E","Ҽ");
Pound_Cake.character_sets["normal"].setCharacter(null,"F","Ƒ");
Pound_Cake.character_sets["normal"].setCharacter(null,"G","Ɠ");
Pound_Cake.character_sets["normal"].setCharacter(null,"H","Ӈ");
Pound_Cake.character_sets["normal"].setCharacter(null,"I","Ӏ");
Pound_Cake.character_sets["normal"].setCharacter(null,"J","J");
Pound_Cake.character_sets["normal"].setCharacter(null,"K","Ƙ");
Pound_Cake.character_sets["normal"].setCharacter(null,"L","l");
Pound_Cake.character_sets["normal"].setCharacter(null,"M","Ɱ");
Pound_Cake.character_sets["normal"].setCharacter(null,"N","Ɲ");
Pound_Cake.character_sets["normal"].setCharacter(null,"O","Ơ");
Pound_Cake.character_sets["normal"].setCharacter(null,"P","Ƥ");
Pound_Cake.character_sets["normal"].setCharacter(null,"Q","Q");
Pound_Cake.character_sets["normal"].setCharacter(null,"R","R");
Pound_Cake.character_sets["normal"].setCharacter(null,"S","Տ");
Pound_Cake.character_sets["normal"].setCharacter(null,"T","Ƭ");
Pound_Cake.character_sets["normal"].setCharacter(null,"U","Ʋ");
Pound_Cake.character_sets["normal"].setCharacter(null,"V","V");
Pound_Cake.character_sets["normal"].setCharacter(null,"W","Ⱳ");
Pound_Cake.character_sets["normal"].setCharacter(null,"X","X");
Pound_Cake.character_sets["normal"].setCharacter(null,"Y","Ƴ");
Pound_Cake.character_sets["normal"].setCharacter(null,"Z","Ȥ");
Pound_Cake.character_sets["normal"].setCharacter(null,"a","ą");
Pound_Cake.character_sets["normal"].setCharacter(null,"b","ɓ");
Pound_Cake.character_sets["normal"].setCharacter(null,"c","ƈ");
Pound_Cake.character_sets["normal"].setCharacter(null,"d","ɗ");
Pound_Cake.character_sets["normal"].setCharacter(null,"e","ҽ");
Pound_Cake.character_sets["normal"].setCharacter(null,"f","ƒ");
Pound_Cake.character_sets["normal"].setCharacter(null,"g","ɠ");
Pound_Cake.character_sets["normal"].setCharacter(null,"h","ɦ");
Pound_Cake.character_sets["normal"].setCharacter(null,"i","í");
Pound_Cake.character_sets["normal"].setCharacter(null,"j","ᴊ");
Pound_Cake.character_sets["normal"].setCharacter(null,"k","ƙ");
Pound_Cake.character_sets["normal"].setCharacter(null,"l","Ɩ");
Pound_Cake.character_sets["normal"].setCharacter(null,"m","ɱ");
Pound_Cake.character_sets["normal"].setCharacter(null,"n","ղ");
Pound_Cake.character_sets["normal"].setCharacter(null,"o","օ");
Pound_Cake.character_sets["normal"].setCharacter(null,"p","ƥ");
Pound_Cake.character_sets["normal"].setCharacter(null,"q","ʠ");
Pound_Cake.character_sets["normal"].setCharacter(null,"r","ɾ");
Pound_Cake.character_sets["normal"].setCharacter(null,"s","ʂ");
Pound_Cake.character_sets["normal"].setCharacter(null,"t","ƭ");
Pound_Cake.character_sets["normal"].setCharacter(null,"u","ʋ");
Pound_Cake.character_sets["normal"].setCharacter(null,"v","ⱱ");
Pound_Cake.character_sets["normal"].setCharacter(null,"w","ⱳ");
Pound_Cake.character_sets["normal"].setCharacter(null,"x","x");
Pound_Cake.character_sets["normal"].setCharacter(null,"y","ყ");
Pound_Cake.character_sets["normal"].setCharacter(null,"z","z");
Pound_Cake.character_sets["normal"].setCharacter(null,"0","0");
Pound_Cake.character_sets["normal"].setCharacter(null,"1","1");
Pound_Cake.character_sets["normal"].setCharacter(null,"2","2");
Pound_Cake.character_sets["normal"].setCharacter(null,"3","3");
Pound_Cake.character_sets["normal"].setCharacter(null,"4","4");
Pound_Cake.character_sets["normal"].setCharacter(null,"5","5");
Pound_Cake.character_sets["normal"].setCharacter(null,"6","6");
Pound_Cake.character_sets["normal"].setCharacter(null,"7","7");
Pound_Cake.character_sets["normal"].setCharacter(null,"8","8");
Pound_Cake.character_sets["normal"].setCharacter(null,"9","9");

export default Pound_Cake;