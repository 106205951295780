class CursorManager {
  constructor(string) {
    this.current = 0;
    this.string = string;
  }

  /***********************************************************************/
  validCursor(cursor) {
    return cursor < this.string.symbols.length && cursor >= 0;
  }
  /***********************************************************************/
  validSelection(selection) {
    return this.validCursor(selection.start) && this.validCursor(selection.end);
  }
  /***********************************************************************/
  findValidEndPosition(cursor, direction) {
    if (
      (this.validCursor(cursor) && this.string.symbols[cursor].isValid()) ||
      cursor === this.string.symbols.length
    ) {
      return cursor;
    }

    while (true) {
      if (direction === "forward") cursor += 1;
      else cursor -= 1;

      if (
        (this.validCursor(cursor) && this.string.symbols[cursor].isValid()) ||
        cursor === this.string.symbols.length
      )
        break;
    }
    return cursor;
  }
  /***********************************************************************/
  getPrevSymbolPosition(cursor) {
    while (true) {
      cursor -= 1;

      if (this.validCursor(cursor) && this.string.symbols[cursor].isValid())
        break;
    }

    return cursor;
  }
  /***********************************************************************/
  findValidStartPosition(cursor, direction) {
    if (
      (this.validCursor(cursor) && this.string.symbols[cursor].isValid()) ||
      cursor === this.string.symbols.length
    )
      return cursor;

    while (true) {
      if (direction === "forward") cursor -= 1;
      else cursor += 1;

      if (
        (this.validCursor(cursor) && this.string.symbols[cursor].isValid()) ||
        cursor === this.string.symbols.length
      )
        break;
    }
    return cursor;
  }
  /***********************************************************************/
}
export default CursorManager;
