class CharacterStyle {
  constructor(bold = false, italic = false) {
    this.bold = bold;
    this.italic = italic;
  }

  setBold(bold) {
    this.bold = bold;
  }

  setItalic(italic) {
    this.italic = italic;
  }

  determineStyle() {
    if (this.bold && this.italic) return "bold italic";
    else if (this.bold) return "bold";
    else if (this.italic) return "italic";
    else return "normal";
  }
  equals(s) {
    return this.sameStyle(s);
  }

  sameStyle(s) {
    return s.determineStyle() === this.determineStyle();
  }

  setFromStyleString(str) {
    if (str === "bold") {
      this.bold = true;
      this.italic = false;
    } else if (str === "italic") {
      this.bold = false;
      this.italic = true;
    } else if (str === "bold italic") {
      this.bold = true;
      this.italic = false;
    } else {
      this.bold = false;
      this.italic = false;
    }
  }

  hashify() {
    return { bold: this.bold, italic: this.italic };
  }

  clone() {
    return new CharacterStyle(this.bold, this.italic);
  }
}

export default CharacterStyle;
