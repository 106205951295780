import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import React from "react";
import "./CharactersLeft.scss";

/***************************************************************************/
const limits = { instagram: 2200, youtube: 5000 };
class CharactersLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      string: this.props.manager.stringManager.getCurrentState().string,
      app: "instagram", // [instagram, youtube],
      open: true,
    };
    this.unicode_string_manager_id = null;
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }
  /***************************************************************************/
  componentDidMount() {
    this.unicode_string_manager_id =
      this.props.manager.stringManager.registerListener((e, changed) => {
        let changedSet = new Set(changed);
        if (changedSet.has("state")) {
          this.setState((state, props) => {
            return {
              string: this.props.manager.stringManager.getCurrentState().string,
            };
          });
        }
      });
  }
  /***************************************************************************/
  componentWillUnmount() {
    this.props.manager.stringManager.unregisterListener(
      this.unicode_string_manager_id
    );
  }
  /***************************************************************************/
  handleClose() {
    this.setState((state, props) => {
      return { open: false };
    });
  }
  /***************************************************************************/
  handleOpen() {
    this.setState((state, props) => {
      return { open: true };
    });
  }
  /***************************************************************************/
  makeInnerHtml() {
    const charsLeft = limits[this.state.app] - this.state.string.length;
    let classes = ["chars-left-value-value"];
    if (charsLeft <= 0) classes.push("no-more-left");
    if (this.state.open) {
      return (
        <React.Fragment>
          <span className="chars-left-label">Characters Left: </span>
          <span className="chars-left-value">
            <span className={classes.join(" ")}>{charsLeft}</span>
            <span>/{limits[this.state.app]}</span>
            <span className="close-arrow">
              <ArrowRightIcon onClick={this.handleClose} />
            </span>
          </span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span className="close-arrow">
            <ArrowLeftIcon onClick={this.handleOpen} />
          </span>
        </React.Fragment>
      );
    }
  }

  /***************************************************************************/
  render() {
    return <div className="chars-left">{this.makeInnerHtml()}</div>;
  }
}

export default CharactersLeft;
/***************************************************************************/
