import Font from "../Font.js";

const Circular = new Font(200,"Circular");

Circular.initCharacterSet("normal",201);
Circular.character_sets["normal"].setCharacter(null,"A","Ⓐ");
Circular.character_sets["normal"].setCharacter(null,"B","Ⓑ");
Circular.character_sets["normal"].setCharacter(null,"C","Ⓒ");
Circular.character_sets["normal"].setCharacter(null,"D","Ⓓ");
Circular.character_sets["normal"].setCharacter(null,"E","Ⓔ");
Circular.character_sets["normal"].setCharacter(null,"F","Ⓕ");
Circular.character_sets["normal"].setCharacter(null,"G","Ⓖ");
Circular.character_sets["normal"].setCharacter(null,"H","Ⓗ");
Circular.character_sets["normal"].setCharacter(null,"I","Ⓘ");
Circular.character_sets["normal"].setCharacter(null,"J","Ⓙ");
Circular.character_sets["normal"].setCharacter(null,"K","Ⓚ");
Circular.character_sets["normal"].setCharacter(null,"L","Ⓛ");
Circular.character_sets["normal"].setCharacter(null,"M","Ⓜ");
Circular.character_sets["normal"].setCharacter(null,"N","Ⓝ");
Circular.character_sets["normal"].setCharacter(null,"O","Ⓞ");
Circular.character_sets["normal"].setCharacter(null,"P","Ⓟ");
Circular.character_sets["normal"].setCharacter(null,"Q","Ⓠ");
Circular.character_sets["normal"].setCharacter(null,"R","Ⓡ");
Circular.character_sets["normal"].setCharacter(null,"S","Ⓢ");
Circular.character_sets["normal"].setCharacter(null,"T","Ⓣ");
Circular.character_sets["normal"].setCharacter(null,"U","Ⓤ");
Circular.character_sets["normal"].setCharacter(null,"V","Ⓥ");
Circular.character_sets["normal"].setCharacter(null,"W","Ⓦ");
Circular.character_sets["normal"].setCharacter(null,"X","Ⓧ");
Circular.character_sets["normal"].setCharacter(null,"Y","Ⓨ");
Circular.character_sets["normal"].setCharacter(null,"Z","Ⓩ");
Circular.character_sets["normal"].setCharacter(null,"a","ⓐ");
Circular.character_sets["normal"].setCharacter(null,"b","ⓑ");
Circular.character_sets["normal"].setCharacter(null,"c","ⓒ");
Circular.character_sets["normal"].setCharacter(null,"d","ⓓ");
Circular.character_sets["normal"].setCharacter(null,"e","ⓔ");
Circular.character_sets["normal"].setCharacter(null,"f","ⓕ");
Circular.character_sets["normal"].setCharacter(null,"g","ⓖ");
Circular.character_sets["normal"].setCharacter(null,"h","ⓗ");
Circular.character_sets["normal"].setCharacter(null,"i","ⓘ");
Circular.character_sets["normal"].setCharacter(null,"j","ⓙ");
Circular.character_sets["normal"].setCharacter(null,"k","ⓚ");
Circular.character_sets["normal"].setCharacter(null,"l","ⓛ");
Circular.character_sets["normal"].setCharacter(null,"m","ⓜ");
Circular.character_sets["normal"].setCharacter(null,"n","ⓝ");
Circular.character_sets["normal"].setCharacter(null,"o","ⓞ");
Circular.character_sets["normal"].setCharacter(null,"p","ⓟ");
Circular.character_sets["normal"].setCharacter(null,"q","ⓠ");
Circular.character_sets["normal"].setCharacter(null,"r","ⓡ");
Circular.character_sets["normal"].setCharacter(null,"s","ⓢ");
Circular.character_sets["normal"].setCharacter(null,"t","ⓣ");
Circular.character_sets["normal"].setCharacter(null,"u","ⓤ");
Circular.character_sets["normal"].setCharacter(null,"v","ⓥ");
Circular.character_sets["normal"].setCharacter(null,"w","ⓦ");
Circular.character_sets["normal"].setCharacter(null,"x","ⓧ");
Circular.character_sets["normal"].setCharacter(null,"y","ⓨ");
Circular.character_sets["normal"].setCharacter(null,"z","ⓩ");
Circular.character_sets["normal"].setCharacter(null,"0","⓪");
Circular.character_sets["normal"].setCharacter(null,"1","①");
Circular.character_sets["normal"].setCharacter(null,"2","②");
Circular.character_sets["normal"].setCharacter(null,"3","③");
Circular.character_sets["normal"].setCharacter(null,"4","④");
Circular.character_sets["normal"].setCharacter(null,"5","⑤");
Circular.character_sets["normal"].setCharacter(null,"6","⑥");
Circular.character_sets["normal"].setCharacter(null,"7","⑦");
Circular.character_sets["normal"].setCharacter(null,"8","⑧");
Circular.character_sets["normal"].setCharacter(null,"9","⑨");

export default Circular;