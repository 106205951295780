import DeleteAll from "./DeleteAll.js";
import Init from "./Init.js";
import InitFromHash from "./InitFromHash.js";
import Insert from "./Insert.js";
import InsertSingle from "./InsertSingle.js";
import Paste from "./Paste.js";
import RemoveSelection from "./RemoveSelection.js";
import RemoveSingle from "./RemoveSingle.js";
import Replace from "./Replace.js";
import UpdateSelectionStyle from "./UpdateSelectionStyle.js";
import Capitalize from "./Capitalize.js";
import Reverse from "./Reverse.js";
import RevertToDefault from "./RevertToDefault.js";

class CommandFactory {
  /*********************************************************/
  constructor(manager) {
    this.manager = manager;
    this.commands = {
      init: Init,
      "init from hash": InitFromHash,
      insert: Insert,
      "insert single": InsertSingle,
      "remove single": RemoveSingle,
      "remove selection": RemoveSelection,
      paste: Paste,
      replace: Replace,
      capitalize: Capitalize,
      "update selection style": UpdateSelectionStyle,
      "delete all": DeleteAll,
      reverse: Reverse,
      "revert to default": RevertToDefault,
    };
  }
  /*********************************************************/
  make(c, input) {
    let command = new this.commands[c](...input);
    command.setManager(this.manager);
    return command;
  }
}

export default CommandFactory;
