import Font from "../Font.js";

const Rando = new Font(230,"Rando");

Rando.initCharacterSet("normal",231);
Rando.character_sets["normal"].setCharacter(null,"A","𝕒");
Rando.character_sets["normal"].setCharacter(null,"B","Ⓑ");
Rando.character_sets["normal"].setCharacter(null,"C","ｃ");
Rando.character_sets["normal"].setCharacter(null,"D","𝓭");
Rando.character_sets["normal"].setCharacter(null,"E","𝒆");
Rando.character_sets["normal"].setCharacter(null,"F","ғ");
Rando.character_sets["normal"].setCharacter(null,"G","ﻮ");
Rando.character_sets["normal"].setCharacter(null,"H","Ĥ");
Rando.character_sets["normal"].setCharacter(null,"I","ί");
Rando.character_sets["normal"].setCharacter(null,"J","Ĵ");
Rando.character_sets["normal"].setCharacter(null,"K","𝓚");
Rando.character_sets["normal"].setCharacter(null,"L","ˡ");
Rando.character_sets["normal"].setCharacter(null,"M","𝓂");
Rando.character_sets["normal"].setCharacter(null,"N","几");
Rando.character_sets["normal"].setCharacter(null,"O","σ");
Rando.character_sets["normal"].setCharacter(null,"P","ק");
Rando.character_sets["normal"].setCharacter(null,"Q","𝐐");
Rando.character_sets["normal"].setCharacter(null,"R","ᖇ");
Rando.character_sets["normal"].setCharacter(null,"S","ⓢ");
Rando.character_sets["normal"].setCharacter(null,"T","ｔ");
Rando.character_sets["normal"].setCharacter(null,"U","ย");
Rando.character_sets["normal"].setCharacter(null,"V","ᐯ");
Rando.character_sets["normal"].setCharacter(null,"W","ⓦ");
Rando.character_sets["normal"].setCharacter(null,"X","χ");
Rando.character_sets["normal"].setCharacter(null,"Y","𝔂");
Rando.character_sets["normal"].setCharacter(null,"Z","z");
Rando.character_sets["normal"].setCharacter(null,"a","ⓐ");
Rando.character_sets["normal"].setCharacter(null,"b","ｂ");
Rando.character_sets["normal"].setCharacter(null,"c","ｃ");
Rando.character_sets["normal"].setCharacter(null,"d","∂");
Rando.character_sets["normal"].setCharacter(null,"e","𝐄");
Rando.character_sets["normal"].setCharacter(null,"f","𝕗");
Rando.character_sets["normal"].setCharacter(null,"g","𝓖");
Rando.character_sets["normal"].setCharacter(null,"h","Ｈ");
Rando.character_sets["normal"].setCharacter(null,"i","เ");
Rando.character_sets["normal"].setCharacter(null,"j","נ");
Rando.character_sets["normal"].setCharacter(null,"k","𝓀");
Rando.character_sets["normal"].setCharacter(null,"l","𝓛");
Rando.character_sets["normal"].setCharacter(null,"m","𝐌");
Rando.character_sets["normal"].setCharacter(null,"n","几");
Rando.character_sets["normal"].setCharacter(null,"o","𝕆");
Rando.character_sets["normal"].setCharacter(null,"p","𝕡");
Rando.character_sets["normal"].setCharacter(null,"q","𝐐");
Rando.character_sets["normal"].setCharacter(null,"r","ⓡ");
Rando.character_sets["normal"].setCharacter(null,"s","ѕ");
Rando.character_sets["normal"].setCharacter(null,"t","т");
Rando.character_sets["normal"].setCharacter(null,"u","𝐮");
Rando.character_sets["normal"].setCharacter(null,"v","𝓋");
Rando.character_sets["normal"].setCharacter(null,"w","ｗ");
Rando.character_sets["normal"].setCharacter(null,"x","χ");
Rando.character_sets["normal"].setCharacter(null,"y","Ｙ");
Rando.character_sets["normal"].setCharacter(null,"z","ℤ");
Rando.character_sets["normal"].setCharacter(null,"0","ʘ");
Rando.character_sets["normal"].setCharacter(null,"1","➀");
Rando.character_sets["normal"].setCharacter(null,"2","❷");
Rando.character_sets["normal"].setCharacter(null,"3","➂");
Rando.character_sets["normal"].setCharacter(null,"4","➃");
Rando.character_sets["normal"].setCharacter(null,"5","❺");
Rando.character_sets["normal"].setCharacter(null,"6","➅");
Rando.character_sets["normal"].setCharacter(null,"7","➆");
Rando.character_sets["normal"].setCharacter(null,"8","❽");
Rando.character_sets["normal"].setCharacter(null,"9","❾");

export default Rando;