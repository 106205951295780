import ComplexUnicodeString from "./ComplexUnicodeString.js";
import font_manager from "../Glyphs/Font/FontManager.js";
import Selection from "../Selection.js";
import CharacterStyle from "../CharacterStyle.js";
import Attributes from "../Attributes.js";
import StateManager from "./StateManager/State/StateManager.js";
import CommandFactory from "./StateManager/Commands/CommandFactory.js";
import HistoryManager from "./HistoryManager.js";

/***********************************************************************/
class ComplexUnicodeStringManager {
  constructor(font_manager) {
    this.commandFactory = new CommandFactory(this);
    this.stateManager = new StateManager(this);
    this.historyManager = new HistoryManager(this);
    this.semaphore = 0;
    this.appManager = null;
    this.defaults = {
      font: "SansSerif",
      style: new CharacterStyle(false, false),
    };
  }
  /***********************************************************************/
  registerListener(func) {
    this.listeners.push(func);
    return this.listeners.length - 1;
  }
  /***********************************************************************/
  unregisterListener(id) {
    this.listeners[id] = null;
  }
  /***********************************************************************/
  notifyListeners(changed) {
    for (let i = 0; i < this.listeners.length; i++) {
      if (this.listeners[i] !== null && this.listeners[i] !== undefined)
        this.listeners[i](this.getCurrent(), changed);
    }
  }
  /***********************************************************************/
  getCurrent() {
    return this.commands[this.current];
  }
  /***********************************************************************/
  getCurrentState() {
    return this.getCurrent().getOutputState();
  }
  /***********************************************************************/
  init(appManager) {
    this.commands = [];
    this.listeners = [];
    this.current = 0;
    this.selections = new Selection(0, 0);
    this.style = this.defaults.style.clone();
    this.font = this.defaults.font;
    this.appManager = appManager;
    this.isCursor = true;
    this.model = new ComplexUnicodeString(font_manager);
    this.stateManager.init();
    let command = this.commandFactory.make("init", []);
    command.exec(this.model);
    this.commands.push(command);
  }
  /***********************************************************************/
  reinstateFromHash(hash) {
    this.dispatch("init from hash", [hash]);
  }
  /***********************************************************************/
  hashify() {
    const actions = this.actions.map((action) => {
      return action.hashify();
    });

    const selections =
      this.selections === null ? null : this.selections.hashify();

    return {
      model: this.model.hashify(),
      actions: actions,
      current: this.current,
      style: this.style.hashify(),
      font: this.font,
      selections: selections,
      isCursor: this.isCursor,
    };
  }
  /***********************************************************************/
  deleteAll() {
    this.setStyle(this.defaults.style.clone());
    this.setFont(this.defaults.font);
    this.dispatch("delete all", []);
  }
  /***********************************************************************/
  getFontManager() {
    return this.model.fontManager;
  }
  /***********************************************************************/
  getCurrentFontModel() {
    return this.model.fontManager.fonts[this.font];
  }
  /***********************************************************************/
  changeSelectionStyle(s, initProps) {
    let propsChanged = initProps;
    let propsChangedSet = new Set(initProps);
    if (s.isSelection()) {
      const firstSymbolInSelection = this.model.symbols[s.start].parent;
      const styleSame =
        firstSymbolInSelection.style === this.style.determineStyle();
      const fontSame = firstSymbolInSelection.font === this.font;

      if (!styleSame) {
        this.style.setFromStyleString(firstSymbolInSelection.style);
        if (!propsChangedSet.has("style")) propsChanged.push("style");
      }

      if (!fontSame) {
        this.font = firstSymbolInSelection.font;
        if (!propsChangedSet.has("font")) propsChanged.push("font");
      }
    }
    return propsChanged;
  }
  /***********************************************************************/
  addSelection(s) {
    const lastSelection = this.getCurrentSelection();

    if (lastSelection !== null && lastSelection !== undefined) {
      const different = !(
        lastSelection.start === s.start && lastSelection.end === s.end
      );

      if (different) {
        this.selections = s;
        this.isCursor = s.isCursor();
        const propsChanged = this.changeSelectionStyle(s, ["selection"]);
        this.notifyListeners(propsChanged);
      }
    } else {
      this.selections = s;
      this.isCursor = s.isCursor();
      const propsChanged = this.changeSelectionStyle(s, ["selection"]);
      this.notifyListeners(propsChanged);
    }
  }
  /***********************************************************************/
  getCurrentStyle() {
    return this.style;
  }
  /***********************************************************************/
  getIsCursor() {
    return this.isCursor;
  }
  /***********************************************************************/
  snip(start, end) {
    const string = this.model.slice(start, end);
    return string;
  }
  /***********************************************************************/
  setSelection(s) {
    this.addSelection(s);
  }
  /***********************************************************************/
  capitalizeSubstring() {
    if (
      this.getCurrentSelection() !== null &&
      this.getCurrentSelection().isSelection()
    ) {
      this.dispatch("capitalize", [
        this.getCurrentSelection(),
        new Attributes(this.style.bold, this.style.italic, this.font),
      ]);
    }
  }
  /***********************************************************************/
  reverseSubstring() {
    if (
      this.getCurrentSelection() !== null &&
      this.getCurrentSelection().isSelection()
    ) {
      this.dispatch("reverse", [
        this.getCurrentSelection(),
        new Attributes(this.style.bold, this.style.italic, this.font),
      ]);
    }
  }
  /***********************************************************************/
  revertToDefault() {
    let selection = this.getCurrentSelection();
    this.dispatch("revert to default", [
      new Attributes(
        this.defaults.style.bold,
        this.defaults.style.italic,
        this.defaults.font
      ),
      selection,
    ]);

    if (
      selection.start === 0 &&
      selection.end >= this.model.makeString().length
    )
      this.setStyle(this.defaults.style.clone());
    this.setFont(this.defaults.font);
    this.appManager.manageSettings();
  }
  /***********************************************************************/
  setFont(font) {
    this.semaphore = 1;
    this.font = font;
    // If we have the style set to bold, but the current font does not
    // have this style, then we need to change it.
    let style = new CharacterStyle(this.style.bold, this.style.italic);
    if (this.style.bold && !this.getCurrentFontModel().hasStyle("bold"))
      style.bold = false;

    if (this.style.italic && !this.getCurrentFontModel().hasStyle("italic"))
      style.italic = false;

    if (
      this.getCurrentSelection() !== null &&
      this.getCurrentSelection().isSelection()
    ) {
      this.dispatch("update selection style", [
        this.getCurrentSelection(),
        new Attributes(style.bold, style.italic, font),
        "font",
      ]);
    }

    this.style.bold = style.bold;
    this.style.italic = style.italic;
    this.semaphore = 0;
    this.appManager.manageSettings();
    this.notifyListeners(["font", "style", "state"]);
  }
  /***********************************************************************/
  setStyle(s) {
    let changed = null;
    this.semaphore = 1;
    if (this.style.bold !== s.bold) changed = "bold";

    if (this.style.italic !== s.italic) changed = "italic";

    this.style.bold = s.bold;
    this.style.italic = s.italic;

    // console.log("Set style called");
    if (
      this.getCurrentSelection() !== null &&
      this.getCurrentSelection().isSelection()
    ) {
      this.dispatch("update selection style", [
        this.getCurrentSelection(),
        new Attributes(s.bold, s.italic, this.font),
        changed,
      ]);
    }

    //this.semaphore = 0;
    this.appManager.manageSettings();
    this.notifyListeners(["style"]);
  }
  /***********************************************************************/
  insertSpecialInput(i) {
    let selection = this.getCurrentSelection();
    if (selection === null) selection = new Selection(0, 0);
    const attributes = new Attributes(
      this.style.bold,
      this.style.italic,
      this.font
    );
    const input = [i, attributes, selection];
    this.appManager.manageSettings();
    this.dispatch("paste", input);
  }
  /***********************************************************************/
  getCurrentFont() {
    return this.font;
  }
  /***********************************************************************/
  getCurrentSelection() {
    return this.selections.clone();
  }
  /***********************************************************************/
  isInit() {
    return this.actions.length === 1;
  }
  /***********************************************************************/
  dispatch(c, input) {
    let command = this.commandFactory.make(c, input);
    command.exec(this.model);
    this.commands.splice(this.current + 1, this.commands.length, command);
    this.current++;
    this.notifyListeners(["state"]);
  }
  /***********************************************************************/
}
const complex_unicode_string_manager = new ComplexUnicodeStringManager(
  font_manager
);
export default complex_unicode_string_manager;
