import Font from "../Font.js";
const All_Caps_2 = new Font(208, "All Caps 2");

All_Caps_2.initCharacterSet("normal", 209);

All_Caps_2.character_sets["normal"].setCharacter(null, "A", "ᴀ");
All_Caps_2.character_sets["normal"].setCharacter(null, "B", "ʙ");
All_Caps_2.character_sets["normal"].setCharacter(null, "C", "ᴄ");
All_Caps_2.character_sets["normal"].setCharacter(null, "D", "ᴅ");
All_Caps_2.character_sets["normal"].setCharacter(null, "E", "ᴇ");
All_Caps_2.character_sets["normal"].setCharacter(null, "F", "ꜰ");
All_Caps_2.character_sets["normal"].setCharacter(null, "G", "ɢ");
All_Caps_2.character_sets["normal"].setCharacter(null, "H", "ʜ");
All_Caps_2.character_sets["normal"].setCharacter(null, "I", "ɪ");
All_Caps_2.character_sets["normal"].setCharacter(null, "J", "ᴊ");
All_Caps_2.character_sets["normal"].setCharacter(null, "K", "ᴋ");
All_Caps_2.character_sets["normal"].setCharacter(null, "L", "ʟ");
All_Caps_2.character_sets["normal"].setCharacter(null, "M", "ᴍ");
All_Caps_2.character_sets["normal"].setCharacter(null, "N", "ɴ");
All_Caps_2.character_sets["normal"].setCharacter(null, "O", "ᴏ");
All_Caps_2.character_sets["normal"].setCharacter(null, "P", "ᴘ");
All_Caps_2.character_sets["normal"].setCharacter(null, "Q", "Q");
All_Caps_2.character_sets["normal"].setCharacter(null, "R", "ʀ");
All_Caps_2.character_sets["normal"].setCharacter(null, "S", "ꜱ");
All_Caps_2.character_sets["normal"].setCharacter(null, "T", "ᴛ");
All_Caps_2.character_sets["normal"].setCharacter(null, "U", "ᴜ");
All_Caps_2.character_sets["normal"].setCharacter(null, "V", "ᴠ");
All_Caps_2.character_sets["normal"].setCharacter(null, "W", "ᴡ");
All_Caps_2.character_sets["normal"].setCharacter(null, "X", "x");
All_Caps_2.character_sets["normal"].setCharacter(null, "Y", "ʏ");
All_Caps_2.character_sets["normal"].setCharacter(null, "Z", "ᴢ");
All_Caps_2.character_sets["normal"].setCharacter(null, "a", "ᴀ");
All_Caps_2.character_sets["normal"].setCharacter(null, "b", "ʙ");
All_Caps_2.character_sets["normal"].setCharacter(null, "c", "ᴄ");
All_Caps_2.character_sets["normal"].setCharacter(null, "d", "ᴅ");
All_Caps_2.character_sets["normal"].setCharacter(null, "e", "ᴇ");
All_Caps_2.character_sets["normal"].setCharacter(null, "f", "ꜰ");
All_Caps_2.character_sets["normal"].setCharacter(null, "g", "ɢ");
All_Caps_2.character_sets["normal"].setCharacter(null, "h", "ʜ");
All_Caps_2.character_sets["normal"].setCharacter(null, "i", "ɪ");
All_Caps_2.character_sets["normal"].setCharacter(null, "j", "ᴊ");
All_Caps_2.character_sets["normal"].setCharacter(null, "k", "ᴋ");
All_Caps_2.character_sets["normal"].setCharacter(null, "l", "ʟ");
All_Caps_2.character_sets["normal"].setCharacter(null, "m", "ᴍ");
All_Caps_2.character_sets["normal"].setCharacter(null, "n", "ɴ");
All_Caps_2.character_sets["normal"].setCharacter(null, "o", "ᴏ");
All_Caps_2.character_sets["normal"].setCharacter(null, "p", "ᴘ");
All_Caps_2.character_sets["normal"].setCharacter(null, "q", "Q");
All_Caps_2.character_sets["normal"].setCharacter(null, "r", "ʀ");
All_Caps_2.character_sets["normal"].setCharacter(null, "s", "ꜱ");
All_Caps_2.character_sets["normal"].setCharacter(null, "t", "ᴛ");
All_Caps_2.character_sets["normal"].setCharacter(null, "u", "ᴜ");
All_Caps_2.character_sets["normal"].setCharacter(null, "v", "ᴠ");
All_Caps_2.character_sets["normal"].setCharacter(null, "w", "ᴡ");
All_Caps_2.character_sets["normal"].setCharacter(null, "x", "x");
All_Caps_2.character_sets["normal"].setCharacter(null, "y", "ʏ");
All_Caps_2.character_sets["normal"].setCharacter(null, "z", "ᴢ");
All_Caps_2.character_sets["normal"].setCharacter(null, "0", "0");
All_Caps_2.character_sets["normal"].setCharacter(null, "1", "1");
All_Caps_2.character_sets["normal"].setCharacter(null, "2", "2");
All_Caps_2.character_sets["normal"].setCharacter(null, "3", "3");
All_Caps_2.character_sets["normal"].setCharacter(null, "4", "4");
All_Caps_2.character_sets["normal"].setCharacter(null, "5", "5");
All_Caps_2.character_sets["normal"].setCharacter(null, "6", "6");
All_Caps_2.character_sets["normal"].setCharacter(null, "7", "7");
All_Caps_2.character_sets["normal"].setCharacter(null, "8", "8");
All_Caps_2.character_sets["normal"].setCharacter(null, "9", "9");

export default All_Caps_2;
