import Command from "./Command.js";
import Selection from "../../../Selection.js";

class RevertToDefault extends Command {
  constructor(attributes, selection) {
    super("revert to default", null, attributes, selection);
  }
  exec(string) {
    this.startCommand();
    this.input_state = string.hashify();
    const substring = string.getSubstring(this.cursor);
    let defaultString = "";
    for (let i = 0; i < substring.importantIndices.length; i++) {
      let index = substring.importantIndices[i];
      let char = substring.substringModel[index];
      let temp = char.parent.char;
      if (char.hasTransformations()) {
        let transformations = char.getTransformations();
        for (let j = 0; j < transformations.length; j++) {
          let t = transformations[j];
          // console.log(t);
          let keys = Object.keys(t);
          temp = t[keys[0]].parent.char;
        }
      }
      defaultString += temp;
    }

    let removeSelection = this.manager.commandFactory.make("remove selection", [
      this.cursor,
    ]);
    let insert = this.manager.commandFactory.make("insert", [
      defaultString,
      this.attributes,
      this.cursor,
    ]);
    this.subCommands.push(removeSelection);
    this.subCommands.push(insert);
    this.execSubCommands(string);
    this.manager.setSelection(new Selection(0, 0));

    this.output_state = string.hashify();
    return this;
  }
  execSubCommands(string) {
    for (let i = 0; i < this.subCommands.length; i++)
      this.subCommands[i].exec(string);
  }
}

export default RevertToDefault;
