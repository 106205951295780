import React from "react";
import ShareButtons from "../ShareButtons/ShareButtons.js";
import History from "../History/History.js";
import Paper from "@mui/material/Paper";
import "./TopToolbar.scss";

/***************************************************************************/
class TopToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openFontSelector: false,
      settingsEnabled: { list: false, horizontal_rule: false },
    };
    this.handleSnippet = this.handleSnippet.bind(this);
    this.ref = React.createRef();
    this.app_manager_id = null;
  }
  /***************************************************************************/
  componentDidMount() {
    //this.props.setHeights(this.ref.current.offsetHeight, "topToolbarHeight");
    this.app_manager_id = this.props.manager.registerListener((changed) => {
      let changedSet = new Set(changed);
      if (changedSet.has("settingsEnabled")) {
        let newSettings = { ...this.props.manager.getSettingsEnabled() };

        this.setState((state, props) => {
          let newSettings = { ...this.props.manager.getSettingsEnabled() };
          return { settingsEnabled: newSettings };
        });
      }
    });
  }
  /***************************************************************************/
  handleSnippet(s) {
    // get the current selection
    const selection = this.props.manager.stringManager.getCurrentSelection();
    if (selection !== null) {
      const snip = this.props.manager.stringManager.snip(
        selection.start,
        selection.end
      );
    }
  }
  /***************************************************************************/
  render() {
    return (
      <Paper
        square
        ref={this.ref}
        elevation={2}
        className="top-toolbar toolbar"
      >
        <History manager={this.props.manager} />
        <ShareButtons manager={this.props.manager} />
      </Paper>
    );
  }
}
/***************************************************************************/
export default TopToolbar;
