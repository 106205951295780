import UnicodeCharFactory from "../UnicodeCharFactory.js";
import ComplexUnicodeChar from "./ComplexUnicodeChar.js";
import CharacterStyle from "../CharacterStyle.js";
import Attributes from "../Attributes.js";
import CursorManager from "./CursorManager.js";
/***********************************************************************/
class ComplexUnicodeString {
  constructor(fontManager) {
    this.factory = new UnicodeCharFactory();
    this.fontManager = fontManager;
    this.symbols = [];
    this.cursor = 0;
    this.cursorManager = new CursorManager(this);
  }
  /***********************************************************************/
  init() {
    this.symbols = [];
    this.factory = new UnicodeCharFactory();
    this.cursor = 0;
  }
  /*************************************************************/
  removeSymbol(cursor, chLen) {
    this.symbols.splice(cursor, chLen);
    this.cursor = cursor;
  }
  /*************************************************************/
  updateSelectionStyle(cursor, style, font) {
    let ret = this.getSubstring(cursor);

    this.removeSymbol(cursor.start, ret.substringModel.length);

    const endCursor = this.insertSymbols(
      ret.substring,
      font,
      style,
      cursor.start
    );

    return { start: cursor.start, end: endCursor };
  }
  /***********************************************************************/
  getSubstring(cursor) {
    let substringModel = this.slice(cursor.start, cursor.end);
    let substring = "";
    let importantIndices = [];
    let attributes = [];
    let transformations = [];
    for (let i = 0; i < substringModel.length; i++) {
      const ch = substringModel[i];
      if (ch.isValid()) {
        let style = new CharacterStyle(false, false);
        style.setFromStyleString(ch.parent.style);
        substring += ch.parent.char;
        importantIndices.push(i);
        attributes.push(
          new Attributes(style.bold, style.italic, ch.parent.font)
        );
        transformations.push(ch.getTransformations());
      }
    }
    return {
      substring: substring,
      substringModel: substringModel,
      importantIndices: importantIndices,
      attributes: attributes,
      transformations: transformations,
    };
  }
  /***********************************************************************/
  isValidPosition(pos) {
    if (pos === this.symbols.length) return true;
    return this.symbols[pos] !== undefined && this.symbols[pos].isValid();
  }
  /***********************************************************************/
  reinstateFromHash(hash) {
    let bank = hash.factory.bank;
    let keys = Object.keys(bank);

    // populate the bank
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      const parent = this.factory.make(
        bank[key].char,
        bank[key].font,
        bank[key].style
      );
    }

    // populate the state
    let temp = [];
    for (let i = 0; i < hash.symbols.length; i++) {
      let symbol = hash.symbols[i];
      let parent = this.factory.getChar(symbol.parent.id);
      temp.push(
        new ComplexUnicodeChar(
          parent,
          symbol.symbol,
          symbol.actualSymbol,
          symbol.relativeIndex
        )
      );
    }

    this.symbols = temp;
    this.cursor = hash.cursor;
  }
  /***********************************************************************/
  slice(start, end) {
    return this.symbols.slice(start, end);
  }
  /************************************************************************/
  makeSubstring(start, end) {
    const substring = this.slice(start, end);
    let str = "";
    for (let i = start; i < end; i++) {
      const symbol = this.symbols[i];
      if (symbol !== undefined && symbol !== null && symbol.isValid())
        str += symbol.parent.char;
    }

    return str;
  }
  /***********************************************************************/
  hashify() {
    let symbols = [];
    for (let i = 0; i < this.symbols.length; i++) {
      symbols.push(this.symbols[i].hashify());
    }

    return {
      symbols: symbols,
      cursor: this.cursor,
      factory: this.factory.hashify(),
      string: this.makeString(),
      plainString: this.makePlainString(),
    };
  }
  /***********************************************************************/
  insertSymbol(ch, font, style, cursor, transformations = null) {
    // We need to deal with symbols that are longer than 1
    const parent = this.factory.make(ch, font, style);
    let rep = this.fontManager.write(font, style, ch);
    let substring = [];
    for (let i = 0; i < rep.length; i++) {
      let symbol = null;
      if (i === 0) {
        symbol = new ComplexUnicodeChar(parent, rep, rep, i);
        symbol.transformations = transformations;
        substring.push(symbol);
      } else {
        // insert empty string
        symbol = new ComplexUnicodeChar(parent, "", rep, i);
        substring.push(symbol);
      }
    }

    let before = this.symbols.slice(0, cursor);
    let after = this.symbols.slice(cursor);
    let temp = [];
    for (let i = 0; i < before.length; i++) temp.push(before[i]);
    for (let i = 0; i < substring.length; i++) temp.push(substring[i]);
    for (let i = 0; i < after.length; i++) temp.push(after[i]);
    this.symbols = temp;

    return cursor + rep.length;
  }
  /***********************************************************************/
  makePlainString() {
    let str = "";
    for (let i = 0; i < this.symbols.length; i++)
      if (this.symbols[i].isValid()) str += this.symbols[i].parent.char;

    return str;
  }
  /***********************************************************************/
  makeString() {
    let str = "";
    for (let i = 0; i < this.symbols.length; i++) str += this.symbols[i].symbol;
    return str;
  }
  /***********************************************************************/
}
export default ComplexUnicodeString;
