import Command from "./Command.js";

class Init extends Command {
  constructor() {
    super("init", null, null, null);
  }
  exec(string) {
    this.input_state = string.hashify();
    string.init();
    this.output_state = string.hashify();
    return this;
  }
}

export default Init;
