class State {
  constructor(name, dispatcher) {
    this.name = name;
    this.input_state = null;
    this.output_state = null;
    this.data = null;
    this.dispatcher = dispatcher;

    this.validHashtag = /[A-Za-z0-9_\b]/gm;
    this.validTag = /[A-Za-z0-9_\.\b]/gm;
  }

  equals(state) {
    return (
      this.name === state.name &&
      this.input_state.equals(state.input_state) &&
      this.output_state.equals(state.outputState)
    );
  }

  setOutputState(outputState) {
    this.output_state = outputState;
    if (this.data !== null) outputState.data = this.data;
    outputState.setInputState(this);
  }

  getOutputState() {
    return this.output_state;
  }

  getInputState() {
    return this.input_state;
  }

  setInputState(inputState) {
    this.input_state = inputState;
  }

  hashify() {
    return {
      name: this.name,
      inputState: this.input_state === null ? null : this.input_state.hashify(),
      outputState:
        this.output_state === null ? null : this.output_state.hashify(),
    };
  }

  spawn(command, data) {
    return;
  }
}

export default State;
