import React from "react";
import runes from "runes";
import Selection from "../../models/UnicodeString/Selection.js";
import Attributes from "../../models/UnicodeString/Attributes.js";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import CharactersLeft from "../CharactersLeft/CharactersLeft.js";
import "./DualInputOutputbox.scss";

class DualInputOutputbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      string: this.props.manager.stringManager.getCurrentState().string,
      numRows: 1,
      selection: this.props.manager.stringManager.getCurrentSelection(),
      mode: {
        normal: this.props.manager.mode.normal,
        list: this.props.manager.mode.list,
      },
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInsert = this.handleInsert.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleRemoveSingle = this.handleRemoveSingle.bind(this);
    this.handleRemoveSelection = this.handleRemoveSelection.bind(this);
    this.handleReplace = this.handleReplace.bind(this);
    this.determineInputType = this.determineInputType.bind(this);

    this.ref = React.createRef();
    this.complex_unicode_string_manager_id = null;
    this.app_manager_id = null;
  }
  /***************************************************************************/
  componentDidMount() {
    let textarea = this.ref.current.lastChild.firstChild;

    this.complex_unicode_string_manager_id =
      this.props.manager.stringManager.registerListener((e, changed) => {
        let changedSet = new Set(changed);
        if (changedSet.has("state")) {
          this.setState((state, props) => {
            return {
              string: this.props.manager.stringManager.getCurrentState().string,
            };
          });
        }

        if (changedSet.has("selection")) {
          this.setState((state, props) => {
            return {
              selection: this.props.manager.stringManager.getCurrentSelection(),
            };
          });
          textarea.focus();
        }
      });

    this.app_manager_id = this.props.manager.registerListener((changed) => {
      let changedSet = new Set(changed);
      if (changedSet.has("mode")) {
        this.setState((state, props) => {
          return {
            mode: {
              normal: this.props.manager.mode.normal,
              list: this.props.manager.mode.list,
            },
          };
        });
      }
      textarea.focus();
    });
    textarea.focus();
    textarea.style.height = "" + (this.props.adjustedHeight - 16 * 4) + "px"; // console.log("The number of rows: ");
  }
  /***************************************************************************/
  componentWillUnmount() {
    this.props.manager.stringManager.unregisterListener(
      this.complex_unicode_string_manager_id
    );

    this.props.manager.unregisterListener(this.app_manager_id);
  }
  /***************************************************************************/
  componentDidUpdate(prevProps, prevState) {
    let textarea = this.ref.current.lastChild.firstChild;
    textarea.focus();
    let selection = this.props.manager.stringManager.getCurrentSelection();

    if (!prevState.selection.equals(this.state.selection)) {
      textarea.selectionStart = selection.start;
      textarea.selectionEnd = selection.end;
    }

    this.props.manager.stringManager.getCurrent().endCommand();
    textarea.style.height = "" + (this.props.adjustedHeight - 16 * 4) + "px";
  }
  /***************************************************************************/
  handleReplace(selection, e) {
    const savedStringCopy = (" " + this.state.string).slice(1);
    const before = savedStringCopy.slice(0, selection.start);
    const after = savedStringCopy.slice(selection.end);
    const intermediatePlainText = before + after;

    const chLen = Math.abs(
      intermediatePlainText.length - e.target.value.length
    );
    const data = e.target.value.slice(selection.start, selection.start + chLen);
    const font = this.props.manager.stringManager.getCurrentFont();
    const style = this.props.manager.stringManager.getCurrentStyle();
    const attributes = new Attributes(style.bold, style.italic, font);
    return [data, attributes, selection];
  }
  /***************************************************************************/
  handleChange(e) {
    // Determine what type of input it was
    const diff = e.target.value.length - this.state.string.length;
    const ret = this.determineInputType(e, diff);

    // Dispatch an action depending on the input type
    let commandInput = [];
    let handled = false;
    if (ret === null) {
      // ensure input box is in sync with model
      e.target.value = this.state.string;
      return;
    }
    if (ret.inputType === "insert") {
      commandInput = this.handleInsert(ret.textInserted, ret.selection);
      handled = true;
    } else if (ret.inputType === "paste") {
      commandInput = this.handlePaste(ret.textInserted, ret.selection);
      handled = true;
    } else if (ret.inputType === "remove single") {
      commandInput = this.handleRemoveSingle(ret.selection);
      handled = true;
    } else if (ret.inputType === "remove selection") {
      commandInput = this.handleRemoveSelection(ret.selection);
      handled = true;
    } else if (ret.inputType === "replace") {
      commandInput = this.handleReplace(ret.selection, e);
      handled = true;
    }

    if (handled)
      this.props.manager.stringManager.dispatch(ret.inputType, commandInput);
  }
  /***************************************************************************/
  handleInsert(textInserted, selection) {
    const font = this.props.manager.stringManager.getCurrentFont();
    const style = this.props.manager.stringManager.getCurrentStyle();
    const attributes = new Attributes(style.bold, style.italic, font);
    if (this.state.mode.list && textInserted === "\n")
      textInserted += this.props.manager.constants["BULLET"];
    const commandInput = [textInserted, attributes, selection];
    return commandInput;
  }
  /***************************************************************************/
  handlePaste(textInserted, selection) {
    const font = this.props.manager.stringManager.getCurrentFont();
    const style = this.props.manager.stringManager.getCurrentStyle();
    const attributes = new Attributes(style.bold, style.italic, font);

    const commandInput = [textInserted, attributes, selection];
    return commandInput;
  }
  /***************************************************************************/
  handleRemoveSingle(selection) {
    return [selection];
  }
  /***************************************************************************/
  handleRemoveSelection(selection) {
    return [selection];
  }
  /***************************************************************************/
  handleSelect(e) {
    let start = e.target.selectionStart;
    let end = e.target.selectionEnd;
    let direction = e.target.selectionDirection;

    /* if (start !== end) {
      const validDirection =
        direction === "forward" || direction === "backward";
      if (!validDirection) direction = start < end ? "forward" : "backward";

      let validEnd =
        this.props.manager.stringManager.model.cursorManager.findValidEndPosition(
          end,
          direction
        );
      let validBeg =
        this.props.manager.stringManager.model.cursorManager.findValidStartPosition(
          start,
          direction
        );
      e.target.selectionStart = validBeg;
      e.target.selectionEnd = validEnd;
    } else {
      let validEnd =
        this.props.manager.stringManager.model.cursorManager.findValidEndPosition(
          end,
          direction
        );
      e.target.selectionStart = validEnd;
      e.target.selectionEnd = validEnd;
    }*/

    let selection = new Selection(
      e.target.selectionStart,
      e.target.selectionEnd
    );
    this.props.manager.stringManager.setSelection(selection);
  }
  /***************************************************************************/
  determineInputType(e, diff) {
    const currentSelection =
      this.props.manager.stringManager.getCurrentSelection();
    
    console.log("What is the current selection?");
    console.log(currentSelection);
    if (currentSelection !== null && currentSelection.isSelection()) {
      const charsRemoved = diff < 0;
      const selectionLength = currentSelection.end - currentSelection.start;
      const allCharsRemoved = selectionLength === Math.abs(diff);

      if (charsRemoved && allCharsRemoved)
        return { inputType: "remove selection", selection: currentSelection };
      return { inputType: "replace", selection: currentSelection };
    } else {
      if (diff > 0) {
        // if the new string is larger than the old string, then the user
        // inserted some text.
        const textInserted = e.target.value.slice(
          currentSelection.end,
          currentSelection.end + diff
        );

        const distinctChars = runes(textInserted);
        const isInsert = distinctChars.length === 1;
        const isPaste = distinctChars.length > 1;
        let ret = {
          textInserted: textInserted,
          distinctChars: distinctChars,
          selection: currentSelection,
        };

        if (isInsert) return { ...ret, inputType: "insert" };
        else if (isPaste) return { ...ret, inputType: "paste" };
      } else if (diff === 0) {
        return null;
      } else {
        let ret = {
          selection: currentSelection,
        };

        return { ...ret, inputType: "remove single" };
      }
    }

    return null; // something went wrong
  }
  /***************************************************************************/
  render() {
    return (
      <React.Fragment>
        <Paper className="box-wrapper">
          <TextField
            ref={this.ref}
            multiline
            minRows={1}
            value={this.state.string}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            className="input-box"
            placeholder="Input text here..."
            fullWidth
            size="string"
            InputProps={{ disableUnderline: true }}
          />
        </Paper>
        <CharactersLeft manager={this.props.manager} />
      </React.Fragment>
    );
  }
}

export default DualInputOutputbox;
