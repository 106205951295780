import Font from "../Font.js";

const Hat = new Font(270,"Hat");

Hat.initCharacterSet("normal",271);
Hat.character_sets["normal"].setCharacter(null,"A","A͡");
Hat.character_sets["normal"].setCharacter(null,"B","B͡");
Hat.character_sets["normal"].setCharacter(null,"C","C͡");
Hat.character_sets["normal"].setCharacter(null,"D","D͡");
Hat.character_sets["normal"].setCharacter(null,"E","E͡");
Hat.character_sets["normal"].setCharacter(null,"F","F͡");
Hat.character_sets["normal"].setCharacter(null,"G","G͡");
Hat.character_sets["normal"].setCharacter(null,"H","H͡");
Hat.character_sets["normal"].setCharacter(null,"I","I͡");
Hat.character_sets["normal"].setCharacter(null,"J","J͡");
Hat.character_sets["normal"].setCharacter(null,"K","K͡");
Hat.character_sets["normal"].setCharacter(null,"L","L͡");
Hat.character_sets["normal"].setCharacter(null,"M","M͡");
Hat.character_sets["normal"].setCharacter(null,"N","N͡");
Hat.character_sets["normal"].setCharacter(null,"O","O͡");
Hat.character_sets["normal"].setCharacter(null,"P","P͡");
Hat.character_sets["normal"].setCharacter(null,"Q","Q͡");
Hat.character_sets["normal"].setCharacter(null,"R","R͡");
Hat.character_sets["normal"].setCharacter(null,"S","S͡");
Hat.character_sets["normal"].setCharacter(null,"T","T͡");
Hat.character_sets["normal"].setCharacter(null,"U","U͡");
Hat.character_sets["normal"].setCharacter(null,"V","V͡");
Hat.character_sets["normal"].setCharacter(null,"W","W͡");
Hat.character_sets["normal"].setCharacter(null,"X","X͡");
Hat.character_sets["normal"].setCharacter(null,"Y","Y͡");
Hat.character_sets["normal"].setCharacter(null,"Z","Z͡");
Hat.character_sets["normal"].setCharacter(null,"a","a͡");
Hat.character_sets["normal"].setCharacter(null,"b","b͡");
Hat.character_sets["normal"].setCharacter(null,"c","c͡");
Hat.character_sets["normal"].setCharacter(null,"d","d͡");
Hat.character_sets["normal"].setCharacter(null,"e","e͡");
Hat.character_sets["normal"].setCharacter(null,"f","f͡");
Hat.character_sets["normal"].setCharacter(null,"g","g͡");
Hat.character_sets["normal"].setCharacter(null,"h","h͡");
Hat.character_sets["normal"].setCharacter(null,"i","i͡");
Hat.character_sets["normal"].setCharacter(null,"j","j͡");
Hat.character_sets["normal"].setCharacter(null,"k","k͡");
Hat.character_sets["normal"].setCharacter(null,"l","l͡");
Hat.character_sets["normal"].setCharacter(null,"m","m͡");
Hat.character_sets["normal"].setCharacter(null,"n","n͡");
Hat.character_sets["normal"].setCharacter(null,"o","o͡");
Hat.character_sets["normal"].setCharacter(null,"p","p͡");
Hat.character_sets["normal"].setCharacter(null,"q","q͡");
Hat.character_sets["normal"].setCharacter(null,"r","r͡");
Hat.character_sets["normal"].setCharacter(null,"s","s͡");
Hat.character_sets["normal"].setCharacter(null,"t","t͡");
Hat.character_sets["normal"].setCharacter(null,"u","u͡");
Hat.character_sets["normal"].setCharacter(null,"v","v͡");
Hat.character_sets["normal"].setCharacter(null,"w","w͡");
Hat.character_sets["normal"].setCharacter(null,"x","x͡");
Hat.character_sets["normal"].setCharacter(null,"y","y͡");
Hat.character_sets["normal"].setCharacter(null,"z","z͡");
Hat.character_sets["normal"].setCharacter(null,"0","0͡");
Hat.character_sets["normal"].setCharacter(null,"1","1͡");
Hat.character_sets["normal"].setCharacter(null,"2","2͡");
Hat.character_sets["normal"].setCharacter(null,"3","3͡");
Hat.character_sets["normal"].setCharacter(null,"4","4͡");
Hat.character_sets["normal"].setCharacter(null,"5","5͡");
Hat.character_sets["normal"].setCharacter(null,"6","6͡");
Hat.character_sets["normal"].setCharacter(null,"7","7͡");
Hat.character_sets["normal"].setCharacter(null,"8","8͡");
Hat.character_sets["normal"].setCharacter(null,"9","9͡");

export default Hat;