import Font from "../Font.js";

const Black_Pill = new Font(208,"Black Pill");

Black_Pill.initCharacterSet("normal",209);
Black_Pill.character_sets["normal"].setCharacter(null,"A","🅐");
Black_Pill.character_sets["normal"].setCharacter(null,"B","🅑");
Black_Pill.character_sets["normal"].setCharacter(null,"C","🅒");
Black_Pill.character_sets["normal"].setCharacter(null,"D","🅓");
Black_Pill.character_sets["normal"].setCharacter(null,"E","🅔");
Black_Pill.character_sets["normal"].setCharacter(null,"F","🅕");
Black_Pill.character_sets["normal"].setCharacter(null,"G","🅖");
Black_Pill.character_sets["normal"].setCharacter(null,"H","🅗");
Black_Pill.character_sets["normal"].setCharacter(null,"I","🅘");
Black_Pill.character_sets["normal"].setCharacter(null,"J","🅙");
Black_Pill.character_sets["normal"].setCharacter(null,"K","🅚");
Black_Pill.character_sets["normal"].setCharacter(null,"L","🅛");
Black_Pill.character_sets["normal"].setCharacter(null,"M","🅜");
Black_Pill.character_sets["normal"].setCharacter(null,"N","🅝");
Black_Pill.character_sets["normal"].setCharacter(null,"O","🅞");
Black_Pill.character_sets["normal"].setCharacter(null,"P","🅟");
Black_Pill.character_sets["normal"].setCharacter(null,"Q","🅠");
Black_Pill.character_sets["normal"].setCharacter(null,"R","🅡");
Black_Pill.character_sets["normal"].setCharacter(null,"S","🅢");
Black_Pill.character_sets["normal"].setCharacter(null,"T","🅣");
Black_Pill.character_sets["normal"].setCharacter(null,"U","🅤");
Black_Pill.character_sets["normal"].setCharacter(null,"V","🅥");
Black_Pill.character_sets["normal"].setCharacter(null,"W","🅦");
Black_Pill.character_sets["normal"].setCharacter(null,"X","🅧");
Black_Pill.character_sets["normal"].setCharacter(null,"Y","🅨");
Black_Pill.character_sets["normal"].setCharacter(null,"Z","🅩");
Black_Pill.character_sets["normal"].setCharacter(null,"a","🅐");
Black_Pill.character_sets["normal"].setCharacter(null,"b","🅑");
Black_Pill.character_sets["normal"].setCharacter(null,"c","🅒");
Black_Pill.character_sets["normal"].setCharacter(null,"d","🅓");
Black_Pill.character_sets["normal"].setCharacter(null,"e","🅔");
Black_Pill.character_sets["normal"].setCharacter(null,"f","🅕");
Black_Pill.character_sets["normal"].setCharacter(null,"g","🅖");
Black_Pill.character_sets["normal"].setCharacter(null,"h","🅗");
Black_Pill.character_sets["normal"].setCharacter(null,"i","🅘");
Black_Pill.character_sets["normal"].setCharacter(null,"j","🅙");
Black_Pill.character_sets["normal"].setCharacter(null,"k","🅚");
Black_Pill.character_sets["normal"].setCharacter(null,"l","🅛");
Black_Pill.character_sets["normal"].setCharacter(null,"m","🅜");
Black_Pill.character_sets["normal"].setCharacter(null,"n","🅝");
Black_Pill.character_sets["normal"].setCharacter(null,"o","🅞");
Black_Pill.character_sets["normal"].setCharacter(null,"p","🅟");
Black_Pill.character_sets["normal"].setCharacter(null,"q","🅠");
Black_Pill.character_sets["normal"].setCharacter(null,"r","🅡");
Black_Pill.character_sets["normal"].setCharacter(null,"s","🅢");
Black_Pill.character_sets["normal"].setCharacter(null,"t","🅣");
Black_Pill.character_sets["normal"].setCharacter(null,"u","🅤");
Black_Pill.character_sets["normal"].setCharacter(null,"v","🅥");
Black_Pill.character_sets["normal"].setCharacter(null,"w","🅦");
Black_Pill.character_sets["normal"].setCharacter(null,"x","🅧");
Black_Pill.character_sets["normal"].setCharacter(null,"y","🅨");
Black_Pill.character_sets["normal"].setCharacter(null,"z","🅩");
Black_Pill.character_sets["normal"].setCharacter(null,"0","⓿");
Black_Pill.character_sets["normal"].setCharacter(null,"1","➊");
Black_Pill.character_sets["normal"].setCharacter(null,"2","➋");
Black_Pill.character_sets["normal"].setCharacter(null,"3","➌");
Black_Pill.character_sets["normal"].setCharacter(null,"4","➍");
Black_Pill.character_sets["normal"].setCharacter(null,"5","➎");
Black_Pill.character_sets["normal"].setCharacter(null,"6","➏");
Black_Pill.character_sets["normal"].setCharacter(null,"7","➐");
Black_Pill.character_sets["normal"].setCharacter(null,"8","➑");
Black_Pill.character_sets["normal"].setCharacter(null,"9","➒");

export default Black_Pill;