import React from "react";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./Settings.scss";

/***************************************************************************/
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.manager.getPanel("settings"),
      horizontal_rule: this.props.manager.settings.HORIZONTAL_RULE,
      bullet: this.props.manager.settings.BULLET,
    };
    this.ref = React.createRef();
    this.handleClose = this.handleClose.bind(this);
    this.app_manager_id = null;
  }
  /***************************************************************************/
  componentDidMount() {
    this.app_manager_id = this.props.manager.registerListener((changed) => {
      let changedSet = new Set(changed);
      if (changedSet.has("panelsOpen")) {
        this.setState((state, props) => {
          return { open: this.props.manager.getPanel("settings") };
        });
      }
    });
  }
  /***************************************************************************/
  handleClose(e) {
    this.props.manager.togglePanel("settings");
  }
  /***************************************************************************/
  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Settings</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            ref={this.ref}
            tabIndex={-1}
          >
            <div>
              <h3>Horizontal Rule</h3>
              <div className="current-symbol">{this.state.horizontal_rule}</div>
            </div>

            <div>
              <h3>List Bullet</h3>
              <div className="current-symbol">{this.state.bullet}</div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
/***************************************************************************/
export default Settings;
