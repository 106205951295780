import Font from "../Font.js";

const Function = new Font(272,"Function");

Function.initCharacterSet("normal",273);
Function.character_sets["normal"].setCharacter(null,"A","⒜");
Function.character_sets["normal"].setCharacter(null,"B","⒝");
Function.character_sets["normal"].setCharacter(null,"C","⒞");
Function.character_sets["normal"].setCharacter(null,"D","⒟");
Function.character_sets["normal"].setCharacter(null,"E","⒠");
Function.character_sets["normal"].setCharacter(null,"F","⒡");
Function.character_sets["normal"].setCharacter(null,"G","⒢");
Function.character_sets["normal"].setCharacter(null,"H","⒣");
Function.character_sets["normal"].setCharacter(null,"I","⒤");
Function.character_sets["normal"].setCharacter(null,"J","⒥");
Function.character_sets["normal"].setCharacter(null,"K","⒦");
Function.character_sets["normal"].setCharacter(null,"L","⒧");
Function.character_sets["normal"].setCharacter(null,"M","⒨");
Function.character_sets["normal"].setCharacter(null,"N","⒩");
Function.character_sets["normal"].setCharacter(null,"O","⒪");
Function.character_sets["normal"].setCharacter(null,"P","⒫");
Function.character_sets["normal"].setCharacter(null,"Q","⒬");
Function.character_sets["normal"].setCharacter(null,"R","⒭");
Function.character_sets["normal"].setCharacter(null,"S","⒮");
Function.character_sets["normal"].setCharacter(null,"T","⒯");
Function.character_sets["normal"].setCharacter(null,"U","⒰");
Function.character_sets["normal"].setCharacter(null,"V","⒱");
Function.character_sets["normal"].setCharacter(null,"W","⒲");
Function.character_sets["normal"].setCharacter(null,"X","⒳");
Function.character_sets["normal"].setCharacter(null,"Y","⒴");
Function.character_sets["normal"].setCharacter(null,"Z","⒵");
Function.character_sets["normal"].setCharacter(null,"a","⒜");
Function.character_sets["normal"].setCharacter(null,"b","⒝");
Function.character_sets["normal"].setCharacter(null,"c","⒞");
Function.character_sets["normal"].setCharacter(null,"d","⒟");
Function.character_sets["normal"].setCharacter(null,"e","⒠");
Function.character_sets["normal"].setCharacter(null,"f","⒡");
Function.character_sets["normal"].setCharacter(null,"g","⒢");
Function.character_sets["normal"].setCharacter(null,"h","⒣");
Function.character_sets["normal"].setCharacter(null,"i","⒤");
Function.character_sets["normal"].setCharacter(null,"j","⒥");
Function.character_sets["normal"].setCharacter(null,"k","⒦");
Function.character_sets["normal"].setCharacter(null,"l","⒧");
Function.character_sets["normal"].setCharacter(null,"m","⒨");
Function.character_sets["normal"].setCharacter(null,"n","⒩");
Function.character_sets["normal"].setCharacter(null,"o","⒪");
Function.character_sets["normal"].setCharacter(null,"p","⒫");
Function.character_sets["normal"].setCharacter(null,"q","⒬");
Function.character_sets["normal"].setCharacter(null,"r","⒭");
Function.character_sets["normal"].setCharacter(null,"s","⒮");
Function.character_sets["normal"].setCharacter(null,"t","⒯");
Function.character_sets["normal"].setCharacter(null,"u","⒰");
Function.character_sets["normal"].setCharacter(null,"v","⒱");
Function.character_sets["normal"].setCharacter(null,"w","⒲");
Function.character_sets["normal"].setCharacter(null,"x","⒳");
Function.character_sets["normal"].setCharacter(null,"y","⒴");
Function.character_sets["normal"].setCharacter(null,"z","⒵");
Function.character_sets["normal"].setCharacter(null,"0","⒪");
Function.character_sets["normal"].setCharacter(null,"1","⑴");
Function.character_sets["normal"].setCharacter(null,"2","⑵");
Function.character_sets["normal"].setCharacter(null,"3","⑶");
Function.character_sets["normal"].setCharacter(null,"4","⑷");
Function.character_sets["normal"].setCharacter(null,"5","⑸");
Function.character_sets["normal"].setCharacter(null,"6","⑹");
Function.character_sets["normal"].setCharacter(null,"7","⑺");
Function.character_sets["normal"].setCharacter(null,"8","⑻");
Function.character_sets["normal"].setCharacter(null,"9","⑼");

export default Function;